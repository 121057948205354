import React from "react";
import { Icon } from "@abb/abb-common-ux-react";
import * as browseTypes from "../Type/TypeBrowse";

interface IActiveColumnViewProps {
  isActive: boolean;
}

const ActiveOrPausedColumnView = (props: IActiveColumnViewProps) => {
  return (
    <div
      className={
        props.isActive
          ? "active-column-cell-content"
          : "paused-column-cell-content"
      }
    >
      {props.isActive ? (
        <Icon name="abb/view" sizeClass="small" />
      ) : (
        <Icon name="abb/pause" sizeClass="small" />
      )}
    </div>
  );
};

export const configuredDataHeaders = [
  {
    id: "isActive",
    displayName: "",
    className: "configured-data-header isactive-header",
    isSortable: true,
  },
  {
    id: "name",
    displayName: "Name",
    className: "configured-data-header",
    isSortable: true,
  },
  {
    id: "nodeName",
    displayName: "Browse Name",
    className: "configured-data-header",
    isSortable: true,
  },
  {
    id: "id",
    displayName: "Object Id",
    className: "configured-data-header",
    isSortable: true,
  },
  {
    id: "nodeId",
    displayName: "Node Id",
    className: "configured-data-header",
    isSortable: true,
  },
];

export const enabledMethodDataHeaders = [
  {
    id: "methodName",
    displayName: "Name",
    className: "enabled-method-data-header",
    isSortable: true,
  },
  {
    id: "objectName",
    displayName: "Object Name",
    className: "enabled-method-data-header",
    isSortable: true,
  },
  {
    id: "objectId",
    displayName: "Object Id",
    className: "enabled-method-data-header",
    isSortable: true,
  },
  {
    id: "nodeId",
    displayName: "Node Id",
    className: "enabled-method-data-header",
    isSortable: true,
  },
];

export const childrenDataHeader = [
  {
    id: "displayName",
    displayName: "Variable Name",
    className: "variable-table-header",
    isSortable: true,
    isSearchable: true,
  },
  {
    id: "nodeTypeId",
    displayName: "Variable Type Id",
    className: "variable-table-header",
    isSortable: true,
    isSearchable: true,
  },
  {
    id: "browseName",
    displayName: "Browse Name",
    className: "variable-table-header",
    isSortable: true,
    isSearchable: true,
  },
];

export const methodDataHeader = [
  {
    id: "displayName",
    displayName: "Method Name",
    className: "method-table-header",
    isSortable: true,
    isSearchable: true,
  },
  {
    id: "browseName",
    displayName: "Browse Name",
    className: "method-table-header",
    isSortable: true,
    isSearchable: true,
  },
];

export const inputParamsHeader = [
  {
    id: "displayName",
    displayName: "Input Parameter Name",
    className: "input-param-table-header",
  },
  {
    id: "nodeTypeName",
    displayName: "Data Type",
    className: "input-param-table-header",
  },
  {
    id: "nodeTypeId",
    displayName: "Node Type ID",
    className: "input-param-table-header",
  },
];
export const outputParamsHeader = [
  {
    id: "displayName",
    displayName: "Output Parameter Name",
    className: "output-param-table-header",
  },
  {
    id: "nodeTypeName",
    displayName: "Data Type",
    className: "output-param-table-header",
  },
  {
    id: "nodeTypeId",
    displayName: "Node Type ID",
    className: "output-param-table-header",
  },
];
export const getFormattedBrowsePageData = (
  browseResponse: any,
  parentId: string,
  level: number
) => {
  const flattenedData = {} as browseTypes.IFlattenedNodeDataObj;
  const formattedTreeData = [] as any;
  const flattenNodes = (
    browseResponse: any,
    parentId: string,
    level: number
  ) => {
    browseResponse.map((nodeObj: browseTypes.InodeObj) => {
      flattenedData[nodeObj.nodeId] = { ...nodeObj };
      if (
        nodeObj.nodeClass !== "UAVariable" &&
        nodeObj.nodeClass !== "UAMethod"
      ) {
        const formattedNodeObj: browseTypes.IOpcTreeDataObj = {
          id: `${parentId}%${nodeObj.nodeId}%`,
          displayName: nodeObj.displayName,
          rawData: { ...nodeObj },
          level,
        };
        formattedNodeObj["isTitle"] = true;

        formattedNodeObj["isExpandable"] =
          nodeObj.childNodes &&
          nodeObj.childNodes.length > 0 &&
          checkUaVariableChild([...nodeObj.childNodes])
            ? true
            : false;
        formattedNodeObj["isExpanded"] = false;
        formattedNodeObj["isVisible"] = level === 1 ? true : false;
        if (nodeObj.nodeClass === "UAObject") {
          formattedNodeObj.showIcon = true;
          if (nodeObj.nodeTypeName === "FolderType") {
            formattedNodeObj.icon = "abb/folder";
          } else {
            formattedNodeObj.icon = "abb/object";
          }
        }
        // else if (nodeObj.nodeClass === "UAMethod") {
        //   formattedNodeObj.showIcon = true;
        //   formattedNodeObj.icon = "abb/io-signals";
        // }
        formattedTreeData.push({ ...formattedNodeObj });
      }
      if (nodeObj.childNodes) {
        flattenNodes(
          [...nodeObj.childNodes],
          `${parentId}%${nodeObj.nodeId}%`,
          level + 1
        );
      }
    });
    return [flattenedData, formattedTreeData];
  };
  const checkUaVariableChild = (childNodes: browseTypes.InodeObj[]) => {
    let checkUaVariable = false;
    childNodes.map((ietm) => {
      if (ietm.nodeClass !== "UAVariable" && ietm.nodeClass !== "UAMethod") {
        checkUaVariable = true;
        return;
      }
    });
    return checkUaVariable;
  };
  flattenNodes([...browseResponse], parentId, level);
  return [flattenedData, formattedTreeData];
};
export const formatConfiguredData = (
  rawData: browseTypes.IConfiguredDataBinding[]
) => {
  const formattedData: browseTypes.IEachRowObj[] = [];
  const keysToBeMapped = ["isActive", "name", "nodeName", "id", "nodeId"];
  rawData.map((item) => {
    const rowObj: browseTypes.IEachRowObj = {
      id: "",
      data: [],
      rawData: { ...item },
    };
    rowObj.id = item.id;
    rowObj.isClickable = false;
    rowObj.isSelectable = true;
    rowObj.data = [];
    rowObj.additionalInfo = { ...item };
    keysToBeMapped.map((key) => {
      const dataObj: browseTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      if (key !== "isActive") {
        dataObj.rawVal = item[key] ? `${item[key]}` : "";
        dataObj.formattedVal = item[key] ? `${item[key]}` : "";
      } else {
        dataObj.rawVal = item[key] ? `${item[key]}` : "";
        dataObj.formattedVal = (
          <ActiveOrPausedColumnView isActive={item.isActive} />
        );
        dataObj.className = "isactive-column";
      }
      rowObj.data.push({ ...dataObj });
    });
    formattedData.push({ ...rowObj });
  });
  return formattedData;
};
export const formatChildrenData = (
  rawData: browseTypes.InodeObj[],
  selectedNode: browseTypes.InodeObj,
  currentObjVariables: browseTypes.IObjVariables[]
) => {
  const formattedData: browseTypes.IEachRowObj[] = [];
  const keysToBeMapped = ["displayName", "nodeTypeId", "browseName"];
  const currentObjVariableIds = currentObjVariables.map((item) => item.nodeId);
  rawData.map((item) => {
    const rowObj: browseTypes.IEachRowObj = {
      id: "",
      data: [],
      rawData: { ...item },
    };
    rowObj.id = item.nodeId;
    rowObj.isClickable = false;
    rowObj.isSelectable =
      item.isSupportedDataType && selectedNode.nodeClass !== "UAMethod";
    if (!item.isSupportedDataType || selectedNode.nodeClass === "UAMethod") {
      rowObj.className = "disabled-variable-row";
    }
    rowObj.data = [];
    rowObj.additionalInfo = { ...item };
    rowObj.isSelected = currentObjVariableIds.indexOf(item.nodeId) >= 0;
    keysToBeMapped.map((key) => {
      const dataObj: browseTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      dataObj.rawVal = item[key] ? `${item[key]}` : "";
      dataObj.formattedVal = item[key] ? `${item[key]}` : "";
      rowObj.data.push({ ...dataObj });
    });
    formattedData.push({ ...rowObj });
  });
  return formattedData;
};

export const formatMethodChildrenData = (
  rawData: browseTypes.InodeObj[],
  selectedNode: browseTypes.InodeObj,
  currentObjMethods: browseTypes.IObjMethods[]
) => {
  const formattedData: browseTypes.IEachRowObj[] = [];
  const keysToBeMapped = ["displayName", "nodeTypeId", "browseName"];
  const currentObjMethodIds = currentObjMethods.map((item) => item.nodeId);
  rawData.map((item) => {
    const rowObj: browseTypes.IEachRowObj = {
      id: "",
      data: [],
      rawData: { ...item },
    };
    rowObj.id = item.nodeId;
    rowObj.isClickable = false;
    rowObj.isSelectable = true;
    rowObj.isViewActionEnabled = true;
    rowObj.isSelectable = item.isSupportedDataType;
    if (!item.isSupportedDataType) {
      rowObj.className = "disabled-variable-row";
    }
    rowObj.data = [];
    rowObj.additionalInfo = { ...item };
    rowObj.isSelected = currentObjMethodIds.indexOf(item.nodeId) >= 0;
    keysToBeMapped.map((key) => {
      const dataObj: browseTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      dataObj.rawVal = item[key] ? `${item[key]}` : "";
      dataObj.formattedVal = item[key] ? `${item[key]}` : "";
      rowObj.data.push({ ...dataObj });
    });
    formattedData.push({ ...rowObj });
  });
  return formattedData;
};

export const formatSelectedMethodData = (
  rawData: browseTypes.ImethodObj[],
  argumentType: "input" | "output"
) => {
  const formattedData: browseTypes.IEachRowObj[] = [];
  const keysToBeMapped = ["displayName", "nodeTypeName", "nodeTypeId"];
  rawData.map((item) => {
    const rowObj: browseTypes.IEachRowObj = {
      id: "",
      data: [],
      rawData: { ...item },
    };
    rowObj.id = item.nodeId;
    rowObj.isClickable = false;
    rowObj.data = [];
    rowObj.additionalInfo = { ...item };
    keysToBeMapped.map((key) => {
      const dataObj: browseTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      dataObj.rawVal = item[key] ? `${item[key]}` : "";
      dataObj.formattedVal = item[key] ? `${item[key]}` : "";
      rowObj.data.push({ ...dataObj });
    });
    // if (item.type === "Output Parameter") {
    //   formattedOutputData.push({ ...rowObj });
    // } else {
    //   formattedInputData.push({ ...rowObj });
    // }
    formattedData.push({ ...rowObj });
  });
  return formattedData;
};

export const formatEnabledMethodData = (
  rawData: browseTypes.IEnabledMethodObj[]
) => {
  const formattedData: browseTypes.IEachRowObj[] = [];
  const keysToBeMapped = [
    "methodName",
    "nodeName",
    "objectName",
    "objectId",
    "nodeId",
  ];
  const flattenedEnabledMethodData: browseTypes.IFlattenedEnabledMethodObj[] =
    [];
  rawData.map((item) => {
    item.methods.map((methodObj) => {
      const modifiedItem: browseTypes.IFlattenedEnabledMethodObj = {
        objectId: item.id,
        methodName: methodObj.name ? methodObj.name : "",
        nodeId: methodObj.nodeId ? methodObj.nodeId : "",
        objectName: item.name ? item.name : "",
        nodeName: item.nodeName ? item.nodeName : "",
      };
      flattenedEnabledMethodData.push({ ...modifiedItem });
    });
  });
  [...flattenedEnabledMethodData].map((item) => {
    const rowObj: browseTypes.IEachRowObj = {
      id: "",
      data: [],
      rawData: { ...item },
    };
    rowObj.id = item.nodeId;
    rowObj.isClickable = false;
    rowObj.isSelectable = true;
    rowObj.data = [];
    rowObj.additionalInfo = { ...item };
    keysToBeMapped.map((key) => {
      const dataObj: browseTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      dataObj.rawVal = item[key] ? `${item[key]}` : "";
      dataObj.formattedVal = item[key] ? `${item[key]}` : "";
      rowObj.data.push({ ...dataObj });
    });
    formattedData.push({ ...rowObj });
  });
  return [formattedData, flattenedEnabledMethodData];
};
