import React, { useEffect } from "react";
import "./Servers.css";
import ServersGrid from "./Grid/Grid";
import ConnectToServerModal from "./ConnectServersModal/ConnectServersModal";
import * as serversType from "./Type/TypeServers";
import {
  isBrowsingAllowed,
  viewByEdgesGridHeader,
  viewByserversGridHeaders,
} from "./DataFormatter/DataFormatterServers";
import {
  AppMainContent,
  Button,
  Input,
  ToggleButton,
  ToggleButtonGroup,
} from "@abb/abb-common-ux-react";
import BrowsePage from "../Browse/Browse";
import WarningDialog from "../../Common/Components/WarningDialog/WarningDialog";
import CommonLoader from "../../Common/Components/CommonLoader/CommonLoader";
import * as serversActions from "./Action/ActionServers";
import * as browseActions from "../Browse/Action/ActionBrowse";
import * as commonActions from "../../Common/Action/ActionCommon";
import { IState } from "../../Reducer";
import { serversGridFixedWidth } from "../../Utils/Constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "underscore";
import { envSettings } from "../../EnviornmentSettings/Settings";

let filterTimer: any;
let resizeCount = 0;
const urlprefix = envSettings.prefix === "/" ? "" : envSettings.prefix + "/";
const urlCopy = urlprefix;
let modifiedUrlPrefix = urlCopy.replace(/^\//, "");
modifiedUrlPrefix = modifiedUrlPrefix.replace(/\/\/$/, "/");
const Servers = (props: RouteComponentProps) => {
  const serversState = useSelector((state: IState) => state.serversState);
  const commonState = useSelector((state: IState) => state.commonState);
  const [resizeCountState, handleResieCount] = React.useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!serversState.showBrowse) {
      dispatch(commonActions.getPreference());
      dispatch(serversActions.getServers());
      dispatch(serversActions.getEdgeList());
      dispatch(browseActions.resetBrowseState());
    }
  }, []);
  useEffect(() => {
    clearTimeout(filterTimer);
    filterTimer = setTimeout(
      () => dispatch(serversActions.performFilter()),
      500
    );
  }, [serversState.filterText]);
  useEffect(() => {
    (window as any).visualViewport!.addEventListener("resize", viewportHandler);
    return () => {
      (window as any).visualViewport!.removeEventListener(
        "resize",
        viewportHandler
      );
    };
  }, []);
  function viewportHandler(event: any) {
    resizeCount = resizeCount + 1;
    handleResieCount(resizeCount);
  }
  const onActionClick = (
    actionType: "viewOptions" | "delete",
    rowObj: serversType.IServersGridRowObj
  ) => {
    if (actionType === "delete") {
      dispatch(serversActions.selectServerToDelete({ ...rowObj }));
    } else if (actionType === "viewOptions") {
      dispatch(serversActions.showMoreOptions({ ...rowObj }));
    }
  };
  const onPopUpAction = (
    actionType: "openSettings" | "copyEdgeId" | "closePopUp" | "browse",
    rowObj: serversType.IServersGridRowObj
  ) => {
    if (actionType === "openSettings") {
      dispatch(serversActions.hideMoreOptions());
      dispatch(
        serversActions.showConnectToServerModal({ ...rowObj }, "update")
      );
      dispatch(
        serversActions.getCurrentServer(
          rowObj.rawData.id,
          rowObj.rawData.uaModuleId,
          rowObj.rawData.edgeId
        )
      );
    } else if (actionType === "copyEdgeId") {
      dispatch(serversActions.hideMoreOptions());
      let copyText = rowObj.additionalInfo ? rowObj.additionalInfo.edgeId : "";
      const el = document.createElement("textarea");
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    } else if (actionType === "browse") {
      props.history.push(
        `/browse?edge&&${rowObj.rawData.edgeId}&&uamodule&&${rowObj.rawData.uaModuleId}&&opcserver&&${rowObj.rawData.id}`
      );
    } else {
      dispatch(serversActions.hideMoreOptions());
    }
  };
  const onExpandCollapse = (item: serversType.IServersGridRowObj) => {
    dispatch(serversActions.handleExpandCollpase({ ...item }));
  };
  const handleRowClick = (item: serversType.IServersGridRowObj) => {
    if (
      isBrowsingAllowed(
        item.rawData.configureState,
        item.rawData.lastConfiguredTimestamp
      )
    ) {
      props.history.push(
        `/browse?edge&&${item.rawData.edgeId}&&uamodule&&${item.rawData.uaModuleId}&&opcserver&&${item.rawData.id}`
      );
    } else {
      dispatch(serversActions.showConnectToServerModal({ ...item }, "update"));
      dispatch(
        serversActions.getCurrentServer(
          item.rawData.id,
          item.rawData.uaModuleId,
          item.rawData.edgeId
        )
      );
    }
  };
  const onConfirmDelete = () => {
    dispatch(
      serversActions.submitDeleteServer(
        serversState.selectedServer.rawData.id,
        serversState.selectedServer.rawData.uaModuleId
      )
    );
  };
  const handleSort = (
    id: string,
    sortDirection: "asc" | "desc" | "unsorted" | undefined
  ) => {
    dispatch(serversActions.handleSort(id, sortDirection!));
  };
  return (
    <React.Fragment>
      {commonState.isLoading ||
        serversState.isLoading ||
        serversState.isLoadingAllServers ? (
        <CommonLoader />
      ) : (
        <div />
      )}
      {serversState.isLoading || serversState.isLoadingAllServers ? (
        <div className="loader-mask" />
      ) : (
        <div />
      )}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent className="servers-view-wrap">
        {!serversState.showBrowse ? (
          <div
            className={
              commonState.theme === "light"
                ? `servers-container light-theme`
                : `servers-container dark-theme`
            }
          >
            <div className="server-header-label">OPC Servers</div>
            <hr />
            <div className="filter-toggle-wrap">
              <div className="servers-input-wrap">
                <Input
                  dataType="text"
                  icon="abb/search"
                  className="servers-search-input"
                  value={serversState.filterText}
                  onValueChange={(value) => {
                    dispatch(serversActions.handleFilterInput(value));
                  }}
                />
              </div>
              <div className="toggle-view-wrap">
                <ToggleButtonGroup
                  selected={serversState.viewBy === "edge" ? [1] : [0]}
                  onChange={(v) => {
                    dispatch(
                      serversActions.toggleView(v[0] === 0 ? "server" : "edge")
                    );
                  }}
                  sizeClass="small"
                  multiselect={false}
                >
                  <ToggleButton text="View by Server" icon="abb/list" />
                  <ToggleButton text="Group by Edge" icon="abb/hierarchy" />
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="add-server-button-wrap">
              <Button
                type="primary-blue"
                sizeClass="medium"
                className="add-server-button"
                icon="abb/plus"
                onClick={() =>
                  dispatch(
                    serversActions.showConnectToServerModal(
                      {} as serversType.IServersGridRowObj,
                      "add"
                    )
                  )
                }
              />
            </div>
            {serversState.viewBy === "edge" ? (
              <div className="servers-grid-wrap">
                <ServersGrid
                  data={serversState.viewByEdgesData}
                  header={[...viewByEdgesGridHeader]}
                  isViewOptionsActionEnabled={true}
                  isDeleteActionEnabled={true}
                  isExpansionEnabled={true}
                  onActionClick={onActionClick}
                  onExpandCollapse={onExpandCollapse}
                  noDataText={serversState.isLoading || serversState.isLoadingAllServers ? "Loading..." : "No data"}
                  selectedRow={{ ...serversState.selectedServer }}
                  showPopUp={serversState.showOptionsPopup}
                  onPopUpAction={onPopUpAction}
                  onRowClick={handleRowClick}
                  viewBy="edge"
                  minWidth={
                    document.documentElement.clientWidth - serversGridFixedWidth
                  }
                  key={`server-grid-${resizeCountState}`}
                />
              </div>
            ) : (
              <div className="servers-grid-wrap">
                <ServersGrid
                  data={serversState.viewByServersData}
                  header={[...viewByserversGridHeaders]}
                  isViewOptionsActionEnabled={true}
                  isDeleteActionEnabled={true}
                  isExpansionEnabled={false}
                  onActionClick={onActionClick}
                  onExpandCollapse={onExpandCollapse}
                  noDataText={serversState.isLoading || serversState.isLoadingAllServers ? "Loading..." : "No data"}
                  selectedRow={{ ...serversState.selectedServer }}
                  showPopUp={serversState.showOptionsPopup}
                  onPopUpAction={onPopUpAction}
                  isSortEnabled={true}
                  sortDirection={
                    serversState.sortInfo.direction
                      ? serversState.sortInfo.direction
                      : "desc"
                  }
                  sortedColumnId={
                    serversState.sortInfo.colId
                      ? serversState.sortInfo.colId
                      : viewByserversGridHeaders[0].id
                  }
                  onSort={handleSort}
                  onRowClick={handleRowClick}
                  viewBy="server"
                  minWidth={
                    document.documentElement.clientWidth - serversGridFixedWidth
                  }
                  key={`server-grid-${resizeCountState}`}
                />
              </div>
            )}
            {serversState.showConnectToServerModal ? (
              <ConnectToServerModal />
            ) : (
              <div />
            )}
            {serversState.showWarningDialog ? (
              <WarningDialog
                warningTitle={`Remove ${serversState.selectedServer.displayName} ?`}
                warningMessage={`Server "${serversState.selectedServer.displayName}" will be removed.`}
                confirmButtonText={"Remove"}
                cancelButtonText={"Cancel"}
                onCancel={() => dispatch(serversActions.hideDeleteWarning())}
                onConfirm={() => onConfirmDelete()}
              />
            ) : (
              <div />
            )}
          </div>
        ) : (
          <BrowsePage />
        )}
      </AppMainContent>
    </React.Fragment>
  );
};

export default withRouter(Servers);
