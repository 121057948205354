import React from "react";
import CommonLoader from "../Common/Components/CommonLoader/CommonLoader";
import { Route } from "react-router-dom";
import Axios from "axios";
import { envSettings } from "../EnviornmentSettings/Settings";
import { commonAuthHelper } from "../App";
import UnAuthorizedView from "../Common/Components/UnAuthorizedView/UnAuthorizedView";

interface privateRouteProps {
  component: React.ComponentClass<any>;
  exact?: boolean;
  path: string;
}

export const PrivateRoute = ({ component, path, exact }: privateRouteProps) => {
  const token = commonAuthHelper.getAccessToken();
  if (envSettings.isAuthenticationEnabled) {
    if (
      !!component &&
      token &&
      token !== null
    ) {
      Axios.defaults.headers.common["X-Tenant"] = `${localStorage.getItem(
        "tenant"
      )}`;
      Axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      if (envSettings.connectivityMode !== "connected") {
        const oidcStorage = JSON.parse(
          localStorage.getItem(
            `oidc.user:${envSettings.authority}:${envSettings.clientId}`
          )!
        );
        if (!!oidcStorage && !!oidcStorage.id_token) {
          localStorage.setItem("id_token", oidcStorage.id_token);
        }
      }
      return <Route {...{ path, exact }} component={component} />;
    } else {
      if (
        localStorage.getItem("showLanding") !== "true" &&
        envSettings.connectivityMode !== "connected"
      ) {
        return <></>
      }
      return <UnAuthorizedView />;
    }
  } else {
    return <Route {...{ path, exact }} component={component} />;
  }
};
