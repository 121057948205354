import {
  formatViewByEdgesDataData,
  formatEdgeData,
  formatViewByServersData,
} from "./../DataFormatter/DataFormatterServers";
import {
  put,
  takeEvery,
  delay,
  fork,
  cancel,
  take,
  cancelled,
} from "redux-saga/effects";
import axios from "axios";
import * as serversTypes from "../Type/TypeServers";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import { envSettings } from "../../../EnviornmentSettings/Settings";
import * as apiEndPoints from "../../../Utils/ApiEndPoints";
import * as uuid from "uuid";
import { OpcServerStatus } from "../../../Utils/Constants";
import {
  getErrorMessage,
  isConfigureStatePollingNeeded,
  isSessionStatePollingNeeded,
  setCommonHeaders,
} from "./../../../Utils/HelperFunctions";
import _ from "underscore";

let getServerStatus: any;
let getCurrentServer: any;
let getSessionStateData: any;
let getServerAfterUpload: any;

function* fetchServers(action: serversTypes.IGetServers): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.get<any>(apiEndPoints.getServers);
    const receivedData = !envSettings.isOfflineMode
      ? [...fetchedData.data]
      : [...fetchedData.data.data];
    const formattdViewByEdgesDataData: any = formatViewByEdgesDataData([
      ...receivedData,
    ]);
    const formattedViewByServersData: any = formatViewByServersData([
      ...receivedData,
    ]);
    yield put({
      type: "RECEIVED_SERVERS",
      viewByEdgesData: [...formattdViewByEdgesDataData],
      viewByServersData: [...formattedViewByServersData],
      rawServersData: [...receivedData],
    });
    yield put({
      type: "GET_SERVERS_STATUS",
      rawServersData: [...receivedData],
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}

function* fetchCurrentServer(action: serversTypes.IGetCurrentServer): any {
  setCommonHeaders();
  try {
    yield cancel(getServerStatus);
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getCurrentServer}/${action.serverId}`
    );
    const receivedData: serversTypes.IRawServersObj = !envSettings.isOfflineMode
      ? { ...fetchedData.data }
      : { ...fetchedData.data.data };
    if (
      isConfigureStatePollingNeeded(receivedData.configureState) ||
      fetchedData.status === 204
    ) {
      if (fetchedData.status === 200) {
        yield put({
          type: "RECEIVED_CURRENT_SERVER",
          payload: {
            ...receivedData,
            edgeId: action.edgeId,
            uaModuleId: action.uaModuleId,
          },
          isPollingNeeded: true,
        });
      }
      yield delay(15000);
      yield put({
        type: "GET_CURRENT_SERVER",
        serverId: action.serverId,
        edgeId: action.edgeId,
        uaModuleId: action.uaModuleId,
      });
    } else {
      if (fetchedData.status === 200) {
        yield put({
          type: "RECEIVED_CURRENT_SERVER",
          payload: {
            ...receivedData,
            edgeId: action.edgeId,
            uaModuleId: action.uaModuleId,
          },
          isPollingNeeded: false,
        });
      }
      if (
        receivedData.configureState !== undefined &&
        receivedData.configureState !== null
      ) {
        yield put({
          type: "GET_SESSION_STATE_DATA",
          serverId: action.serverId,
        });
      }
    }
  } catch (e) {
    console.log("error", e);
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: "ON_FAIL_SERVERS",
      });
    }
  }
}

function* fetchServerInBackgroundAfterUpload(
  action: serversTypes.IGetServerInBackgroundAfterUpload
): any {
  setCommonHeaders();
  try {
    yield cancel(getServerStatus);
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getCurrentServer}/${action.serverId}`
    );
    const receivedData: serversTypes.IRawServersObj = !envSettings.isOfflineMode
      ? { ...fetchedData.data }
      : { ...fetchedData.data.data };
    if (
      isConfigureStatePollingNeeded(receivedData.configureState) ||
      fetchedData.status === 204
    ) {
      if (fetchedData.status === 200) {
        yield put({
          type: "RECEIVED_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
          payload: {
            ...receivedData,
            edgeId: action.edgeId,
            uaModuleId: action.uaModuleId,
          },
          isPollingNeeded: true,
        });
      }
      yield delay(15000);
      yield put({
        type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
        serverId: action.serverId,
        edgeId: action.edgeId,
        uaModuleId: action.uaModuleId,
      });
    } else {
      if (fetchedData.status === 200) {
        yield put({
          type: "RECEIVED_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
          payload: {
            ...receivedData,
            edgeId: action.edgeId,
            uaModuleId: action.uaModuleId,
          },
          isPollingNeeded: true,
        });
      }
    }
  } catch (e) {
    console.log("error", e);
  }
}

function* fetchSessionState(action: serversTypes.IGetSessionStateData): any {
  setCommonHeaders();
  yield cancel(getCurrentServer);
  try {
    const fetchedData = yield axios.get<any>(
      `${apiEndPoints.getCurrentServer}/${action.serverId}/sessionState`
    );
    const receivedData: serversTypes.ISessionStateData =
      !envSettings.isOfflineMode
        ? { ...fetchedData.data }
        : { ...fetchedData.data.data };
    if (
      isSessionStatePollingNeeded(receivedData.sessionState) ||
      fetchedData.status === 204
    ) {
      yield put({
        type: "RECEIVED_SESSION_STATE_DATA",
        payload: fetchedData.status === 200 ? { ...receivedData } : {},
        pollingStatus: true,
      });
      yield delay(6000);
      yield put({
        type: "GET_SESSION_STATE_DATA",
        serverId: action.serverId,
      });
    } else {
      yield put({
        type: "RECEIVED_SESSION_STATE_DATA",
        payload: { ...receivedData },
        pollingStatus: false,
      });
    }
  } catch (e) {
    console.log("error", e);
    yield delay(6000);
    yield put({
      type: "GET_SESSION_STATE_DATA",
      serverId: action.serverId,
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: "ON_FAIL_SERVERS",
      });
    }
  }
}

function* fetchServersStatus(action: serversTypes.IGetServersStatus): any {
  setCommonHeaders();
  try {
    setCommonHeaders();
    const fetchedData = yield axios.post<any>(apiEndPoints.getServersStatus, [
      ...action.rawServersData,
    ]);
    const receivedData: serversTypes.IRawServersObj[] =
      !envSettings.isOfflineMode
        ? [...fetchedData.data]
        : [...fetchedData.data.data];
    const groupedStatusData = _.groupBy(receivedData, "id");
    const initialRawData = [...action.rawServersData];
    initialRawData.map((item) => {
      if (item.id) {
        let statusObj = groupedStatusData[item.id][0];
        item.configureState = statusObj.configureState;
        item.configureStatusMessage = statusObj.configureStatusMessage;
        item.lastConfigureRequestedTimestamp =
          statusObj.lastConfigureRequestedTimestamp;
        item.lastConfiguredTimestamp = statusObj.lastConfiguredTimestamp;
        item.errorDetails = statusObj.errorDetails;
      }
    });
    const formattdViewByEdgesDataData: any = formatViewByEdgesDataData([
      ...initialRawData,
    ]);
    const formattedViewByServersData: any = formatViewByServersData([
      ...initialRawData,
    ]);
    yield put({
      type: "RECEIVED_SERVERS",
      viewByEdgesData: [...formattdViewByEdgesDataData],
      viewByServersData: [...formattedViewByServersData],
    });
    if (isPollingNeeded([...initialRawData])) {
      yield delay(5000);
      yield put({
        type: "GET_SERVERS_STATUS",
        rawServersData: [...initialRawData],
      });
    }
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  } finally {
    if (yield cancelled()) {
      yield put({
        type: "ON_FAIL_SERVERS",
      });
    }
  }
}
const isPollingNeeded = (servers: serversTypes.IRawServersObj[]) => {
  if (servers.length === 0) {
    return;
  }
  let isPollingRequired = false;
  servers.map((serverObj) => {
    if (isConfigureStatePollingNeeded(serverObj.configureState)) {
      isPollingRequired = true;
      return;
    }
  });
  return isPollingRequired;
};

function* fetchEdgeList(action: serversTypes.IGetEdgeList): any {
  try {
    setCommonHeaders();
    const fetchedData = yield axios.get<any>(apiEndPoints.getEdgeList);
    const receivedData = !envSettings.isOfflineMode
      ? [...fetchedData.data]
      : [...fetchedData.data.data];
    const formattedEdgeDropdownData: commonTypes.IEdgeDropDownData[] =
      formatEdgeData([...receivedData]);
    yield put({
      type: "RECEIVED_EDGE_LIST",
      payload: [...receivedData],
      edgeDropDownData: [...formattedEdgeDropdownData],
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}
function* submitAddServer(action: serversTypes.ISubmitAddServer): any {
  setCommonHeaders();
  try {
    let newId = uuid.v4();
    const payLoad: any = {
      id: newId,
      uaModuleId: action.paramsToBeSubmitted.uaModuleId,
      endpointUrl: action.paramsToBeSubmitted.enPointId.trim(),
      serverDescription: action.paramsToBeSubmitted.description,
      name: action.paramsToBeSubmitted.name.trim(),
      certificate: action.paramsToBeSubmitted.doesRequireSecureConnection
        ? action.paramsToBeSubmitted.certificate
        : "",
      status: OpcServerStatus.Disconnected,
    };
    const fetchedData = yield axios.post(
      `${apiEndPoints.submitAddServer}`,
      payLoad
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Server was added successfully.`,
    });
    const newServerRow = [
      {
        id: newId,
        endpointUrl: action.paramsToBeSubmitted.enPointId.trim(),
        name: action.paramsToBeSubmitted.name.trim(),
        edgeName: action.paramsToBeSubmitted.edgeName,
        edgeId: action.paramsToBeSubmitted.edgeId,
        uaModuleId: action.paramsToBeSubmitted.uaModuleId,
        serverDescription: action.paramsToBeSubmitted.description,
        nodeSetCachedAt: "",
        status: OpcServerStatus.Disconnected,
        certificate: action.paramsToBeSubmitted.doesRequireSecureConnection
          ? action.paramsToBeSubmitted.certificate
          : "",
        configureState: 0,
        configureStatusMessage: "",
        errorDetails: "",
        lastConfigureRequestedTimestamp: "",
        lastConfiguredTimestamp: "",
      },
    ];
    const formattedServerRow: serversTypes.IServersGridRowObj[] =
      formatViewByServersData([...newServerRow]);

    yield put({
      type: "SHOW_CONNECT_TO_SERVER_MODAL",
      rowObj: formattedServerRow[0],
      method: "update",
    });
    yield put({
      type: "GET_SERVERS",
    });
    yield put({
      type: "GET_CURRENT_SERVER",
      serverId: newId,
      uaModuleId: action.paramsToBeSubmitted.uaModuleId,
      edgeId: action.paramsToBeSubmitted.edgeId,
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}
function* submitUpdateServer(action: serversTypes.ISubmitUpdateServer): any {
  setCommonHeaders();
  try {
    const payLoad: any = {
      id: action.paramsToBeSubmitted.serverId,
      uaModuleId: action.paramsToBeSubmitted.uaModuleId,
      endpointUrl: action.paramsToBeSubmitted.enPointId.trim(),
      serverDescription: action.paramsToBeSubmitted.description,
      name: action.paramsToBeSubmitted.name.trim(),
      certificate: action.paramsToBeSubmitted.doesRequireSecureConnection
        ? action.paramsToBeSubmitted.certificate
        : "",
      status: action.paramsToBeSubmitted.status,
    };
    const fetchedData = yield axios.put(
      `${apiEndPoints.submitUpdateServer}`,
      payLoad
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Server was updated successfully.`,
    });
    yield put({
      type: "HIDE_CONNECT_TO_SERVER_MODAL",
      shouldRefreshAfterClosingUpdate: false,
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
    yield put({
      type: "GET_SERVERS",
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}
function* submitDeleteServer(action: serversTypes.ISubmitDeleteServer): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.delete(
      `${apiEndPoints.submitDeleteServer}`,
      {
        data: { id: action.serverId, usModuleId: action.uaModuleId },
      }
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "success",
      message: `Server was successfully removed.`,
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
    yield put({
      type: "HIDE_WARNING_DIALOG",
    });
    yield put({
      type: "GET_SERVERS",
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}

function* submitAutoConfig(action: serversTypes.ISubmitAutoConfig): any {
  setCommonHeaders();
  try {
    const fetchedData = yield axios.post(
      `${environmentSettings.webapiUrl}/UaModules/${action.uaModuleId}/OpcServers/${action.serverId}/configure`,
      {}
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "info",
      message: `Automatic configuration initiated.`,
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
    yield put({
      type: "HIDE_NODESET_UPLOAD_DIALOG",
    });
    yield put({
      type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
      serverId: action.serverId,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
      yield put({
        type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
        serverId: action.serverId,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}

function* submitNodeSetAfterUpload(
  action: serversTypes.ISubmitNodeSetAfterUpload
): any {
  setCommonHeaders();
  try {
    const data = new FormData();
    const currentFiles: any[] = [...action.selectedNodeSetFiles];
    for (let i = 0; currentFiles[i]; i++) {
      data.append("nodeSetFiles", currentFiles[i]);
    }
    const fetchedData = yield axios.post(
      `${environmentSettings.webapiUrl}/OpcServers/${action.serverId}/Configure`,
      data
    );
    yield put({
      type: "SHOW_NOTIFICATION",
      notificationType: "info",
      message: `Manual configuration initiated.`,
    });
    yield put({
      type: "ON_SUCCESS_SERVERS",
    });
    yield put({
      type: "HIDE_NODESET_UPLOAD_DIALOG",
    });
    yield put({
      type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
      serverId: action.serverId,
    });
  } catch (e) {
    console.log("error", e);
    if (
      e.response &&
      (e.response.status === 401 || e.response.status === 403)
    ) {
      yield put({
        type: "SHOW_UNAUTHORIZED_PAGE",
        errorType: `${e.response.status}`,
      });
    } else {
      const errorMessage = getErrorMessage({ ...e });
      yield put({
        type: "SHOW_NOTIFICATION",
        notificationType: "alarm",
        message: errorMessage,
      });
      yield put({
        type: "GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD",
        serverId: action.serverId,
      });
    }
    yield put({
      type: "ON_FAIL_SERVERS",
    });
  }
}
function* handleSessionStateTimeOut(
  action: serversTypes.IHandleSessionStateTimeOut
) {
  try {
    yield cancel(getSessionStateData);
  } catch (e) {
    console.log("error", e);
  }
}
function* stopConfigureStatePollingAfterClosingModal(
  action: serversTypes.IHideConnectToServerModal
) {
  try {
    yield cancel(getCurrentServer);
  } catch (e) {
    console.log("error", e);
  }
}
function* stopSessionStatePollingAfterClosingModal(
  action: serversTypes.IHideConnectToServerModal
) {
  try {
    yield cancel(getSessionStateData);
  } catch (e) {
    console.log("error capture", e);
  }
}
function* handleBackGroundCallPostClosingModal(
  action: serversTypes.IHideConnectToServerModal
) {
  try {
    yield cancel(getServerAfterUpload);
  } catch (e) {
    console.log("error capture", e);
  }
}
function* refreshLandingAdterClosingModal(
  action: serversTypes.IHideConnectToServerModal
) {
  try {
    yield put({
      type: "GET_SERVERS",
    });
  } catch (e) {
    console.log("error capture", e);
  }
}

export default function* actionWatcherServers() {
  yield takeEvery("GET_SERVERS", fetchServers);
  yield takeEvery("GET_EDGE_LIST", fetchEdgeList);
  yield takeEvery("SUBMIT_ADD_SERVER", submitAddServer);
  yield takeEvery("SUBMIT_UPDATE_SERVER", submitUpdateServer);
  yield takeEvery("SUBMIT_DELETE_SERVER", submitDeleteServer);
  yield takeEvery("SUBMIT_NODE_SET_AUTO_CONFIG", submitAutoConfig);
  yield takeEvery("SUBMIT_NODE_SET_AFTER_UPLOAD", submitNodeSetAfterUpload);
  yield takeEvery("HANDLE_SESSION_STATE_TIMEOUT", handleSessionStateTimeOut);
  yield takeEvery(
    "HIDE_CONNECT_TO_SERVER_MODAL",
    (action: serversTypes.IHideConnectToServerModal) => {
      return action.shouldRefreshAfterClosingUpdate
        ? refreshLandingAdterClosingModal(action)
        : () => {};
    }
  );
  yield takeEvery(
    "HIDE_CONNECT_TO_SERVER_MODAL",
    stopConfigureStatePollingAfterClosingModal
  );
  yield takeEvery(
    "HIDE_CONNECT_TO_SERVER_MODAL",
    stopSessionStatePollingAfterClosingModal
  );
  yield takeEvery(
    "HIDE_CONNECT_TO_SERVER_MODAL",
    handleBackGroundCallPostClosingModal
  );
}
export function* watchHandleServerStatus(): any {
  while (true) {
    const action = yield take("GET_SERVERS_STATUS");
    getServerStatus = yield fork(fetchServersStatus, action);
  }
}
export function* watchHandleCurrentServerBackgroundCall(): any {
  while (true) {
    const action = yield take("GET_CURRENT_SERVER");
    getCurrentServer = yield fork(fetchCurrentServer, action);
  }
}
export function* watchSessionStateCall(): any {
  while (true) {
    const action = yield take("GET_SESSION_STATE_DATA");
    getSessionStateData = yield fork(fetchSessionState, action);
  }
}
export function* watchServerAfterUpload(): any {
  while (true) {
    const action = yield take("GET_SERVER_IN_BACKGROUND_AFTER_UPLOAD");
    getServerAfterUpload = yield fork(
      fetchServerInBackgroundAfterUpload,
      action
    );
  }
}
