import * as browsePageTypes from "../Type/TypeBrowse";
import * as serverTypes from "../../Servers/Type/TypeServers";
import _ from "underscore";
import { availableTypes } from "../../../Utils/Constants";

export interface IBrowsePageStateType {
  serverId: string;
  uaModuleId: string;
  edgeId: string;
  selectedServer: serverTypes.IRawServersObj;
  formattedTreeNodes: browsePageTypes.IOpcTreeDataObj[];
  flatenedNodes: browsePageTypes.IFlattenedNodeDataObj;
  treeData: browsePageTypes.InodeObj[];
  selectedNodeId: string;
  selectedNode: browsePageTypes.InodeObj;
  selectedNodeDataType: string;
  selectedNodeChildren: browsePageTypes.InodeObj[];
  isNodeAutoClose: boolean;
  originalDisplayName: string;
  currentDisplayName: string;
  originalSamplingInterval: string;
  currentSamplingInterval: string;
  originalObjVariables: browsePageTypes.IObjVariables[];
  currentObjVariables: browsePageTypes.IObjVariables[];
  dataBindingResponse: browsePageTypes.IDataBindingResponse;
  isLoading: boolean;
  isDataBindingLoading: boolean;
  isSelectedMethodDataLoading: boolean;
  formattedInputParameter: browsePageTypes.IEachRowObj[];
  formattedOutputParameter: browsePageTypes.IEachRowObj[];
  selectedMethodData: browsePageTypes.ISelectedMethodDataResponse;
  isBrowseDataLoading: boolean;
  isConfiguredDataLoading: boolean;
  typeInfoData: browsePageTypes.ITypeInformation;
  desiredTypeData: browsePageTypes.IDesiredTypeData;
  desiredType: string;
  showDesiredTypeModal: boolean;
  desiredTypeModalMessage: string;
  showConfiguredDataPage: boolean;
  rawConfiguredData: browsePageTypes.IConfiguredDataBinding[];
  formattedConfiguredData: browsePageTypes.IEachRowObj[];
  selectedConfiguredDataIds: string[];
  sortDirection: "asc" | "desc";
  sortColumnId: string;
  variableSortDirection: "asc" | "desc";
  variableSortColumnId: string;
  showFilterVariableData: boolean;
  variableFilters: browsePageTypes.IFilterParams;
  methodSortDirection: "asc" | "desc";
  methodSortColumnId: string;
  showFilterMethodData: boolean;
  methodFilters: browsePageTypes.IFilterParams;
  showNodeDetails: boolean;
  isEnabledMethodsLoading: boolean;
  rawEnabledMethodData: browsePageTypes.IEnabledMethodObj[];
  formattedEnabledMethodData: browsePageTypes.IEachRowObj[];
  flattenedEnabledMethodData: browsePageTypes.IFlattenedEnabledMethodObj[];
  selectedEnabledMethodDataIds: string[];
  enabledMethodsortDirection: "asc" | "desc";
  enabledMethodsortColumnId: string;
  showConfiguredObjects: boolean;
  showEnabledMethods: boolean;
  originalObjMethods: browsePageTypes.IObjMethods[];
  currentObjMethods: browsePageTypes.IObjMethods[];
  showInputOutputParametersDialog: boolean;
  currentlyViewingMethod: browsePageTypes.IEachRowObj;
  filteredVariableNodes: browsePageTypes.InodeObj[];
  filteredMethodNodes: browsePageTypes.InodeObj[];
  typeVersion: number | undefined;
}

const initialState: IBrowsePageStateType = {
  serverId: "",
  uaModuleId: "",
  edgeId: "",
  selectedServer: {} as serverTypes.IRawServersObj,
  treeData: [],
  formattedTreeNodes: [],
  flatenedNodes: {} as browsePageTypes.IFlattenedNodeDataObj,
  selectedNodeId: "",
  selectedNodeDataType: "",
  selectedNode: {} as browsePageTypes.InodeObj,
  selectedNodeChildren: [],
  isLoading: false,
  isDataBindingLoading: false,
  isSelectedMethodDataLoading: false,
  formattedInputParameter: [],
  formattedOutputParameter: [],
  selectedMethodData: {} as browsePageTypes.ISelectedMethodDataResponse,
  isNodeAutoClose: false,
  originalDisplayName: "",
  currentDisplayName: "",
  originalSamplingInterval: "1000",
  currentSamplingInterval: "1000",
  originalObjVariables: [],
  currentObjVariables: [],
  dataBindingResponse: {} as browsePageTypes.IDataBindingResponse,
  isBrowseDataLoading: false,
  isConfiguredDataLoading: false,
  typeInfoData: {} as browsePageTypes.ITypeInformation,
  desiredTypeData: {} as browsePageTypes.IDesiredTypeData,
  desiredType: "",
  showDesiredTypeModal: false,
  desiredTypeModalMessage: "",
  showConfiguredDataPage: false,
  rawConfiguredData: [],
  formattedConfiguredData: [],
  selectedConfiguredDataIds: [],
  sortDirection: "desc",
  sortColumnId: "name",
  variableSortDirection: "desc",
  variableSortColumnId: "displayName",
  showFilterVariableData: false,
  variableFilters: {} as browsePageTypes.IFilterParams,
  methodSortDirection: "desc",
  methodSortColumnId: "displayName",
  showFilterMethodData: false,
  methodFilters: {} as browsePageTypes.IFilterParams,
  showNodeDetails: false,
  isEnabledMethodsLoading: false,
  rawEnabledMethodData: [],
  formattedEnabledMethodData: [],
  flattenedEnabledMethodData: [],
  selectedEnabledMethodDataIds: [],
  enabledMethodsortDirection: "desc",
  enabledMethodsortColumnId: "name",
  showConfiguredObjects: false,
  showEnabledMethods: false,
  originalObjMethods: [],
  currentObjMethods: [],
  showInputOutputParametersDialog: false,
  currentlyViewingMethod: {} as browsePageTypes.IEachRowObj,
  filteredVariableNodes: [],
  filteredMethodNodes: [],
  typeVersion: undefined,
};

export default function (
  state: IBrowsePageStateType = initialState,
  action: browsePageTypes.IBrowsePageActions
): IBrowsePageStateType {
  switch (action.type) {
    case "GET_BROWSE_TREE_DATA":
      return {
        ...state,
        serverId: action.selectedServerId,
        uaModuleId: action.selectedUaModuleId,
        edgeId: action.edgeId,
        isBrowseDataLoading: true,
        typeVersion: undefined,
      };
    case "RECEIVED_BROWSE_TREE_DATA":
      return {
        ...state,
        treeData: [...action.treeData],
        formattedTreeNodes: [...action.formattedNodeTreeData],
        flatenedNodes: {
          ...action.flattenedNodeData,
        } as browsePageTypes.IFlattenedNodeDataObj,
        isBrowseDataLoading: false,
        typeVersion: action.typeVersion,
      };
    case "RECEIVED_SELECTED_SERVER_FOR_BROWSE":
      return {
        ...state,
        selectedServer: { ...action.payload },
      };
    case "HANDLE_BROWSE_TREE_EXPAND_COLLAPSE":
      return {
        ...state,
        formattedTreeNodes: [...action.formattedTreeData],
      };
    case "ON_NODE_SELECTED_BROWSE_TREE":
      let originalSamplingInterval = state.originalSamplingInterval;
      let currentSamplingInterval = state.currentSamplingInterval;
      if (
        !(
          state.flatenedNodes[action.selectedNode.nodeId].childNodes &&
          state.flatenedNodes[action.selectedNode.nodeId].childNodes.some(
            (child: any) => child.nodeClass === availableTypes.uaVariable
          )
        )
      ) {
        originalSamplingInterval = "1000";
        currentSamplingInterval = "1000";
      }
      const selectedNodeChildren =
        state.flatenedNodes[action.selectedNode.nodeId] &&
        state.flatenedNodes[action.selectedNode.nodeId].childNodes
          ? state.flatenedNodes[action.selectedNode.nodeId].childNodes
          : [];
      const methodChildrenInitial = [...selectedNodeChildren].filter(
        (child) => child.nodeClass === availableTypes.uaMethod
      );
      const variableChildrenInitial = [...selectedNodeChildren].filter(
        (child) => child.nodeClass === availableTypes.uaVariable
      );
      return {
        ...state,
        selectedNodeId: action.selectedNode.nodeId,
        selectedNode: { ...action.selectedNode },
        selectedNodeDataType: action.selectedNode.nodeClass,
        selectedNodeChildren,
        originalObjVariables: [],
        currentObjVariables: [],
        originalSamplingInterval,
        currentSamplingInterval,
        dataBindingResponse: {} as browsePageTypes.IDataBindingResponse,
        desiredTypeData: {} as browsePageTypes.IDesiredTypeData,
        desiredType: "",
        typeInfoData: {} as browsePageTypes.ITypeInformation,
        currentDisplayName: "",
        originalDisplayName: "",
        variableSortDirection: "desc",
        variableSortColumnId: "displayName",
        showFilterVariableData: false,
        variableFilters: {} as browsePageTypes.IFilterParams,
        formattedInputParameter: [],
        formattedOutputParameter: [],
        selectedMethodData: {} as browsePageTypes.ISelectedMethodDataResponse,
        showConfiguredObjects: false,
        showEnabledMethods: false,
        filteredMethodNodes: [...methodChildrenInitial],
        filteredVariableNodes: [...variableChildrenInitial],
      };
    case "GET_DATA_BINDING_RESPONSE":
      let oldSamplingInterval = state.originalSamplingInterval;
      let latestSamplingInterval = state.currentSamplingInterval;
      if (
        !(
          state.flatenedNodes[action.selectedNode.nodeId] &&
          state.flatenedNodes[action.selectedNode.nodeId].childNodes &&
          state.flatenedNodes[action.selectedNode.nodeId].childNodes.some(
            (child: any) => child.nodeClass === availableTypes.uaVariable
          )
        )
      ) {
        oldSamplingInterval = "1000";
        latestSamplingInterval = "1000";
      }
      return {
        ...state,
        isDataBindingLoading: true,
        // originalObjVariables: [],
        // currentObjVariables: [],
        originalSamplingInterval: oldSamplingInterval,
        currentSamplingInterval: latestSamplingInterval,
        dataBindingResponse: {} as browsePageTypes.IDataBindingResponse,
        desiredTypeData: {} as browsePageTypes.IDesiredTypeData,
        currentDisplayName: "",
        originalDisplayName: "",
        typeInfoData: {} as browsePageTypes.ITypeInformation,
        desiredType: "",
      };
    case "RECEIVED_DATA_BINDING_RESPONSE":
      let newSamplingInterval = "1000";
      let newDisplayName = "";
      const bindingVariables: browsePageTypes.IObjVariables[] = [];
      const bindingMethods: browsePageTypes.IObjMethods[] = [];
      if (
        action.dataBindingData.properties &&
        action.dataBindingData.properties.defaultBinding &&
        action.dataBindingData.properties.defaultBinding.samplingInterval &&
        action.dataBindingData.properties.defaultBinding.samplingInterval.value
      ) {
        newSamplingInterval = `${action.dataBindingData.properties.defaultBinding.samplingInterval.value}`;
      }
      if (
        action.dataBindingData &&
        action.dataBindingData.name &&
        action.dataBindingData.name !== ""
      ) {
        newDisplayName = `${action.dataBindingData.name}`;
      } else {
        newDisplayName = `${state.selectedNode.displayName}`;
      }
      if (
        action.dataBindingData &&
        action.dataBindingData.properties &&
        action.dataBindingData.properties.variableBindings
      ) {
        const variableKey = Object.keys(
          action.dataBindingData.properties.variableBindings
        );
        variableKey.forEach((varName: string) => {
          bindingVariables.push({
            opcDataType: state.selectedNode ? state.selectedNode.nodeClass : "",
            nodeId:
              action.dataBindingData.properties &&
              action.dataBindingData.properties.variableBindings &&
              action.dataBindingData.properties.variableBindings[varName]
                ? action.dataBindingData.properties.variableBindings[varName]
                    .nodeId.value
                : "",
            browseName:
              action.dataBindingData.properties &&
              action.dataBindingData.properties.variableBindings &&
              action.dataBindingData.properties.variableBindings[varName]
                ? action.dataBindingData.properties.variableBindings[varName]
                    .browseName.value
                : "",
          });
        });
      }

      if (
        action.dataBindingData &&
        action.dataBindingData.properties &&
        action.dataBindingData.properties.methodBindings
      ) {
        const methodKey = Object.keys(
          action.dataBindingData.properties.methodBindings
        );
        methodKey.forEach((varName: string) => {
          bindingMethods.push({
            opcDataType: state.selectedNode ? state.selectedNode.nodeClass : "",
            nodeId:
              action.dataBindingData.properties &&
              action.dataBindingData.properties.methodBindings &&
              action.dataBindingData.properties.methodBindings[varName]
                ? action.dataBindingData.properties.methodBindings[varName]
                    .nodeId.value
                : "",
            browseName:
              action.dataBindingData.properties &&
              action.dataBindingData.properties.methodBindings &&
              action.dataBindingData.properties.methodBindings[varName] &&
              action.dataBindingData.properties.methodBindings[varName].name
                ? action.dataBindingData.properties.methodBindings[varName]
                    .name!.value
                : "",
          });
        });
      }
      return {
        ...state,
        isDataBindingLoading: false,
        dataBindingResponse: { ...action.dataBindingData },
        currentSamplingInterval: newSamplingInterval,
        originalSamplingInterval: newSamplingInterval,
        currentDisplayName: newDisplayName,
        originalDisplayName: newDisplayName,
        currentObjVariables: [...bindingVariables],
        originalObjVariables: [...bindingVariables],
        currentObjMethods: [...bindingMethods],
        originalObjMethods: [...bindingMethods],
        typeInfoData: {} as browsePageTypes.ITypeInformation,
      };
    case "GET_SELECTED_METHOD_DATA":
      return {
        ...state,
        isSelectedMethodDataLoading: true,
        selectedEnabledMethodDataIds: [],
      };
    case "RECEIVED_SELECTED_METHOD_DATA":
      return {
        ...state,
        formattedInputParameter: [...action.formattedInputParameter],
        formattedOutputParameter: [...action.formattedOututParameter],
        selectedMethodData: { ...action.selectedMethodData },
        isSelectedMethodDataLoading: false,
      };
    case "GET_ENABLED_METHOD_DATA":
      return {
        ...state,
        isEnabledMethodsLoading: true,
      };
    case "RECEIVED_ENABLED_METHOD_DATA":
      return {
        ...state,
        isEnabledMethodsLoading: false,
        rawEnabledMethodData: [...action.rawEnabledMethodData],
        formattedEnabledMethodData: [...action.formattedEnabledMethodData],
        flattenedEnabledMethodData: [...action.flattenedMethodData],
        selectedEnabledMethodDataIds: [],
      };
    case "HANDLE_SELECT_EACH_ENABLED_METHOD_DATA":
      const updatedEnabledMethodData: browsePageTypes.IEachRowObj[] = [];
      [...state.formattedEnabledMethodData].map((item, index) => {
        const modifiedEnabledMethodItem = { ...item };
        if ([...action.selectedRowIds].indexOf(item.id) >= 0) {
          modifiedEnabledMethodItem.isSelected = true;
        } else {
          modifiedEnabledMethodItem.isSelected = false;
        }
        updatedEnabledMethodData.push({ ...modifiedEnabledMethodItem });
      });
      return {
        ...state,
        selectedEnabledMethodDataIds: [...action.selectedRowIds],
        formattedEnabledMethodData: [...updatedEnabledMethodData],
      };
    case "HANDLE_SORT_ENABLED_METHOD_DATA_COLUMN":
      return {
        ...state,
        enabledMethodsortDirection: action.sortDirection,
        enabledMethodsortColumnId: action.sortColumnId,
      };
    case "HANDLE_DISPLAY_NAME_CHANGE":
      return {
        ...state,
        currentDisplayName: action.displayName,
      };
    case "HANDLE_SAMPLING_INTERVAL_CHANGE":
      return {
        ...state,
        currentSamplingInterval: action.samplingInterval,
      };
    case "HANDLE_SELECT_ALL_VARIABLE":
      let list: browsePageTypes.IObjVariables[] = [];
      const variableChildren = [...state.filteredVariableNodes];
      const variableChildrenIds =
        variableChildren.length > 0 ? _.pluck(variableChildren, "nodeId") : [];
      if (!action.isChecked) {
        [...state.currentObjVariables].map((item) => {
          if (variableChildrenIds.indexOf(item.nodeId) < 0) {
            list.push({
              ...item,
            });
          }
        });
      } else {
        list = [...state.currentObjVariables];
        const currentSelectedVariableIds =
          state.currentObjVariables.length > 0
            ? _.pluck([...state.currentObjVariables], "nodeId")
            : [];
        variableChildren.map((child) => {
          if (
            child.nodeClass === "UAVariable" &&
            child.isSupportedDataType &&
            currentSelectedVariableIds.indexOf(child.nodeId) < 0
          ) {
            list.push({
              opcDataType: child.nodeClass,
              nodeId: child.nodeId,
              browseName: child.browseName,
            });
          }
        });
      }
      return {
        ...state,
        currentObjVariables: [...list],
      };
    case "HANDLE_VARIABLE_SELECT":
      const childNodeId = action.variable.nodeId;
      let present = false;
      let valueIndex = -1;
      state.currentObjVariables.forEach((objVariable, index) => {
        if (objVariable.nodeId === childNodeId) {
          present = true;
          valueIndex = index;
        }
      });

      const updatedList = [...state.currentObjVariables];
      if (present) {
        updatedList.splice(valueIndex, 1);
      } else {
        updatedList.push({
          opcDataType: action.variable.nodeClass,
          nodeId: childNodeId,
          browseName: action.variable.browseName,
        });
      }
      return {
        ...state,
        currentObjVariables: [...updatedList],
      };
    case "HANDLE_FORM_CANCEL":
      return {
        ...state,
        currentObjVariables: [...state.originalObjVariables],
        currentObjMethods: [...state.originalObjMethods],
        currentSamplingInterval: state.originalSamplingInterval,
        currentDisplayName: state.originalDisplayName,
        desiredType: "",
        desiredTypeModalMessage: "",
      };
    case "GET_TYPE_INFO":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_TYPE_INFO":
      return {
        ...state,
        isLoading: false,
        typeInfoData: {
          ...action.typeInfo,
        } as browsePageTypes.ITypeInformation,
      };
    case "GET_DESIRED_TYPE":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_DESIRED_TYPE":
      return {
        ...state,
        isLoading: false,
        showDesiredTypeModal: action.showDesiredTypeModal,
        desiredTypeModalMessage: action.desiredTypeMessage,
      };
    case "HANDLE_DESIRED_TYPE_CHANGE":
      return {
        ...state,
        desiredType: action.desiredType,
      };
    case "HIDE_DESIRED_TYPE_MODAL":
      return {
        ...state,
        showDesiredTypeModal: false,
        desiredTypeModalMessage: "",
      };
    case "HANDLE_FORM_COLLECT":
      return {
        ...state,
        isLoading: true,
      };
    case "HANDLE_FORM_MODIFY":
      return {
        ...state,
        isLoading: true,
      };
    case "ON_COLLECT_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "ON_MODIFY_SUCCESS":
      return {
        ...state,
        isLoading: false,
      };
    case "GET_CONFIGURED_DATA":
      return {
        ...state,
        isConfiguredDataLoading: true,
        selectedConfiguredDataIds: [],
      };
    case "RECEIVED_CONFIGURED_DATA":
      return {
        ...state,
        isConfiguredDataLoading: false,
        rawConfiguredData: [...action.rawConfiguredData],
        formattedConfiguredData: [...action.formattedConfiguredData],
      };
    case "HANDLE_SELECT_EACH_CONFIGURED_DATA":
      const updatedConfiguredData: browsePageTypes.IEachRowObj[] = [];
      [...state.formattedConfiguredData].map((item, index) => {
        const modifiedItem = { ...item };
        if ([...action.selectedRowIds].indexOf(item.id) >= 0) {
          modifiedItem.isSelected = true;
        } else {
          modifiedItem.isSelected = false;
        }
        updatedConfiguredData.push({ ...modifiedItem });
      });
      return {
        ...state,
        selectedConfiguredDataIds: [...action.selectedRowIds],
        formattedConfiguredData: [...updatedConfiguredData],
      };
    case "UPDATE_CONFIGURED_DATA_PUBLISHING":
      return {
        ...state,
        isLoading: true,
      };
    case "DELETE_CONFIGURED_DATA_PUBLISHING":
      return {
        ...state,
        isLoading: true,
      };
    case "HANDLE_SORT_CONFIGURED_DATA_COLUMN":
      return {
        ...state,
        sortDirection: action.sortDirection,
        sortColumnId: action.sortColumnId,
      };
    case "HANDLE_SORT_VARIABLE_DATA_COLUMN":
      return {
        ...state,
        variableSortColumnId: action.sortColumnId,
        variableSortDirection: action.sortDirection,
      };
    case "HANDLE_SHOW_CONFIGURED_DATA":
      return {
        ...state,
        showConfiguredDataPage: action.value,
      };
    case "HANDLE_VIEW_NODE_DETAILS":
      return {
        ...state,
        showNodeDetails: action.showNodeDetails,
      };
    case "HANDLE_SHOW_FILTER_VARIABLE_DATA":
      const variableChildrenForEmptyFilter = [
        ...state.selectedNodeChildren,
      ].filter((child) => child.nodeClass === availableTypes.uaVariable);
      return {
        ...state,
        showFilterVariableData: action.showFilter,
        variableFilters: {} as browsePageTypes.IFilterParams,
        filteredVariableNodes: [...variableChildrenForEmptyFilter],
      };
    case "HANDLE_FILTER":
      const filteredVariableNodes: browsePageTypes.InodeObj[] = [];
      const updatedVariableFilters = { ...state.variableFilters };
      updatedVariableFilters[action.id] = action.filterText;
      const filteredVariableChildren = [...state.selectedNodeChildren].filter(
        (child) => child.nodeClass === availableTypes.uaVariable
      );
      [...filteredVariableChildren].map((item) => {
        let filterSuccess = true;
        updatedVariableFilters &&
          Object.keys(updatedVariableFilters).map((filterKey) => {
            if (
              updatedVariableFilters[filterKey].replace(/\s/g, "").length !==
                0 &&
              (item[filterKey] as any)
                .toLowerCase()
                .indexOf(updatedVariableFilters[filterKey].toLowerCase()) < 0
            ) {
              filterSuccess = false;
            }
          });
        if (filterSuccess) {
          filteredVariableNodes.push({ ...item });
        }
      });
      return {
        ...state,
        variableFilters: {
          ...updatedVariableFilters,
        },
        filteredVariableNodes,
      };
    case "HANDLE_SHOW_ENABLED_METHODS":
      return {
        ...state,
        showEnabledMethods: action.value,
      };
    case "HANDLE_SHOW_CONFIGURED_OBJECTS":
      return {
        ...state,
        showConfiguredObjects: action.value,
      };
    case "HANDLE_SELECT_ALL_METHOD":
      let methodList: browsePageTypes.IObjMethods[] = [];
      const methodChildren = [...state.filteredMethodNodes];
      const methodChildrenIds =
        methodChildren.length > 0 ? _.pluck(methodChildren, "nodeId") : [];
      if (!action.isChecked) {
        methodList = [];
        [...state.currentObjMethods].map((item) => {
          if (methodChildrenIds.indexOf(item.nodeId) < 0) {
            methodList.push({
              ...item,
            });
          }
        });
      } else {
        methodList = [...state.currentObjMethods];
        const currentSelectedMethodIds =
          state.currentObjMethods.length > 0
            ? _.pluck([...state.currentObjMethods], "nodeId")
            : [];
        methodChildren.map((child) => {
          if (
            child.nodeClass === "UAMethod" &&
            child.isSupportedDataType &&
            currentSelectedMethodIds.indexOf(child.nodeId) < 0
          ) {
            methodList.push({
              opcDataType: child.nodeClass,
              nodeId: child.nodeId,
              browseName: child.browseName,
            });
          }
        });
      }
      return {
        ...state,
        currentObjMethods: [...methodList],
      };
    case "HANDLE_METHOD_SELECT":
      const methodNodeId = action.method.nodeId;
      let isMethodpresent = false;
      let methodValueIndex = -1;
      state.currentObjMethods.forEach((objMethod, index) => {
        if (objMethod.nodeId === methodNodeId) {
          isMethodpresent = true;
          methodValueIndex = index;
        }
      });

      const updatedMethodList = [...state.currentObjMethods];
      if (isMethodpresent) {
        updatedMethodList.splice(methodValueIndex, 1);
      } else {
        updatedMethodList.push({
          opcDataType: action.method.nodeClass,
          nodeId: methodNodeId,
          browseName: action.method.browseName,
        });
      }
      return {
        ...state,
        currentObjMethods: [...updatedMethodList],
      };
    case "HANDLE_SORT_METHOD_DATA_COLUMN":
      return {
        ...state,
        methodSortColumnId: action.sortColumnId,
        methodSortDirection: action.sortDirection,
      };
    case "HANDLE_SHOW_FILTER_METHOD_DATA":
      const methodChildrenForEmptyFilter = [
        ...state.selectedNodeChildren,
      ].filter((child) => child.nodeClass === availableTypes.uaMethod);
      return {
        ...state,
        showFilterMethodData: action.showFilter,
        methodFilters: {} as browsePageTypes.IFilterParams,
        filteredMethodNodes: [...methodChildrenForEmptyFilter],
      };
    case "HANDLE_FILTER_METHOD":
      const filteredMethodNodes: browsePageTypes.InodeObj[] = [];
      const updatedMethodFilters = { ...state.methodFilters };
      updatedMethodFilters[action.id] = action.filterText;
      const filteredMethodChildren = [...state.selectedNodeChildren].filter(
        (child) => child.nodeClass === availableTypes.uaMethod
      );
      [...filteredMethodChildren].map((item) => {
        let filterSuccess = true;
        updatedMethodFilters &&
          Object.keys(updatedMethodFilters).map((filterKey) => {
            if (
              updatedMethodFilters[filterKey].replace(/\s/g, "").length !== 0 &&
              (item[filterKey] as any)
                .toLowerCase()
                .indexOf(updatedMethodFilters[filterKey].toLowerCase()) < 0
            ) {
              filterSuccess = false;
            }
          });
        if (filterSuccess) {
          filteredMethodNodes.push({ ...item });
        }
      });
      return {
        ...state,
        methodFilters: {
          ...updatedMethodFilters,
        },
        filteredMethodNodes,
      };
    case "SHOW_INPUT_OUTPUT_PARAMETERS":
      return {
        ...state,
        currentlyViewingMethod: { ...action.selectedMethod },
        showInputOutputParametersDialog: true,
      };
    case "HIDE_INPUT_OUTPUT_PARAMETERS":
      return {
        ...state,
        currentlyViewingMethod: {} as browsePageTypes.IEachRowObj,
        showInputOutputParametersDialog: false,
      };
    case "DISABLE_METHODS":
      return {
        ...state,
        isLoading: true,
      };
    case "RESET_BROWSE_STATE":
      return {
        ...state,
        serverId: "",
        uaModuleId: "",
        edgeId: "",
        selectedServer: {} as serverTypes.IRawServersObj,
        treeData: [],
        formattedTreeNodes: [],
        flatenedNodes: {} as browsePageTypes.IFlattenedNodeDataObj,
        selectedNodeId: "",
        selectedNodeDataType: "",
        selectedNode: {} as browsePageTypes.InodeObj,
        selectedNodeChildren: [],
        isLoading: false,
        isDataBindingLoading: false,
        isSelectedMethodDataLoading: false,
        isNodeAutoClose: false,
        originalDisplayName: "",
        currentDisplayName: "",
        originalSamplingInterval: "1000",
        currentSamplingInterval: "1000",
        originalObjVariables: [],
        currentObjVariables: [],
        dataBindingResponse: {} as browsePageTypes.IDataBindingResponse,
        isBrowseDataLoading: false,
        typeInfoData: {} as browsePageTypes.ITypeInformation,
        desiredType: "",
        showConfiguredDataPage: false,
        showDesiredTypeModal: false,
        desiredTypeModalMessage: "",
        isConfiguredDataLoading: false,
        sortDirection: "desc",
        sortColumnId: "name",
        variableSortDirection: "desc",
        variableSortColumnId: "displayName",
        showFilterVariableData: false,
        variableFilters: {} as browsePageTypes.IFilterParams,
        formattedInputParameter: [],
        formattedOutputParameter: [],
        selectedMethodData: {} as browsePageTypes.ISelectedMethodDataResponse,
        isEnabledMethodsLoading: false,
        showConfiguredObjects: false,
        showEnabledMethods: false,
      };

    case "ON_SUCCESS_BROWSE":
      return {
        ...state,
        isLoading: false,
        isDataBindingLoading: false,
        isSelectedMethodDataLoading: false,
        isConfiguredDataLoading: false,
        isEnabledMethodsLoading: false,
        isBrowseDataLoading: false,
        selectedEnabledMethodDataIds:
          action.info === "disablesuccess"
            ? []
            : [...state.selectedEnabledMethodDataIds],
      };
    case "ON_FAIL_BROWSE":
      return {
        ...state,
        isLoading: false,
        isDataBindingLoading: false,
        isSelectedMethodDataLoading: false,
        isBrowseDataLoading: false,
        isConfiguredDataLoading: false,
        isEnabledMethodsLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
