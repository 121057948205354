import serversState, {
  IServersDetailsStateType,
} from "../Views/Servers/Reducer/ReducerServers";
import browseState, {
  IBrowsePageStateType,
} from "../Views/Browse/Reducer/ReducerBrowse";
import { combineReducers } from "redux";
import commonState, { ICommonStateType } from "../Common/Reducer/ReducerCommon";

export interface IState {
  serversState: IServersDetailsStateType;
  browseState: IBrowsePageStateType;
  commonState: ICommonStateType;
}

const allReducers = combineReducers({
  serversState,
  browseState,
  commonState,
});

export type AppState = ReturnType<typeof allReducers>;
export default allReducers;
