import React from "react";
import "./ConnectServersModal.css";
import {
  Dialog,
  Input,
  Dropdown,
  DropdownOption,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Icon,
  RadioGroup,
  RadioOption,
  WithDialog,
  LoadingIndicator,
} from "@abb/abb-common-ux-react";
import FilePickerButton from "../UploadModal/FilePickerButton";
import { useSelector, useDispatch } from "react-redux";
import { IState } from "../../../Reducer";
import * as serverActions from "../Action/ActionServers";
import * as serverTypes from "../Type/TypeServers";
import { isUpdateServerBlocked } from "../../../Utils/HelperFunctions";
import { withRouter, RouteComponentProps } from "react-router-dom";

let timeOutTimer: any;
const ConnectToServerModal = (props: RouteComponentProps) => {
  const serversState = useSelector((state: IState) => state.serversState);
  const [
    enableUpdateServerAfterUploadSucceed,
    setEnableUpdateServerAfterUploadSucceed,
  ] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (serversState.isSessionStatePolling) {
      timeOutTimer = setTimeout(
        () => handleSesionStateTimeOut(),
        2 * 60 * 1000
      );
    }
    return () => {
      clearTimeout(timeOutTimer);
    };
  }, [serversState.isSessionStatePolling]);
  React.useEffect(() => {
    if (
      serversState.selectedRawServer.configureState === 7 &&
      serversState.selectedServer.rawData &&
      serversState.selectedServer.rawData.lastConfiguredTimestamp !==
        serversState.selectedRawServer.lastConfiguredTimestamp
    ) {
      setEnableUpdateServerAfterUploadSucceed(true);
    }
  }, [serversState.selectedRawServer.configureState]);
  React.useEffect(() => {
    return () => {
      setEnableUpdateServerAfterUploadSucceed(false);
    };
  }, []);
  const handleSesionStateTimeOut = () => {
    if (serversState.isSessionStatePolling) {
      dispatch(serverActions.handleSessionStatTimeOut());
    }
  };
  const handleCancel = () => {
    dispatch(
      serverActions.hideConnectToServerModal(
        serversState.shouldRefreshAfterClosingUpdate
      )
    );
  };
  const handleSubmit = () => {
    if (serversState.isEdittingServer) {
      let paramsToBeSubmitted: serverTypes.IUpdateServerParams = {
        serverId: serversState.selectedRawServer.id,
        name: serversState.serverName,
        description: serversState.serverDscription,
        enPointId: serversState.endPoint,
        certificate: serversState.selectedCertificate,
        uaModuleId: serversState.selectedServer.rawData.uaModuleId,
        doesRequireSecureConnection: serversState.requiresSecureConnection,
        status: serversState.selectedRawServer.status,
        nodeSetCachedAt: serversState.selectedRawServer.nodeSetCachedAt,
      };
      dispatch(serverActions.submitUpdateServer(paramsToBeSubmitted));
    } else {
      let selectedHostingEdge = serversState.edgeList.filter(
        (item) => item.id === serversState.selectedEdgeInDropdown[0].value
      );
      let selectedUaModuleId = selectedHostingEdge[0].uaModuleId;
      let selectedEdgeName = selectedHostingEdge[0].name;
      let selectedEdgeId = selectedHostingEdge[0].id;
      let paramsToBeSubmitted: serverTypes.IAddServerParams = {
        name: serversState.serverName,
        description: serversState.serverDscription,
        enPointId: serversState.endPoint,
        certificate: serversState.selectedCertificate,
        uaModuleId: selectedUaModuleId,
        doesRequireSecureConnection: serversState.requiresSecureConnection,
        edgeId: selectedEdgeId,
        edgeName: selectedEdgeName,
      };
      dispatch(serverActions.submitAddServer({ ...paramsToBeSubmitted }));
    }
  };
  const handleFileSelect = (e: any) => {
    e.preventDefault();
    const fr = new FileReader();
    const fileName =
      e.target && e.target.files && e.target.files[0] && e.target.files[0].name
        ? e.target.files[0].name
        : "";
    fr.onload = (fevt: any) => {
      const data = fevt.target.result.replace(/data:.*;base64,/, "");
      dispatch(serverActions.handleSelectCertificate(data, fileName));
    };
    e.target.files.length === 1
      ? fr.readAsDataURL(e.target.files[0])
      : dispatch(serverActions.handleSelectCertificate("", ""));
    if (e.target.files.length === 1) {
      if (
        [
          "application/x-x509-ca-cert",
          "application/x-pkcs7-certificates",
          "application/x-pkcs12",
        ].indexOf(e.target.files[0].type) < 0
      ) {
        dispatch(serverActions.setFileValidity(false));
      } else {
        dispatch(serverActions.setFileValidity(true));
      }
    }
  };

  const handleNodeSetUploadCancel = () => {
    dispatch(serverActions.hideNodeSetUploadModal());
  };
  const handleNodeSetFilePick = (e: any) => {
    e.preventDefault();
    const files: [] = e.target.files;
    const currentFiles: any[] = [...serversState.nodeSetFiles];
    const currentFileNames = [...serversState.fileNames];
    for (let i = 0; files[i]; i++) {
      currentFileNames.push((files[i] as any).name);
      currentFiles.push(files[i]);
    }
    dispatch(
      serverActions.pickNodeSetFile([...currentFileNames], [...currentFiles])
    );
  };
  const getFileNames = () => {
    const fileNamesView = serversState.fileNames.map((item, index) => {
      return (
        <div key={`${item}_${index}`} className="each-name-wrap">
          <label>{item}</label>
          <div
            className="file-close-icon"
            onClick={() => dispatch(serverActions.removeNodeSetFile(index))}
          >
            <Icon name="abb/close" sizeClass="small" />
          </div>
        </div>
      );
    });
    return <div className="file-name-wrap">{fileNamesView}</div>;
  };
  const submitNodeSet = () => {
    if (serversState.selectedUploadOption === "automatic") {
      dispatch(
        serverActions.submitNodeSetAutoConfig(
          serversState.selectedServer.rawData.uaModuleId,
          serversState.selectedServer.rawData.id
        )
      );
    } else {
      dispatch(
        serverActions.submitNodeSetAfterUpload(
          [...serversState.nodeSetFiles],
          serversState.selectedServer.rawData.uaModuleId,
          serversState.selectedServer.rawData.id
        )
      );
    }
  };
  const disableDueToPolling = () => {
    if (
      serversState.isEdittingServer &&
      (serversState.selectedRawServer.configureState === undefined ||
        [0, 7, 8].indexOf(serversState.selectedRawServer.configureState) < 0 ||
        isUpdateServerBlocked(
          serversState.sessionStateData.sessionState,
          serversState.sessionStateData.sessionErrorMessage,
          serversState.isSessionStateTimedOut,
          serversState.isSessionStatePolling
        ))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const disablePageMessage = () => {
    if (
      serversState.isLoadedAfterUpload &&
      [0, 7, 8].indexOf(serversState.selectedRawServer.configureState!) >= 0
    ) {
      return serversState.selectedRawServer.configureState === 7
        ? "Nodeset configuration upload complete. Server initialization succeeded. Click Update server to proceed."
        : serversState.selectedRawServer.configureState === 8
        ? `${serversState.selectedRawServer.configureStatusMessage}`
        : "";
    }
    if (
      serversState.selectedRawServer.configureState === undefined ||
      [0, 7, 8].indexOf(serversState.selectedRawServer.configureState) < 0
    ) {
      return serversState.selectedRawServer.configureStatusMessage
        ? `${serversState.selectedRawServer.configureStatusMessage}`
        : "";
    } else {
      if (serversState.isSessionStateTimedOut) {
        return serversState.sessionStateData.sessionErrorMessage
          ? serversState.sessionStateData.sessionErrorMessage
          : "Could not fetch OPC server connection status. User may proceed with next steps, but its recommended to verify the connection between OPC server and Edge before proceeding further.";
      } else {
        return `Detecting opc ua server configuration...`;
      }
    }
  };
  const getDisablePageLoader = () => {
    let disabledMessage = disablePageMessage();
    let icon = "";
    if (
      serversState.sessionStateData.sessionState === 5 ||
      disabledMessage.indexOf("Detecting opc ua server") >= 0
    ) {
      icon = "loading";
    }
    return icon;
  };
  const isSubmitDisabled = () => {
    const didFailToFetchServer =
      serversState.selectedRawServer &&
      serversState.selectedRawServer.configureStatusMessage &&
      serversState.selectedRawServer.configureStatusMessage.indexOf(
        "Failed to fetch OPC server status"
      ) >= 0;
    const isDisabled =
      serversState.selectedEdgeInDropdown.length === 0 ||
      serversState.serverName.replace(/\s/g, "").length === 0 ||
      serversState.endPoint.replace(/\s/g, "").length === 0 ||
      (serversState.requiresSecureConnection &&
        serversState.selectedCertificate.length === 0) ||
      (disableDueToPolling() && !didFailToFetchServer) ||
      (serversState.selectedCertificate.length !== 0 &&
        !serversState.isValidCertificate);
    let isCertificateUnChanged =
      serversState.selectedRawServer.certificate ===
      serversState.selectedCertificate;
    if (
      serversState.selectedRawServer.certificate !==
        serversState.selectedCertificate &&
      serversState.selectedCertificate.length > 0
    ) {
      if (!serversState.isValidCertificate) {
        isCertificateUnChanged = true;
      }
    }
    const isServerDataUnchanged =
      serversState.selectedRawServer.name === serversState.serverName &&
      serversState.selectedRawServer.serverDescription ===
        serversState.serverDscription &&
      isCertificateUnChanged;
    return (
      (isDisabled || isServerDataUnchanged) &&
      !enableUpdateServerAfterUploadSucceed
    );
  };
  const currentTime = new Date();
  let formattedDate = currentTime.toLocaleString();
  formattedDate = formattedDate.replace(/,/g, "");
  return (
    <Dialog
      showCloseButton={true}
      closeOnEscape={true}
      closeOnLostFocus={false}
      dimBackground={true}
      isOpen={true}
      onClose={() =>
        dispatch(
          serverActions.hideConnectToServerModal(
            serversState.shouldRefreshAfterClosingUpdate
          )
        )
      }
      title={
        serversState.isEdittingServer
          ? "Update OPC UA Server"
          : "Connect to OPC UA Server"
      }
      className="server-modal"
    >
      <div className="server-modal-form">
        {serversState.isEdittingServer ? (
          <React.Fragment>
            {disableDueToPolling() ||
            (serversState.isLoadedAfterUpload &&
              [7, 8].indexOf(serversState.selectedRawServer.configureState!) >=
                0) ||
            serversState.isSessionStateTimedOut ? (
              <div className="disable-message-wrap">
                {getDisablePageLoader() === "loading" ? (
                  <div className="icon-wrap">
                    <LoadingIndicator
                      sizeClass="small"
                      type="radial"
                      color="grey"
                      determinate={true}
                    />
                  </div>
                ) : (
                  <div />
                )}
                {disablePageMessage().indexOf(
                  "Could not fetch OPC server connection status"
                ) >= 0 ? (
                  <div className="could-not-fetch-status-view">
                    <div className="could-not-fetch-status-line-one">
                      <div className="icon">
                        <Icon name="abb/warning-circle-1" sizeClass="small" />
                      </div>
                      <div className="could-not-fetch-status-message">
                        {`Could not fetch OPC server connection status -`}
                        <span className="time-view"> {formattedDate}</span>
                      </div>
                    </div>
                    <div className="could-not-fetch-status-line-two">
                      <div className="could-not-fetch-status-message-two">
                        It is recommended to verify the connection between OPC
                        server and Edge before proceeding further.
                      </div>
                    </div>
                  </div>
                ) : serversState.selectedRawServer.configureStatusMessage.indexOf(
                    "Failed to fetch OPC server status. Please refresh page after sometime"
                  ) >= 0 ? (
                  <div className="could-not-fetch-status-view">
                    <div className="could-not-fetch-status-line-one">
                      <div className="error-icon">
                        <Icon name="abb/error-circle-1" sizeClass="small" />
                      </div>
                      <div className="could-not-fetch-status-message">
                        {`Failed to fetch OPC server status -`}
                        <span className="time-view"> {formattedDate}</span>
                      </div>
                    </div>
                    <div className="could-not-fetch-status-line-two">
                      <div className="could-not-fetch-status-message-two">
                        Please refresh page after sometime, if the problem
                        persists, remove and add server again or contact
                        Administrator.
                      </div>
                    </div>
                  </div>
                ) : disablePageMessage().indexOf(
                    "Nodeset configuration upload complete"
                  ) >= 0 ? (
                  <div className="could-not-fetch-status-view">
                    <div className="could-not-fetch-status-line-one">
                      <div className="success-icon">
                        <Icon
                          name="abb/check-mark-circle-1"
                          sizeClass="small"
                        />
                      </div>
                      <div className="could-not-fetch-status-message">
                        {`Nodeset configuration upload complete. Server initialization succeeded -`}
                        <span className="time-view"> {formattedDate}</span>
                      </div>
                    </div>
                    <div className="could-not-fetch-status-line-two">
                      <div className="could-not-fetch-status-message-two">
                        Click Update server to proceed.
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="disabled-page-message">
                    {disablePageMessage()}
                  </div>
                )}
              </div>
            ) : (
              <div />
            )}
            <div className="row upload-nodeset-row">
              <WithDialog>
                <div className="upload-button-loader-wrap">
                  <LoadingIndicator
                    sizeClass="small"
                    type="radial"
                    color="grey"
                    determinate={true}
                    className={
                      [1, 2, 3, 4, 5, 6].indexOf(
                        serversState.selectedRawServer.configureState!
                      ) >= 0
                        ? "upload-button-loading"
                        : "upload-button-loading hidden-view"
                    }
                  />
                  <Button
                    sizeClass="medium"
                    type="primary-blue"
                    className={
                      [1, 2, 3, 4, 5, 6].indexOf(
                        serversState.selectedRawServer.configureState!
                      ) >= 0
                        ? "btn btn-default uploading-button"
                        : "btn btn-default"
                    }
                    onClick={() =>
                      dispatch(serverActions.showNodeSetUploadModal())
                    }
                    disabled={
                      serversState.isLoading ||
                      serversState.isLoadingAllServers ||
                      disableDueToPolling() ||
                      serversState.isConfigureStatePolling
                    }
                    text={
                      [1, 2, 3, 4, 5, 6].indexOf(
                        serversState.selectedRawServer.configureState!
                      ) >= 0
                        ? "Uploading"
                        : "Upload node set files"
                    }
                  />
                </div>
                <Dialog
                  showCloseButton={true}
                  closeOnEscape={true}
                  closeOnLostFocus={false}
                  dimBackground={
                    serversState.isNodeSetUploadDialogOpen ? true : false
                  }
                  isOpen={serversState.isNodeSetUploadDialogOpen ? true : false}
                  onClose={handleNodeSetUploadCancel}
                  title="Upload node set files"
                  className="upload-dialog"
                >
                  <RadioGroup
                    sizeClass="small"
                    value={serversState.selectedUploadOption}
                    onChange={(value) => {
                      //setUploadOption(value);
                      dispatch(serverActions.setNodeSetUploadOption(value));
                    }}
                  >
                    <RadioOption
                      text="Automatic configuration"
                      value="automatic"
                    />
                    <RadioOption
                      text="Manual configuration - Upload node set file"
                      value="manual"
                    />
                  </RadioGroup>
                  {serversState.selectedUploadOption === "manual" ? (
                    <div className="file-upload-container">
                      {getFileNames()}
                      <div className="file-upload-button-wrap">
                        <FilePickerButton
                          onChange={handleNodeSetFilePick}
                          multiple={true}
                          acceptTypes=".xml"
                        >
                          <Button
                            sizeClass="small"
                            type="normal"
                            text="Browse"
                            disabled={
                              serversState.isLoading ||
                              [0, 7, 8].indexOf(
                                serversState.selectedRawServer.configureState!
                              ) < 0
                            }
                          />
                        </FilePickerButton>
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="submit-cancel-view-wrap">
                    <Button
                      sizeClass="medium"
                      type="normal"
                      className="btn btn-default node-set-cancel"
                      onClick={handleNodeSetUploadCancel}
                      disabled={serversState.isLoading}
                      text="Cancel"
                    />
                    <Button
                      sizeClass="medium"
                      type="primary-blue"
                      onClick={submitNodeSet}
                      disabled={serversState.isLoading}
                      className="node-set-submit"
                      text="Submit"
                    />
                  </div>
                </Dialog>
              </WithDialog>
            </div>
          </React.Fragment>
        ) : (
          <div />
        )}
        <div className="connection-info-label">Connection Information</div>
        <Dropdown
          id="hostingEdge"
          label="Hosting Edge"
          className="edge-selection-dropdown"
          value={
            serversState.selectedEdgeInDropdown
              ? serversState.selectedEdgeInDropdown
              : []
          }
          required={true}
          placeholder="Select Hosting Edge"
          showValidationBarWhenInvalid={false}
          disabled={serversState.isEdittingServer}
          onChange={(val: any) =>
            dispatch(serverActions.handleEdgeDropdown(val))
          }
        >
          {serversState.edgeDeropDownData.map((item) => {
            return (
              <DropdownOption
                key={item.value}
                label={item.label}
                value={item.value}
              />
            );
          })}
        </Dropdown>
        <Input
          id="name"
          label="OPC UA Server Name"
          dataType="text"
          required={true}
          className="connect-server-name connect-server-inputs"
          maxLength={100}
          showValidationBarWhenInvalid={false}
          value={serversState.serverName}
          onValueChange={(value: string) =>
            dispatch(serverActions.handleServerName(value))
          }
        />
        <Input
          id="endpoint"
          label="OPC UA Endpoint"
          dataType="text"
          required={true}
          maxLength={100}
          className="connect-server-endpoint connect-server-inputs"
          showValidationBarWhenInvalid={false}
          value={serversState.endPoint}
          disabled={serversState.isEdittingServer}
          onValueChange={(value: string) =>
            dispatch(serverActions.handleEndPoint(value))
          }
        />
        <Input
          id="serverDescription"
          label="Server Description"
          dataType="text"
          maxLength={200}
          className="connect-server-description connect-server-inputs"
          showValidationBarWhenInvalid={false}
          value={serversState.serverDscription}
          onValueChange={(value: string) =>
            dispatch(serverActions.handleServerDescription(value))
          }
        />
        <hr className="add-server-modal-devider" />
        <React.Fragment>
          <div className="toggleContainer">
            <div className="secure-coonection-toggle-wrap certificate-row">
              <ToggleButtonGroup
                sizeClass="medium"
                selected={
                  serversState.requiresSecureConnection ? [0] : undefined
                }
              >
                <ToggleButton
                  icon="abb/certificate_document2"
                  onClick={() =>
                    dispatch(serverActions.handleSecureConnection())
                  }
                  // disabled={disableDueToPolling()}
                />
              </ToggleButtonGroup>
              <b className="secure-connection-text">
                Configure secure connection
              </b>
            </div>
          </div>
          <div hidden={!serversState.requiresSecureConnection}>
            <div className="certificate-row">
              <div className="security-certificate-label-wrap">
                <div hidden={!!serversState.selectedCertificate}>
                  <FilePickerButton onChange={handleFileSelect}>
                    <Button
                      sizeClass="medium"
                      type="normal"
                      text="Upload File"
                    />
                  </FilePickerButton>
                </div>
                <div hidden={!!!serversState.selectedCertificate}>
                  <Button
                    type="normal"
                    sizeClass="medium"
                    onClick={() => dispatch(serverActions.replaceCertificate())}
                    disabled={disableDueToPolling()}
                    text="Replace Certificate"
                  />
                  <span className="certificate-file-name-view">
                    {serversState.selectedCertificateName}
                  </span>
                </div>
              </div>
            </div>
            <div
              className={
                !!serversState.selectedCertificate
                  ? "certificate-row hidden-row"
                  : "certificate-row"
              }
            >
              <div>
                <p>Upload this server's security certificate.</p>
              </div>
            </div>
            <div
              className={
                !!serversState.selectedCertificate
                  ? "certificate-row"
                  : "certificate-row hidden-row"
              }
            >
              <div>
                <p className="replace-certificate-note-wrap">
                  <div className="note-icon-wrap">
                    {!!serversState.isValidCertificate ? (
                      <Icon name="abb/check_mark_circle2" color="green" />
                    ) : (
                      <Icon name="abb/warning_triangle" color="orange" />
                    )}
                  </div>
                  <span className="certificate-status">
                    {!!serversState.isValidCertificate
                      ? `Security certificate is ready. Click ${
                          serversState.isEdittingServer
                            ? "Update"
                            : "Connect to"
                        } Server to complete this process.`
                      : "Invalid Certificate. Please upload a valid certificate."}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </React.Fragment>

        <div className="modal-submit-cancel">
          <Button
            sizeClass="medium"
            type="primary-blue"
            onClick={handleSubmit}
            text={
              serversState.isEdittingServer
                ? "Update server"
                : "Connect to server"
            }
            className="btn-submit-server"
            disabled={isSubmitDisabled()}
          />
          <Button
            sizeClass="medium"
            type="normal"
            className="btn-cancel-server"
            onClick={handleCancel}
            text="Close"
          />
        </div>
      </div>
    </Dialog>
  );
};

export default withRouter(ConnectToServerModal);
