import * as browsePageTypes from "../Type/TypeBrowse";
export const getBrowseTreeData = (
  uaModuleId: string,
  serverId: string,
  edgeId: string
): browsePageTypes.IGetBrowseTreeData => {
  return {
    type: "GET_BROWSE_TREE_DATA",
    selectedUaModuleId: uaModuleId,
    selectedServerId: serverId,
    edgeId,
  };
};
export const getSelectedServerForBrowse = (
  serverId: string,
  uaModuleId: string,
  edgeId: string
): browsePageTypes.IGetSelectedServerForBrowse => {
  return {
    type: "GET_SELECTED_SERVER_FOR_BROWSE",
    serverId,
    uaModuleId,
    edgeId,
  };
};
export const handleTreeExpandColapse = (
  updatedTreeData: browsePageTypes.IOpcTreeDataObj[]
): browsePageTypes.IHandleTreeExpandColapse => {
  return {
    type: "HANDLE_BROWSE_TREE_EXPAND_COLLAPSE",
    formattedTreeData: [...updatedTreeData],
  };
};
export const onNodeSelect = (
  nodeObj: browsePageTypes.InodeObj
): browsePageTypes.IOnNodeSelected => {
  return {
    type: "ON_NODE_SELECTED_BROWSE_TREE",
    selectedNode: { ...nodeObj },
  };
};
export const getDataBindingResponse = (
  serverId: string,
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IGetDataBindingResponse => {
  return {
    type: "GET_DATA_BINDING_RESPONSE",
    serverId,
    selectedNode,
  };
};
export const handleDisplayNameChange = (
  displayName: string
): browsePageTypes.IHandleDisplayNameChange => {
  return {
    type: "HANDLE_DISPLAY_NAME_CHANGE",
    displayName,
  };
};
export const getSelectedMethodData = (
  serverId: string,
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IGetSelectedMethodData => {
  return {
    type: "GET_SELECTED_METHOD_DATA",
    serverId,
    selectedNode,
  };
};
export const handleDesiredTypeChange = (
  desiredType: string
): browsePageTypes.IHandleDesiredTypeChange => {
  return {
    type: "HANDLE_DESIRED_TYPE_CHANGE",
    desiredType,
  };
};
export const handleSamplingIntervalChange = (
  samplingInterval: string
): browsePageTypes.IHandleSamplingIntervalChange => {
  return {
    type: "HANDLE_SAMPLING_INTERVAL_CHANGE",
    samplingInterval,
  };
};
export const handleSelectAllVariables = (
  isChecked: boolean
): browsePageTypes.IHandleSelectAllVariables => {
  return {
    type: "HANDLE_SELECT_ALL_VARIABLE",
    isChecked,
  };
};
export const handleVriableSelect = (
  isChecked: boolean,
  variable: browsePageTypes.InodeObj
): browsePageTypes.IHnadleVariableSelect => {
  return {
    type: "HANDLE_VARIABLE_SELECT",
    isChecked,
    variable,
  };
};
export const resetBrowseState = (): browsePageTypes.IResetBrowseState => {
  return {
    type: "RESET_BROWSE_STATE",
  };
};
export const cancelForm = (): browsePageTypes.ICancelForm => {
  return {
    type: "HANDLE_FORM_CANCEL",
  };
};
export const collectForm = (
  collectData: browsePageTypes.onCollectRequestParams
): browsePageTypes.ICollectForm => {
  return {
    type: "HANDLE_FORM_COLLECT",
    requestData: { ...collectData },
  };
};
export const modifyForm = (
  modifyData: browsePageTypes.onModifyRequestParams
): browsePageTypes.IModifyForm => {
  return {
    type: "HANDLE_FORM_MODIFY",
    requestData: { ...modifyData },
  };
};
export const getTypeInfo = (
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IGetTypeInfo => {
  return {
    type: "GET_TYPE_INFO",
    selectedNode,
  };
};
export const getDesiredType = (
  selectedVariables: browsePageTypes.IObjVariableForCollect[],
  desiredType: string,
  collectData: browsePageTypes.onCollectRequestParams
): browsePageTypes.IGetDesiredType => {
  return {
    type: "GET_DESIRED_TYPE",
    selectedVariables,
    desiredType,
    collectData,
  };
};
export const hideDesiredTypeModal =
  (): browsePageTypes.IHideDesiredTypeModal => {
    return {
      type: "HIDE_DESIRED_TYPE_MODAL",
    };
  };
export const handleShowConfiguredData = (
  value: boolean
): browsePageTypes.IHandleShowConfiguredData => {
  return {
    type: "HANDLE_SHOW_CONFIGURED_DATA",
    value,
  };
};
export const handleShowConfiguredObjectsData = (
  value: boolean
): browsePageTypes.IHandleShowConfiguredObjectsData => {
  return {
    type: "HANDLE_SHOW_CONFIGURED_OBJECTS",
    value,
  };
};
export const handleShowEnabledMethodsData = (
  value: boolean
): browsePageTypes.IHandleShowEnabledMethodsData => {
  return {
    type: "HANDLE_SHOW_ENABLED_METHODS",
    value,
  };
};
export const getConfiguredData = (
  uaModuleId: string,
  serverId: string,
  edgeId: string
): browsePageTypes.IGetConfiguredData => {
  return {
    type: "GET_CONFIGURED_DATA",
    selectedUaModuleId: uaModuleId,
    selectedServerId: serverId,
    edgeId,
  };
};
export const getEnabledMethodData = (
  uaModuleId: string,
  serverId: string,
  edgeId: string
): browsePageTypes.IGetEnabledMethodData => {
  return {
    type: "GET_ENABLED_METHOD_DATA",
    selectedUaModuleId: uaModuleId,
    selectedServerId: serverId,
    edgeId,
  };
};
export const updateConfiguredData = (
  selectedConfiguredData: browsePageTypes.IConfiguredDataBinding[],
  serverId: string,
  uaModuleId: string,
  edgeId: string,
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IUpdateConfiguredData => {
  return {
    type: "UPDATE_CONFIGURED_DATA_PUBLISHING",
    selectedConfiguredData,
    serverId,
    uaModuleId,
    edgeId,
    selectedNode,
  };
};
export const deleteConfiguredData = (
  selectedConfiguredData: browsePageTypes.IConfiguredDataBinding[],
  serverId: string,
  uaModuleId: string,
  edgeId: string,
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IDeleteConfiguredData => {
  return {
    type: "DELETE_CONFIGURED_DATA_PUBLISHING",
    selectedConfiguredData,
    serverId,
    uaModuleId,
    edgeId,
    selectedNode,
  };
};
export const handleViewNodeDetails = (
  showNodeDetails: boolean
): browsePageTypes.IHandleViewNodeDetails => {
  return {
    type: "HANDLE_VIEW_NODE_DETAILS",
    showNodeDetails,
  };
};
export const selectEachConfiguredData = (
  selectedRowIds: string[]
): browsePageTypes.ISelectEachConfiguredDataRow => {
  return {
    type: "HANDLE_SELECT_EACH_CONFIGURED_DATA",
    selectedRowIds,
  };
};
export const sortConfiguredData = (
  sortDirection: "asc" | "desc",
  sortColumnId: string
): browsePageTypes.IHandleSort => {
  return {
    type: "HANDLE_SORT_CONFIGURED_DATA_COLUMN",
    sortDirection,
    sortColumnId,
  };
};

export const selectEachEnabledMethodData = (
  selectedRowIds: string[]
): browsePageTypes.ISelectEachEnabledMethodDataRow => {
  return {
    type: "HANDLE_SELECT_EACH_ENABLED_METHOD_DATA",
    selectedRowIds,
  };
};
export const sortEnabledMethodata = (
  sortDirection: "asc" | "desc",
  sortColumnId: string
): browsePageTypes.IHandleSortEnabledMethod => {
  return {
    type: "HANDLE_SORT_ENABLED_METHOD_DATA_COLUMN",
    sortDirection,
    sortColumnId,
  };
};
export const sortVariabledData = (
  sortDirection: "asc" | "desc",
  sortColumnId: string
): browsePageTypes.IHandleSortVariable => {
  return {
    type: "HANDLE_SORT_VARIABLE_DATA_COLUMN",
    sortDirection,
    sortColumnId,
  };
};
export const showFilterVariableData = (
  showFilter: boolean
): browsePageTypes.IHandleShowFilterVariableData => {
  return {
    type: "HANDLE_SHOW_FILTER_VARIABLE_DATA",
    showFilter,
  };
};
export const handleFilter = (
  id: string,
  filterText: string
): browsePageTypes.IHandleFilter => {
  return {
    type: "HANDLE_FILTER",
    id,
    filterText,
  };
};
export const handleSelectAllMethods = (
  isChecked: boolean
): browsePageTypes.IHandleSelectAllMethods => {
  return {
    type: "HANDLE_SELECT_ALL_METHOD",
    isChecked,
  };
};
export const handleMethodsSelect = (
  isChecked: boolean,
  method: browsePageTypes.InodeObj
): browsePageTypes.IHnadleMethodSelect => {
  return {
    type: "HANDLE_METHOD_SELECT",
    isChecked,
    method,
  };
};
export const sortMethoddData = (
  sortDirection: "asc" | "desc",
  sortColumnId: string
): browsePageTypes.IHandleSortMethod => {
  return {
    type: "HANDLE_SORT_METHOD_DATA_COLUMN",
    sortDirection,
    sortColumnId,
  };
};
export const showFilterMethodData = (
  showFilter: boolean
): browsePageTypes.IHandleShowFilterMethodData => {
  return {
    type: "HANDLE_SHOW_FILTER_METHOD_DATA",
    showFilter,
  };
};
export const handleFilterMethod = (
  id: string,
  filterText: string
): browsePageTypes.IHandleFilterMethod => {
  return {
    type: "HANDLE_FILTER_METHOD",
    id,
    filterText,
  };
};

export const showInputOutputParameters = (
  selectedMethod: browsePageTypes.IEachRowObj
): browsePageTypes.IShowInputOutputPrameters => {
  return {
    type: "SHOW_INPUT_OUTPUT_PARAMETERS",
    selectedMethod,
  };
};
export const hideInputOutputParameters =
  (): browsePageTypes.IHideInputOutputPrameters => {
    return {
      type: "HIDE_INPUT_OUTPUT_PARAMETERS",
    };
  };

export const disableMethods = (
  disableMethodsPayload: browsePageTypes.IDisableMethodsPayload[],
  serverId: string,
  uaModuleId: string,
  edgeId: string,
  selectedNode: browsePageTypes.InodeObj
): browsePageTypes.IDisableMethods => {
  return {
    type: "DISABLE_METHODS",
    payload: disableMethodsPayload,
    serverId,
    uaModuleId,
    edgeId,
    selectedNode,
  };
};
