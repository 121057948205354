import React, { useEffect } from "react";
import "./Documentation.css";
import Tree, { ITreeDataObj } from "../Tree/Tree";
import { Icon, AppMainContent } from "@abb/abb-common-ux-react";
import { helpPageTreeData } from "../../../Utils/Constants";
import { RouteComponentProps, withRouter } from "react-router";
import * as commonActions from "../../Action/ActionCommon";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import CommonLoader from "../CommonLoader/CommonLoader";

const refs: any = {};
helpPageTreeData.map((item: any) => {
  refs[item.id] = React.createRef();
});

const Documentation = (props: RouteComponentProps) => {
  const [tocData, handleExpandCollapse] = React.useState([...helpPageTreeData]);
  const [selectedNodeId, setSelectedNodeId] = React.useState(
    helpPageTreeData[0].id
  );
  const [nodeClickCount, setNodeClickCount] = React.useState(0);
  const [showLeftPane, toggleLeftPane] = React.useState(true);
  React.useEffect(() => {
    const elmnt = document.getElementById(selectedNodeId);
    if (elmnt && elmnt !== null) {
      elmnt?.scrollIntoView();
    }
  }, [nodeClickCount]);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getPreference());
  }, []);
  const clickedNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    setSelectedNodeId(clickedObj.id);
    setNodeClickCount(nodeClickCount + 1);
  };
  const handleExpandCollapseNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    if (clickedObj.isExpandable) {
      const updatedData = [...tocData];
      updatedData.map((item) => {
        if (item.id === clickedObj.id) {
          item.isExpanded = !item.isExpanded;
        }
      });
      if (clickedObj.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(clickedObj.id) >= 0 &&
            item.level > clickedObj.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(clickedObj.id) >= 0 &&
            item.level === clickedObj.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      handleExpandCollapse([...updatedData]);
    }
  };

  const handleDocClick = (clickedId: string) => {
    let modifiedId = clickedId;
    const updatedData = [...tocData];
    updatedData.reverse();
    updatedData.some((item) => {
      if (clickedId.indexOf(item.id) >= 0 && item.isVisible) {
        modifiedId = item.id;
        return true;
      }
      return false;
    });
    setSelectedNodeId(modifiedId);
  };

  const tooglePane = () => {
    toggleLeftPane(!showLeftPane);
  };
  return (
    <React.Fragment>
      {commonState.isLoading ? <CommonLoader /> : <div />}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent>
        <div className="docs">
          <div className="docs-view">
            <div className="menu" onClick={tooglePane}>
              <Icon name="abb/menu" sizeClass="large" />
            </div>
            {showLeftPane ? (
              <Tree
                data={tocData}
                selectedNodeId={selectedNodeId}
                handleExpandCollapse={handleExpandCollapseNode}
                handleNodeSelection={clickedNode}
              />
            ) : (
              <React.Fragment />
            )}
          </div>
          <DisplayNewDoc onDocClick={handleDocClick} />
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};

const DisplayNewDoc = (props: any) => {
  return (
    <div className="doc-view-container" id="scrolling-div">
      <div>
        <div
          id="%1.introduction%"
          className="level-1-padding"
          ref={refs["%1.introduction%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%")}
        >
          <p className="headers-document first-header">1. Introduction</p>
          <p>
            The OPC UA Configuration Utility provides the capability to connect
            to an OPC UA Server via the Edgenius Edge node, browse the OPC UA
            Server and add variables to the edge data stream which can then be
            subscribed by other applications like ABB AbilityTM Edgenius
            Dashboard.
          </p>
          <p>
            The OPC UA Configuration Utility consists of two modules – an OPC UA
            Configuration tool hosted in Ability cloud instance and an OPC UA
            Connect module which is deployed in the Edgenius Edge device.
          </p>
        </div>

        <div
          id="%1.introduction%%1.1rbac%"
          className="level-2-padding"
          ref={refs["%1.introduction%%1.1rbac%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%%1.1rbac%")}
        >
          <p className="headers-document first-header">1.1 RBAC</p>
          <p>
            Role based access control is used to authorize functionality. For
            the Edge Management Portal there are two levels, User and Software
            Developer. For access to OPC UA Configuration Utility,
            “ApplicationEngineer” grant is required.
          </p>
        </div>
        <div
          id="%2.opcuaconnect%"
          className="level-1-padding"
          ref={refs["%2.opcuaconnect%"]}
          onMouseOver={() => props.onDocClick("%2.opcuaconnect%")}
        >
          <p className="headers-document">2. OPC UA Connect</p>
          <p>
            The OPC UA Connect application is an OPC UA client running on
            Edgenius. It provides southbound connectivity to an OPC UA
            compatible device or system.
          </p>
          <p>
            <img
              src="images/UserDocsImages/opcuaconect1.png"
              className="common-image-class"
            />
          </p>
        </div>
        <div
          id="%2.opcuaconnect%%2.1.installation%"
          className="level-2-padding"
          ref={refs["%2.opcuaconnect%%2.1.installation%"]}
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.1.installation%")
          }
        >
          <p className="headers-document">2.1. Installation</p>
          <p>
            Installation is made using the Edgenius Management Portal (EMP)
            website. This chapter describes how to install and setup the
            application.
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.1.installation%%2.1.1.application%"
          className="level-3-padding"
          ref={refs["%2.opcuaconnect%%2.1.installation%%2.1.1.application%"]}
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.1.installation%%2.1.1.application%"
            )
          }
        >
          <p className="headers-document">2.1.1. Application</p>
          <p>To install the OPC UA Connect application:</p>
          <p>Navigate to the main page of the edge in EMP.</p>
          <p>
            Locate the OPC UA Connect application in the All tab of the
            Applications list:
          </p>
          <p>
            <img
              src="images/UserDocsImages/application.png"
              className="common-image-class"
            />
          </p>
          <p>
            Click on <img src="images/UserDocsImages/downloadicon.png" />
            to install the application
          </p>
          <p>
            After installation the application can be found in the ‘On this
            edge’ tab:
          </p>
          <p>
            <img
              src="images/UserDocsImages/installationonedgenew.png"
              className="common-image-class"
            />
          </p>
          <p>Click on the application to navigate to the application page.</p>
          <p>
            Installation is ready. Follow the steps in 2.1.2 to handle
            application certificates.
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.1.installation%%2.1.2.certificates%"
          className="level-3-padding"
          ref={refs["%2.opcuaconnect%%2.1.installation%%2.1.2.certificates%"]}
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.1.installation%%2.1.2.certificates%"
            )
          }
        >
          <p className="headers-document">2.1.2. Certificates</p>
          <p>
            The edge OPC UA Connect needs certificates to be functional. A
            self-signed certificate is generated at start-up.
          </p>
          <p>
            Certificates for the client and the servers, are placed under
            /var/ability/modules/uaconnect/files/PKI/.
          </p>
          <p>It is possible to manually create certificates:</p>
          <p>
            Navigate to the Modules page (Software developer role required, see
            chapter 1.1):
          </p>
          <p>
            <img
              src="images/UserDocsImages/certificates.png"
              className="common-image-class"
            />
          </p>
          <p>
            Click the createApplicationCertificate button to create a CA
            certificate, a client certificate, and a certificate revocation list
            file:
          </p>
          <p>
            <img
              src="images/UserDocsImages/certificate2.png"
              className="image-margin"
            />
          </p>
          <p>When done Status 200 is displayed under the button:</p>
          <p>
            <img
              src="images/UserDocsImages/certificate3.png"
              className="image-margin"
            />
          </p>
          <p>
            Click the View Result link and verify that the certificates where
            successfully created:
          </p>
          <p>
            <img
              src="images/UserDocsImages/certificate4.png"
              className="image-margin"
            />
          </p>
          <p>
            Click the createSelfSignedCertificate to create a self-signed
            certificate:
          </p>
          <p>
            <img
              src="images/UserDocsImages/selfsigned1.png"
              className="image-margin"
            />
          </p>
          <p>When done Status 200 is displayed under the button:</p>
          <p>
            <img
              src="images/UserDocsImages/selfsigned2.png"
              className="image-margin"
            />
          </p>
          <p>
            Click the View Result link and verify that the certificates where
            successfully created:
          </p>
          <p>
            <img
              src="images/UserDocsImages/selfsigned3.png"
              className="image-margin"
            />
          </p>
          <p>
            Validating that the certificates have been created or delete
            certificates can be done by clicking either of the
            validateApplicationCertificate or dumpApplicationCertificate
            buttons:
          </p>
          <p>
            <img
              src="images/UserDocsImages/certificateupdatednew1.png"
              className="image-margin"
            />
          </p>
          <p>
            validateApplicationCertificate will automatically validate the
            created certificates.
          </p>
          <p>
            dumpApplicationCertificate will get the application certificate.
          </p>
          <p>
            Before adding an OPC server connection, to have the client
            certificate trusted by the server, you need to manually copy the CA
            certificate from the edge
            /var/ability/modules/uaconnect/files/PKI/trusted/certs folder to the
            OPC server's trusted folder.
          </p>
          <p>
            Some servers also require that the server revocation list file be
            available. Copy the generated crl from
            /var/ability/modules/uaconnect/files/PKI/trusted/crl/ folder to the
            OPC server’s trusted folder.
          </p>
        </div>
        <div
          id="%2.opcuaconnect%%2.2.routingconfiguration%"
          ref={refs["%2.opcuaconnect%%2.2.routingconfiguration%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.2.routingconfiguration%")
          }
        >
          <p className="headers-document">2.2. Routing Configuration</p>
          <p>
            Once OPC UA Connect module has been installed in the Edge it will be
            possible to configure which data should stay on the edge and which
            data should be available outside in the Ability cloud instance.
          </p>
          <p>
            In order to configure the routes of your edge, open the settings
            page for the edge and select Routing. For sending OPC UA data to the
            Ability cloud instance select ‘Cloud’ route and Add a route with
            ‘Variable Data’ as type (only relevant type for OPC UA), ‘Cloud’ as
            route and ‘OPC UA’ as the model.{" "}
          </p>
          <p>
            <img
              src="images/UserDocsImages/rutingconfig.png"
              className="image-margin"
            />
          </p>
          <p>
            Once the route is added click Save to Save the route configuration.
          </p>
        </div>
        <div
          id="%2.opcuaconnect%%2.3.runtime%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%"]}
          className="level-2-padding"
          onMouseOver={() => props.onDocClick("%2.opcuaconnect%%2.3.runtime%")}
        >
          <p className="headers-document">2.3. Runtime</p>
          <p>
            This section describes the application pages of OPC UA Connect in
            Edge Management Portal:
          </p>
          <p>
            <img
              src="images/UserDocsImages/runtime.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%overview%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%overview%"]}
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.3.runtime%%overview%")
          }
        >
          <p className="headers-document">Overview</p>
          <p>Contains general information about the application.</p>
          <p>
            <img
              src="images/UserDocsImages/overview.png"
              className="image-margin"
            />
          </p>
          <p>
            Use the ‘View Json’ button to display the application’s info model.
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%settings%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%settings%"]}
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.3.runtime%%settings%")
          }
        >
          <p className="headers-document">Settings</p>
          <p>
            After changing a setting, press the Save button for changes to take
            effect.
          </p>
          <p>
            <img
              src="images/UserDocsImages/settings.png"
              className="image-margin"
            />
          </p>
        </div>
        <div
          id="%2.opcuaconnect%%2.3.runtime%%settings%%loglevel%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%settings%%loglevel%"]}
          className="level-4-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.3.runtime%%settings%%loglevel%"
            )
          }
        >
          <p className="headers-document">Loglevel</p>
          <p>
            Sets the minimum level the module will log, meaning that events for
            that level or higher will be logged. (Software developer role
            required, see chapter 1.1)
          </p>
          <p>
            <img
              src="images/UserDocsImages/settings2.png"
              className="image-margin"
            />
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%modules%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%modules%"]}
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.3.runtime%%modules%")
          }
        >
          <p className="headers-document">Modules</p>
          <p>
            The OPC UA Connect application consists of one module, the uaconnect
            module. The content of the uaconnect module tab is described in this
            section.
          </p>
          <p>(Software developer role required, see chapter 1.1)</p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%modules%%information%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%modules%%information%"]}
          className="level-4-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.3.runtime%%modules%%information%"
            )
          }
        >
          <p className="headers-document">Information</p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Id</td>
                <td>The Ability object id.</td>
              </tr>
              <tr>
                <td>State</td>
                <td>The current state of the module: running or stopped.</td>
              </tr>
              <tr>
                <td>Installation State</td>
                <td>
                  The current state of the installation process: Unknown, New,
                  Removed, NeedsUpdate, Updating or Installed.
                </td>
              </tr>
              <tr>
                <td>Type</td>
                <td>The module’s Ability type.</td>
              </tr>
              <tr>
                <td>Image</td>
                <td>The Docker image the module is running.</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>
                  The version of the Docker image the module is running. The
                  version can be change by selecting another version in the
                  Version dropbox and then press the Update button.
                </td>
              </tr>
            </table>
          </p>
        </div>

        {/* <div
          id="%2.opcuaconnect%%2.3.runtime%%modules%%operation%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%modules%%operation%"]}
          className="level-4-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.3.runtime%%modules%%operation%"
            )
          }
        >
          <p className="headers-document">Operation</p>
          <p>
            Note that the operations are primarily for development and
            troubleshooting. Certificate operations are described in
            Certificates.
          </p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>loadNodeSetFile</td>
                <td>Deprecated </td>
              </tr>
              <tr>
                <td>exportNodeIds</td>
                <td>Deprecated</td>
              </tr>
              <tr>
                <td>browseNodeId</td>
                <td>
                  <p>
                    Browses the node tree from the specified nodeId from the
                    specified endpoint.
                  </p>
                  <p>The result contains nodeset in xml format.</p>
                </td>
              </tr>
              <tr>
                <td>discoverEndpoints</td>
                <td>Takes an opc ua server url and discovers its endpoints.</td>
              </tr>
              <tr>
                <td>refreshNodeSets</td>
                <td>
                  Browses the specified endpoint and creates nodeset files. One
                  file for each namespace.
                </td>
              </tr>
              <tr>
                <td>abortRefreshNodeSets</td>
                <td>Aborts refreshNodeSets operation.</td>
              </tr>
              <tr>
                <td>uploadNodeSets</td>
                <td>
                  Uploads created nodeset files of specified endpoint. Note,
                  refreshNodeSets must have been executed.
                </td>
              </tr>
              <tr>
                <td>dumpState</td>
                <td>Dumps the state of the module.</td>
              </tr>
              <tr>
                <td>getEdgeBrokerStatus</td>
                <td>Get status statistics for the edge broker.</td>
              </tr>
              <tr>
                <td>setLogLevel</td>
                <td>Change the log level in the running container.</td>
              </tr>
            </table>
          </p>
        </div> */}
        <div
          id="%2.opcuaconnect%%2.3.runtime%%objects%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%objects%"]}
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.3.runtime%%objects%")
          }
        >
          <p className="headers-document">Objects</p>
          <p>
            The configured endpoints and databindings can be monitored from this
            page. Select OPC UA Structure in the Structure dropdown to view the
            configuration:
          </p>
          <p>
            <img
              src="images/UserDocsImages/objects.png"
              className="image-margin"
            />
          </p>
          <p>
            Click on the desired endpoint or databinding to show detailed
            information:
          </p>
          <p>
            <img
              src="images/UserDocsImages/objects2.png"
              className="image-margin"
            />
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%"
          ref={refs["%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%"]}
          className="level-4-padding"
          onMouseOver={() =>
            props.onDocClick("%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%")
          }
        >
          <p className="headers-document">Endpoint</p>
          <p>This page contains one tab for each model of the endpoint.</p>
          <p>
            <img
              src="images/UserDocsImages/endPoint.png"
              className="image-margin"
            />
          </p>
          <p>
            Use the View Json button to display json for the models. The
            following table describes the information in the fields:
          </p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Name</td>
                <td>The name of the model.</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>The type for the model that is being used.</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>The version of the type.</td>
              </tr>
            </table>
          </p>
          <p>
            The tables below describe the most important variables for
            monitoring the condition and state of the endpoint.
          </p>
          <p>
            <table>
              <tr>
                <th colSpan={2}>abb.ability.device</th>
              </tr>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th>Variable</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>startTime</td>
                <td>
                  The server’s startup time. Only valid if there is a connection
                  to the server, i.e. sessionState is Connected.
                </td>
              </tr>
              <tr>
                <td>sessionState</td>
                <td>
                  The state of the connection between the module and the server.
                </td>
              </tr>
              <tr>
                <td>sessionErrorMessage</td>
                <td>
                  Contains an error message if there is a problem with the
                  connection between the module and the server.{" "}
                </td>
              </tr>
              <tr>
                <td>serverState</td>
                <td>
                  The server’s state. Only valid if there is a connection to the
                  server, i.e. sessionState is Connected.
                </td>
              </tr>
              <tr>
                <td>refreshNodeSetsState</td>
                <td>
                  The current state of the operations refreshNodeSets and
                  uploadNodeSets, see 2.1.3.2
                </td>
              </tr>
              <tr>
                <td>refreshNodeSetsLastInvokeTime</td>
                <td>
                  The last time one of the operations refreshNodeSets or
                  uploadNodeSets was invoked, see 2.1.3.2.
                </td>
              </tr>
              <tr>
                <td>refreshNodeSetsErrorMessage</td>
                <td>
                  Contains an error message if there was an error while invoking
                  one of the operations refreshNodeSets or uploadNodeSets, see
                  2.1.3.2.
                </td>
              </tr>
              <tr>
                <td>currentTime</td>
                <td>
                  The server’s current time. Only valid if there is a connection
                  to the server, i.e. sessionState is Connected.
                </td>
              </tr>
            </table>
          </p>
        </div>

        <div
          id="%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%%databinding%"
          ref={
            refs[
              "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%%databinding%"
            ]
          }
          className="level-5-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%%databinding%"
            )
          }
        >
          <p className="headers-document">Databinding</p>
          <p>This page contains one tab for each model of the databinding.</p>
          <p>
            <img
              src="images/UserDocsImages/dataBinding.png"
              className="image-margin"
            />
          </p>
          <p>
            Use the View Json button to display json for the models. The
            following table describes the information in the fields:
          </p>
          <p>
            <table>
              <colgroup>
                <col span={1} style={{ width: "20%" }} />
                <col span={1} style={{ width: "80%" }} />
              </colgroup>
              <tr>
                <th>Field</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>Name</td>
                <td>The name of the model.</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>The type for the model that is being used.</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>The version of the type.</td>
              </tr>
            </table>
          </p>
          <p>
            Databindings are publishing time series on the model abb.ua.device.
            Open the tab for that model to see live variable data:
          </p>
          <p>
            <img
              src="images/UserDocsImages/databinding2.png"
              className="image-margin"
            />
          </p>
          <p>
            Click <img src="images/UserDocsImages/databindingicon.png" />
            to display the trend of a variable:
          </p>
          <p>
            <img
              src="images/UserDocsImages/databindingcontrolout.png"
              className="image-margin"
            />
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%"
          ref={refs["%3.opcuaconfigurationtool%"]}
          className="level-1-padding"
          onMouseOver={() => props.onDocClick("%3.opcuaconfigurationtool%")}
        >
          <p className="headers-document">3. OPC UA Configuration Utility</p>
          <p>
            OPC UA Configuration Utility is a web application hosted in Ability
            Cloud.
          </p>
          <p>The utility allows users to:</p>
          <ul>
            <li>
              Establish connection and browse data entities from OPC UA Servers
              that are connected to ABB Ability Edgenius devices.
            </li>
            <li>
              Select or unselect the required data entities to be activated and
              published to Ability Cloud for subscription.
            </li>
          </ul>
          <p>
            <img
              src="images/UserDocsImages/opcuaconfigtoolupdated.png"
              className="image-margin"
              height="65%"
              width="65%"
            />
          </p>
          <p>
            Users should have "ApplicationEngineer" grant for accessing OPC UA
            Configuration Tool. Please contact Administrator for getting access
            grants.
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.1.configuration%"
          ref={refs["%3.opcuaconfigurationtool%%3.1.configuration%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%3.opcuaconfigurationtool%%3.1.configuration%")
          }
        >
          <p className="headers-document">3.1. Configuration</p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.1.configuration%3.1.1.connectopcuaserver"
          ref={
            refs[
              "%3.opcuaconfigurationtool%%3.1.configuration%3.1.1.connectopcuaserver"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.1.configuration%3.1.1.connectopcuaserver"
            )
          }
        >
          <p className="headers-document">3.1.1. Connect OPC UA Server</p>
          <p>
            Execute the following steps to establish the connection to a UA
            Server.
          </p>
          <p>
            Login to OPC UA Configuration Utility. The OPC Servers page lists
            all the configured OPC UA servers, connected to the Edge devices for
            the logged in tenant. The User will also have an option to view the
            configured OPC UA servers grouped by their host edge devices.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connectopcuaupdated1.png" />
            <div>Figure 1: Configured OPC UA Servers</div>
          </p>
          <p>
            The Servers will be listed with their corresponding hosting edge,
            status, and server description.
          </p>
          <p>
            Click on{" "}
            <img src="images/UserDocsImages/connectopcuaupadted2.png" /> to view
            the servers grouped by edge.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connectopcuaupdated3.png" />
            <div>Figure 2: Configured OPC UA Servers grouped by edges</div>
          </p>
          <p>
            Click on <img src="images/UserDocsImages/rightarrowedge.png" /> icon
            to expand the edge and view the OPC servers connected to it.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connectopcuaupdated4.png" />
            <div>
              Figure 3: Configured OPC UA Servers grouped by edges expanded view
            </div>
          </p>
          <p>
            To add a new connection to an OPC UA Server, click on the{" "}
            <img src="images/UserDocsImages/connectuaicon1.png" />
            button in ‘OPC Servers’ page.
          </p>
          <p>
            In the ‘Connect to OPC UA Server’ popup the ‘Hosting Edge’ drop down
            lists the Edge devices that has OPC UA Connect module installed for
            the logged in tenant. From the ‘Hosting Edge’ drop down, select the
            Edge from which a connection to a southbound OPC UA Server needs to
            be established. In the ‘OPC UA Server Name’ field enter a name to
            uniquely identify the configured OPC UA Server in OPC UA
            Configuration Utility. Enter the OPC UA Server Endpoint URL in the
            ‘OPC UA Endpoint’ field. Additional information can be added in the
            ‘Server Description’ field.
          </p>
          <p>
            If the OPC UA Server requires secure connection using certificates,
            Click the ‘Configure secure connection’ button{" "}
            <img src="images/UserDocsImages/connecttoopcuaupdated5.png" /> to
            upload the corresponding OPC UA Server certificate.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connecttoopcuaupdated6.png" />
            <div>Figure 4: Connecting to a new OPC UA Server</div>
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connecttoopcuaupdated7.png" />
            <div>Figure 5: Hosting Edges in Add Servers popup</div>
          </p>
          <p>
            <ul>
              <li>
                Hosting Edge- Edge from which connection to a southbound OPC UA
                Server will be established.
              </li>
              <li>
                OPC UA Server Name- Name provided by the user to identify OPC UA
                Server.
              </li>
              <li>OPC UA End Point– URL of the OPC UA Server.</li>
              <li>
                Server Description– Sever description provided by the user.
              </li>
              <li>
                Configure secure connection – Enables ‘Upload File’ button for
                uploading OPC UA Server certificate.
              </li>
            </ul>
          </p>
          <p>
            Once the details are provided click on the ‘Connect to Server’
            button to connect to the OPC UA Server. Once the connection is
            successful, the ‘Upload node set file’ button will be enabled.
          </p>
          <p>
            Click on “Server Settings” from the pop up to go to “Update OPC
            servers” popup. Once the connection is successful, the ‘Upload node
            set file’ button will be enabled.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connecttoopcuaupdated9.png" />
            <div>Figure 6: Upload Nodeset files</div>
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.1.configuration%3.1.2.configureopcuaserver"
          ref={
            refs[
              "%3.opcuaconfigurationtool%%3.1.configuration%3.1.2.configureopcuaserver"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.1.configuration%3.1.2.configureopcuaserver"
            )
          }
        >
          <p className="headers-document">3.1.2. Configure OPC UA Server</p>
          <p>
            To browse and work with the OPC UA Server, the OPC UA Configuration
            utility must understand the information model of the connected OPC
            UA Server.
          </p>
          <p>
            This information model schema is exposed as a node-set configuration
            schema xml which needs to be uploaded first.{" "}
          </p>
          <p>
            <img
              src="images/UserDocsImages/configureopcua1.png"
              className="image-margin"
            />
          </p>
          <p>
            On clicking the ‘Upload node set files’ button the user is given two
            options for uploading the Nodeset configuration from the
            corresponding OPC UA Server. ‘Automatic configuration’ option is
            selected by default.
          </p>
          <p className="image-margin">
            <img
              src="images/UserDocsImages/configureopcua2.png"
              className="image-margin"
            />
            <div>Figure 7: Upload Nodeset dialog</div>
          </p>
          <ul>
            <li>
              <div>Automatic Configuration:</div>
              <div>
                In automatic configuration mode, the Nodeset configuration of
                the OPC UA Server is extracted by the UA Connect module in the
                Edge device and the corresponding types will be created in the
                OPC UA Config Utility.
              </div>
            </li>
            <li>
              <div>Manual Configuration – Upload node set file:</div>
              <div>
                In manual configuration mode, instead of UA Connect module
                uploading the Nodeset configuration, the user needs to manually
                upload the Nodeset file directly from the OPC UA Config Utility.
              </div>
            </li>
          </ul>
          <p className="image-margin">
            <img src="images/UserDocsImages/configureopcua3.png" />
            <div>Figure 8: Manual Nodeset file upload</div>
          </p>
          <p>
            Click the Submit button to upload the Nodeset configuration. An
            acknowledge message confirms the Nodeset configuration upload has
            been initiated.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/configureopcua4.png" />
            <div>Figure 9: Initiate Nodeset configuration upload</div>
          </p>
          <p>
            Once the Nodeset configuration is uploaded, configuration of the
            types will take some time based on the size of the OPC UA Server.{" "}
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/configureopcuaupdated2.png" />
            <div>Figure 10: Nodeset configuration processing</div>
          </p>
          <p>
            The ‘Update server’ button will be enabled once the configuration is
            complete. Click on the ‘Update server’ button to complete the Server
            configuration.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/configureopcuaupdated3.png" />
            <div>Figure 11: Nodeset configuration complete</div>
          </p>
          <p>
            Once the Server configuration is saved the OPC UA Server will be
            added to the list of connected Servers on the Edge Device with the
            status set to ‘Connected’.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/configureopcuaupdated4.png" />
            <div>Figure 12: OPC Servers Page</div>
          </p>
          <p>
            While connecting to OPC UA Server or during Nodeset upload, even if
            the ‘Connect to OPC UA Server’ popup is closed at any time by
            clicking on the <img src="images/UserDocsImages/crossupdated.png" />{" "}
            button the operation will progress in the background. In the OPC
            Servers page, the status row will show the status of the server
            configuration. Clicking on{" "}
            <img src="images/UserDocsImages/serverOptions.png" /> button in the
            server row and selecting “Server Settings” option will open the
            ‘Update Server’ popup again.
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.2.browseopcuahierarchy%"
          ref={refs["%3.opcuaconfigurationtool%%3.2.browseopcuahierarchy%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.2.browseopcuahierarchy%"
            )
          }
        >
          <p className="headers-document">3.2. Browse OPC UA Hierarchy</p>
          <p>
            The OPC Servers page lists the connected OPC UA Servers for each
            Edge Device. UA Servers with status set to ‘Connected’ state will be
            available for browse. Click on the OPC UA Server from the list to
            browse the server objects.
          </p>
          <p>
            Click on a ‘Connected’ OPC UA Server row from the OPC UA Servers
            list to navigate to the Browse Server page.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcibrowse.png" />
            <div>Figure 13: Browse Server Page</div>
          </p>
          <p>
            The Browse Server page lists the data entities of the selected OPC
            UA Server in a tree view, which can be browsed by the user.
            Selecting a node from the browse tree displays the available
            variables for the selected node object.
          </p>
          <p>
            The ‘Selected node’ section displays the following fields when the
            user selects a node from the browse tree.
          </p>
          <table>
            <colgroup>
              <col span={1} style={{ width: "20%" }} />
              <col span={1} style={{ width: "80%" }} />
            </colgroup>
            <tr>
              <th>Field Name</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>Title</td>
              <td>Name of the object (will be displayed in the title)</td>
            </tr>
            <tr>
              <td>Desired Type</td>
              <td>
                Editable field. Disabled if an ability type is detected during
                Nodeset configuration upload. If the ability type is not
                detected or refers to FolderType or baseobjecttype, user can
                specify the type name in this field which will be assigned on
                subscription of any variables in the Object (by clicking
                ‘Activate’)
              </td>
            </tr>
            <tr>
              <td>DataBinding Name</td>
              <td>
                Editable field. The data binding object name can be edited
                before subscription.
              </td>
            </tr>
            <tr>
              <td>Sampling Interval</td>
              <td>
                Editable field. The default Sampling interval is set as 1000
                milliseconds. Updates to the ‘Sampling interval’ is applicable
                to all the variables under the object selected for activation.
              </td>
            </tr>
          </table>
          {/* <p>
            The ‘Selected node’ section also displays the list of variable tags
            available in the Object. The Variables will be listed with their
            corresponding variable name, variable type id, and browse name. User
            can subscribe to tags by selecting them from the list and clicking
            ‘Collect’. Already subscribed tags are marked with a checkbox. Tags
            with unsupported data types (e.g., custom data types) are disabled
            (grey) and cannot be selected. Additional Information for the OPC UA
            object can be viewed by clicking on the icon near the object name.
          </p> */}
          <p>
            The ‘Selected node’ section also displays the list of variable tags
            and the list of methods available in the Object.
          </p>
          <p>
            The Variables will be listed with their corresponding variable name,
            variable type id, and browse name.
          </p>
          <p>
            The Methods will be listed with their corresponding method name and
            browse name.
          </p>
          <p>
            User can subscribe to variable tags or enable the methods by
            selecting them from the list and clicking ‘Activate’. Already
            subscribed variable tags and enabled methods are marked with a
            checkbox.{" "}
          </p>
          <p>
            Variables and Methods with unsupported data types (e.g., custom data
            types) are disabled (grey) and cannot be selected for activation.
          </p>
          <p>
            Additional Information for the OPC UA object can be viewed by
            clicking on the{" "}
            <img src="images/UserDocsImages/browseupdated2.png" /> icon near the
            object name.
          </p>
          <table>
            <colgroup>
              <col span={1} style={{ width: "20%" }} />
              <col span={1} style={{ width: "80%" }} />
            </colgroup>
            <tr>
              <td>OPC UA Type</td>
              <td>OPC UA Type</td>
            </tr>
            <tr>
              <td>NodeId</td>
              <td>
                Unique identifier for the node in the OPC UA Address space
              </td>
            </tr>
            <tr>
              <td>Detected Ability Type</td>
              <td>
                Ability type corresponding to the OPC UA Type generated during
                Nodeset configuration upload. If the OPC UA Type cannot be
                mapped during Nodeset upload this field is set to ‘Undefined’.
                User then has the option to manually specify the type via the
                ‘Desired Type’ field
              </td>
            </tr>
            <tr>
              <td>Object Existing As</td>
              <td>
                Blank by default. If any variable is subscribed an Object Id
                generated in Ability Information Model, which uniquely
                identifies the object.
              </td>
            </tr>
          </table>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcinodeDetails.png" />
            <div>Figure 14: Node Details popup</div>
          </p>
          <p>
            Additional Information regarding the input parameters and output
            parameters for the Methods can be viewed by clicking on the{" "}
            <img src="images/UserDocsImages/browseupdated2.png" /> icon in the
            method row.
          </p>
          <table>
            <colgroup>
              <col span={1} style={{ width: "20%" }} />
              <col span={1} style={{ width: "80%" }} />
            </colgroup>
            <tr>
              <td>Input/Output Parameter Name</td>
              <td>Input/Output parameter</td>
            </tr>
            <tr>
              <td>Data Type</td>
              <td>Data Type</td>
            </tr>
            <tr>
              <td>Node Type Id</td>
              <td>Corresponding Node Type Id</td>
            </tr>
          </table>
          <p className="image-margin">
            <img src="images/UserDocsImages/mciinputoutputparams.png" />
            <div>Figure 15: Input and Output Parameters details</div>
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.3.activatevariablesandmethods%"
          ref={
            refs["%3.opcuaconfigurationtool%%3.3.activatevariablesandmethods%"]
          }
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.3.activatevariablesandmethods%"
            )
          }
        >
          <p className="headers-document">
            3.3. Activate Variables and Methods
          </p>
          <p>
            In the Browse Server page, navigate through the object hierarchy to
            select the object that contains the required variables. From the
            'Selected Node' section, select the variables for subscription
            and/or the methods to be enabled and click “Activate” button.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcicollectnew.png" />
            <div>Figure 16: Select Variables and Methods for Activation</div>
          </p>
          <p>Check for confirmation message.</p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcicollectnewsuccess.png" />
            <div>Figure 17: Activate Variables and Methods</div>
          </p>
          <p>
            The user can filter through the variables and methods by clicking on
            the <img src="images/UserDocsImages/browsefiltericon.png" /> button.{" "}
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcifilter.png" />
            <div>Figure 18: Variables and Methods Filter</div>
          </p>
          <p>
            The users can view the lists of objects that has variables activated
            and published to Ability Instance in the “Configured Data” tab under
            “Configured Objects” section. In this tab users can either pause
            subscription or enable subscription for the published objects, or
            remove objects from the collections, as needed. The action will be
            applicable to all the variables under the selected object.
          </p>
          <p>
            Variables that are already selected for activation will be shown as
            checked in the ‘Browse Server – Selected Node’ section. Remove the
            selection and click ‘Modify’ button to remove the variable from the
            subscribed list.
          </p>
          <p>
            The users can also view the methods that were enabled in the
            “Configured Data” tab under “Enabled Methods” section. The user can
            disable the methods by selecting them and clicking on “Disable”
            button. Methods those are activated will be shown as checked in the
            ‘Browse server – Selected Node’ section. Alternatively, user can
            also remove the selection and click Modify button to disable methods
            of a selected node.
          </p>
          <p>
            OPC UA Method can also be invoked from the Edgenius Applications
			      (e.g. Asset Hub) using Method APIs
          </p>
          <p>
            The parameters of Method APIs do not support any space in the
			      parameter name.
			      For example, Floatvalue is supported but Float Value as a
			      parameter name is not supported
          </p>                            
          <p className="image-margin">
            <img src="images/UserDocsImages/mciconfiguredDatanew.png" />
            <div>Figure 19: Configured Data</div>
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.4.configureopcuatypes%"
          ref={refs["%3.opcuaconfigurationtool%%3.4.configureopcuatypes%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.4.configureopcuatypes%"
            )
          }
        >
          <p className="headers-document">3.4. Configuring OPC UA Types</p>
          <p>
            When a connection is established to an OPC UA Server from OPC UA
            Config Tool, the next step is Nodeset configuration upload where the
            OPC UA namespace is imported to the Ability Instance so that it can
            then be browsed, and the variables subscribed in OPC UA Config
            Utility. It also makes the subscribed variables available to other
            application in the Ability instance like ABB Edgenius™ Dashboard and
            ABB Edgenius™ Stream Calculation Engineering Tool.
          </p>
          <p>
            As part of the Nodeset configuration upload the OPC Types are
            processed and mapped to corresponding Ability Types. Its possible
            that OPC UA server has objects without a OPC UA Type associated or
            associated with generic OPC UA type such as Folder Type.
          </p>
          <p>
            The ‘Detected Ability Type’ field will be set to ‘undefined’ for
            objects whose Ability Type could not be mapped during Nodeset
            configuration upload. It is possible to to activate and subscribe
            variables from Objects whose Ability Type is set as undefined, but
            their type will be set to either opcfoundation.org.ua.baseobjecttype
            or opcfoundation.org.UA.FolderType based on the associated UA type,
            on subscription.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mciopcuatypesnodedetails.png" />
            <div>Figure 20: Detected Ability Type: Undefined</div>
          </p>
          <p>
            It is also possible to configure the ‘Desired Type’ while
            subscribing to the variables. For objects whose ‘Detected Ability
            Type’ is set as undefined, the ‘Desired Type’ field will be enabled,
            and user can give a type name for the object when variables or
            methods are activated.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcicreatingtypeone.png" />
            <div>Figure 21: Desired Type</div>
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcitypepopupone.png" />
            <div>Figure 22: User defined Type</div>
          </p>
          <p>
            A confirmation box will ask the user whether the new type should be
            created with the selected Variables. On clicking ‘ok’ the type is
            mapped for that object.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcitypenodedetailspopup.png" />
            <div>Figure 23: User defined Type</div>
          </p>
          <p>
            Once the variables or methods are activated, the configured type is
            mapped to the object.
          </p>
          <p>
            If the same Type name is used to map a different Folder Type object
            or the same object with a different set of variables, a confirmation
            message will check whether the user wants to proceed. While it is
            possible to proceed and map the type to multiple objects, the
            recommendation is to use unique type names for different objects.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcitypepopuptwo.png" />
            <div>Figure 24: User defined Type</div>
          </p>
          <p>
            Deleting the object from the ’Configured Data’ allows the user to
            give a different type mapping name as well as a select a different
            set of tags for subscription.
          </p>
        </div>
        <div
          id="%3.opcuaconfigurationtool%%3.5.editdeleteserver%"
          ref={refs["%3.opcuaconfigurationtool%%3.5.editdeleteserver%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%3.opcuaconfigurationtool%%3.5.editdeleteserver%")
          }
        >
          <p className="headers-document">
            3.5. Edit/Delete OPU UA Server Configuration
          </p>
          <p>
            Click ‘OPC Servers’ on the top left corner of the Browse Server Page
            to go back to OPC Servers page.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/mcirouting.png" />
            <div>Figure 25: Back to OPC Servers page</div>
          </p>
          <ul>
            <li>
              <p>Edit Server Info</p>
              <p>
                Click on <img src="images/UserDocsImages/serverOptions.png" />{" "}
                on the server row and select “Server Settings”. It will open the
                “Update OPC UA Server” popup where the user can rename the OPC
                UA Server, update the server description or initiate upload of
                node set configuration again in case the Server information
                model has been updated.
              </p>
              <p className="image-margin">
                <img src="images/UserDocsImages/connecttoopcuaupdated8.png" />
                <div>Figure 26: Server actions pop up</div>
              </p>
              <p className="image-margin">
                <img src="images/UserDocsImages/detectingserverupdated1.png" />
                <div>Figure 27: Update OPC UA Server pop up</div>
              </p>
            </li>
            <li>
              <p>Delete Server</p>
              <p>
                Click on <img src="images/UserDocsImages/deleteicon.png" />{" "}
                button on the server row. It will open a warning popup asking
                for confirmation. Click on 'Remove' button to remove the Server.
              </p>
              <p className="image-margin">
                <img src="images/UserDocsImages/removeserverupdated1.png" />
                <div>Figure 28: Remove OPC Server Page</div>
              </p>
            </li>
          </ul>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%"
          ref={refs["%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%"
            )
          }
        >
          <p className="headers-document">
            3.6. OPC UA Configuration Utility – Pages
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.1.opcuaservers%"
          ref={
            refs[
              "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.1.opcuaservers%"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.1.opcuaservers%"
            )
          }
        >
          <p className="headers-document">3.6.1. OPC UA Servers</p>
          <p>
            This is the main view of the OPC UA Configuration utility. It lists
            the configured OPC UA Servers. Each row in the list shows the server
            name, hosting edge, status and the server description. The User will
            also have an option to view the listed servers grouped by their
            respective edges.
          </p>
          <p>
            Click on a row to navigate to the ‘Browse Server’ page if the status
            is ‘Connected’. If the Server is ‘Not Connected’ or the Nodeset
            upload or server configuration is ongoing the Server Settings popup
            will open instead of the Browse Page.
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connectopcuaupdated1.png" />
            <div>Figure 29: OPC Servers view</div>
          </p>
          <p className="image-margin">
            <img src="images/UserDocsImages/connectopcuaupdated3.png" />
            <div>Figure 30: OPC Servers Grouped by Edges</div>
          </p>
        </div>

        <div
          id="%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.2.browseserver%"
          ref={
            refs[
              "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.2.browseserver%"
            ]
          }
          className="level-3-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.2.browseserver%"
            )
          }
        >
          <p className="headers-document">3.6.4. Browse Server</p>
          <p>
            The ‘Browse Server’ page displays the OPC UA Server object hierarchy
            tree. Selecting an object from the browse tree will show its
            attributes and variables in the ‘Selected node’ section. Users can
            configure ‘Desired Type’, ‘DataBinding Name’ and ‘Sampling Interval’
            from the ‘Selected node’ section.
          </p>
          <p>
            If there are no variables associated with the selected object, the
            Variables table will be empty.
          </p>
          <p>
            <img
              src="images/UserDocsImages/mcibrowse.png"
              className="image-margin"
            />
            <div>Figure 31: Browse server</div>
          </p>
        </div>

        <div
          id="%4.opcuaconnectondemand%"
          ref={refs["%4.opcuaconnectondemand%"]}
          className="level-1-padding"
          onMouseOver={() => props.onDocClick("%4.opcuaconnectondemand%")}
        >
          <p className="headers-document">
            4. OPC UA Configuration Utility – Connect-On-Demand mode
          </p>
          <p>
            OPC UA Configuration Utility can be deployed to the
            Connect-On-Demand Edge as an Edge Application. Here the OPC UA
            Configuration Utility can be accessed directly from the Edge Node
            for configuring southbound OPC UA Servers.{" "}
          </p>
          <p>
            Installation is made using the Edgenius Management Portal (EMP)
            website. This chapter describes how to install and setup the
            application.
          </p>
        </div>

        <div
          id="%4.opcuaconnectondemand%%4.1.installation%"
          ref={refs["%4.opcuaconnectondemand%%4.1.installation%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%4.opcuaconnectondemand%%4.1.installation%")
          }
        >
          <p className="headers-document">4.1. Installation</p>
          <p>To install the OPC UA Configuration Utility application:</p>
          <p>Navigate to the main page of the edge in EMP.</p>
          <p>
            Locate the OPC UA Configuration Utility application in the ‘All’ tab
            of the Applications list:
          </p>
          <p>
            <img
              src="images/UserDocsImages/installation1.png"
              className="image-margin"
            />
            <div>Figure 32</div>
          </p>
          <p>
            Click on <img src="images/UserDocsImages/installation2.png" /> to
            install the application.
          </p>
          <p>
            After installation, the application can be found in the ‘On this
            edge’ tab:
          </p>
          <p>
            <img
              src="images/UserDocsImages/installation3.png"
              className="image-margin"
            />
            <div>Figure 33</div>
          </p>
          <p>Click on the application to navigate to the application page.</p>
          <p>
            Installation is ready. Deployment of OPC UA Configuration Utility
            will automatically deploy ABB Warmstorage and OPC UA Connect
            applications.
          </p>
        </div>

        <div
          id="%4.opcuaconnectondemand%%4.2.routingconfiguration%"
          ref={refs["%4.opcuaconnectondemand%%4.2.routingconfiguration%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick(
              "%4.opcuaconnectondemand%%4.2.routingconfiguration%"
            )
          }
        >
          <p className="headers-document">4.2. Routing Configuration</p>
          <p>
            After installation configure the routing in the Edge for OPC UA
            Connect. In Connect-On-Demand Edge, instead of Cloud routes, warm
            data routes should be configured.
          </p>
          <p>
            To configure the routes of your edge, open the settings page for the
            edge and select Routing. For sending OPC UA data to other
            application hosted in Connect-On-Demand Edge select ‘Warm’ route and
            Add a route with ‘Variable Data’ as type (only relevant type for OPC
            UA), ‘Warm’ as route and ‘OPC UA’ as the model.{" "}
          </p>
          <p>
            <img
              src="images/UserDocsImages/routingConfiguration.png"
              className="image-margin"
            />
            <div>Figure 34</div>
          </p>
          <p>
            Once the routes are added click Save to Save the route
            configuration.
          </p>
        </div>

        <div
          id="%4.opcuaconnectondemand%%4.3.synchronization%"
          ref={refs["%4.opcuaconnectondemand%%4.3.synchronization%"]}
          className="level-2-padding"
          onMouseOver={() =>
            props.onDocClick("%4.opcuaconnectondemand%%4.3.synchronization%")
          }
        >
          <p className="headers-document">4.3. Synchronization</p>
          <p>
            Type Definitions and Model Definitions for the Edge Applications
            need to be synchronized between the Ability Cloud instance and
            Connect-On-Demand Edge after deploying an application.
          </p>
          <p>
            To Synchronize, open the settings page for the edge and select
            ‘Basic Edge Settings’.
          </p>
          <p>
            Click on the{" "}
            <img src="images/UserDocsImages/synchronization1.png" /> button to
            initiate Model and Type synchronization.
          </p>
          <p>
            <img
              src="images/UserDocsImages/synchronization2.png"
              className="image-margin"
            />
            <div>Figure 35</div>
          </p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Documentation);
