import Axios from "axios";
import { commonAuthHelper } from "../App";
import { envSettings } from "../EnviornmentSettings/Settings";

export const getErrorMessage = (e: any, info?: string) => {
  let errorMessage: string = "";
  if (e.response && e.response.status === 400) {
    if (e.response.data) {
      if (e.response.data.title) {
        errorMessage = e.response.data.title;
      } else if (e.response.data.info) {
        errorMessage = e.response.data.info;
      } else {
        errorMessage = e.response.data;
      }
    } else {
      errorMessage = "400 bad request.";
    }
  } else {
    errorMessage =
      e.response && e.response.data
        ? typeof e.response.data === "string"
          ? e.response.data
          : e.response.data.errorMessage
        : "Error";
  }
  if ((errorMessage as any) === undefined || errorMessage === "") {
    errorMessage = "Error";
  }
  return errorMessage;
};

export const setCommonHeaders = () => {
  if (envSettings.connectivityMode === "connected") {
    Axios.defaults.headers.common["X-Tenant"] = localStorage.getItem("tenant");
  }
  let token = commonAuthHelper.getAccessToken();
  Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
export const isConfigureStatePollingNeeded = (
  configureState: number | undefined
) =>
  configureState === undefined ||
  configureState === null ||
  [0, 7, 8].indexOf(configureState) >= 0
    ? false
    : true;
export const isBrowsingAllowed = (
  configureState: number | undefined,
  lastConfiguredTime: string | undefined
) => {
  if (
    configureState === 7 ||
    (configureState === 8 && lastConfiguredTime !== null)
  ) {
    return true;
  } else {
    return false;
  }
};
export const isSessionStatePollingNeeded = (sessionState: number | undefined) =>
  sessionState === undefined ||
  sessionState === null ||
  [2].indexOf(sessionState) >= 0
    ? false
    : true;
export const isUpdateServerBlocked = (
  sessionState: number | undefined,
  sessionStateMessage: string | undefined,
  isPollingTimedOut: boolean,
  isSessionStatePolling: boolean
) => {
  if (!isSessionStatePolling) {
    return false;
  }
  if (sessionState === undefined) {
    if (isPollingTimedOut && sessionStateMessage === undefined) {
      return false;
    } else {
      return true;
    }
  } else {
    if ([2].indexOf(sessionState!) >= 0) {
      return false;
    } else {
      return true;
    }
  }
};
