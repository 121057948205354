import React from "react";

export interface IFilePickerProps {
  onChange: (e: any) => void;
  multiple?: boolean;
  acceptTypes?: string;
  value?: any;
}

const FilePickerButton: React.FunctionComponent<IFilePickerProps> = (props) => {
  const ref: React.RefObject<HTMLInputElement> = React.createRef();

  const onClick = (e: any) => {
    e.preventDefault();
    if (ref.current) {
      ref.current.click();
    }
  };

  const fileInput =
    props.acceptTypes !== undefined
      ? React.createElement("input", {
          type: "file",
          ref,
          style: { display: "none" },
          onChange: props.onChange,
          multiple: props.multiple,
          value: props.value || "",
          accept: props.acceptTypes,
        })
      : React.createElement("input", {
          type: "file",
          ref,
          style: { display: "none" },
          onChange: props.onChange,
          multiple: props.multiple,
          value: props.value || "",
        });

  const child: any =
    React.Children.count(props.children) === 0 ? (
      <button>Browse</button>
    ) : (
      React.Children.only(props.children)
    );

  const clone = React.cloneElement(child, { onClick });

  return (
    <div>
      {clone}
      {fileInput}
    </div>
  );
};
export default FilePickerButton;
