import { envSettings } from "../EnviornmentSettings/Settings";

export const getServers = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/opcServers`
  : "Fixtures/ServerLandingUpdated.json";
export const getServersStatus = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/opcServers/status`
  : "Fixtures/ServerLandingUpdated.json";
export const getCurrentServer = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/opcServers`
  : "Fixtures/ServerLandingUpdated.json";
export const getSessionStateData = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/opcServers`
  : "Fixtures/ServerLandingUpdated.json";
export const getBrowseData = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/opcServers`
  : "Fixtures/BrowseDataTest.json";
export const getEdgeList = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/Edges`
  : "Fixtures/EdgeList.json";
export const getDataBinding = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/DataBindings`
  : "Fixtures/bindingData.json";
export const getSelectedMethodData = "Fixtures/SelectedMethodData.json";
export const getTypeInfo = `${envSettings.webapiUrl}/UATypes/query`;
export const getDesiredType = `${envSettings.webapiUrl}/UATypes`;
export const getConfiguredData = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/OpcServers`
  : "Fixtures/confguredData.json";
export const getEnabledMethodData = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/OpcServers`
  : "Fixtures/EnabledMethodData.json";
export const disableMethods = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/OpcServers`
  : "Fixtures/EnabledMethodData.json";
export const updateConfiguredData = `${envSettings.webapiUrl}/OpcServers`;
export const submitAddServer = `${envSettings.webapiUrl}/opcServers/AddOpcServer`;
export const submitUpdateServer = `${envSettings.webapiUrl}/opcServers/UpdateOpcServer`;
export const submitDeleteServer = `${envSettings.webapiUrl}/opcServers/RemoveOpcServer`;
export const getPreference = !envSettings.isOfflineMode
  ? `${envSettings.webapiUrl}/User/Login`
  : "Fixtures/preference.json";
export const updatePreference = `${envSettings.webapiUrl}/user/UpdateUserTheme`;
