import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./BrowseRightPannel.css";
import * as browseActions from "../Action/ActionBrowse";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import { Button, Dialog, Icon, Input } from "@abb/abb-common-ux-react";
import BrowseGrid from "../BrowseGrid/BrowseGrid";
import * as browseTypes from "../Type/TypeBrowse";
import {
  availableTypes,
  methodGridFixedWidth,
  variableGridFixedWidth,
} from "../../../Utils/Constants";
import {
  childrenDataHeader,
  formatChildrenData,
  formatMethodChildrenData,
  formatSelectedMethodData,
  inputParamsHeader,
  methodDataHeader,
  outputParamsHeader,
} from "../DataFormatter/BrowseDataFormatter";
import _ from "underscore";

interface browseRightPannelProps extends RouteComponentProps {
  key?: string;
}
const BrowseRightPannel = (props: browseRightPannelProps) => {
  const browseState = useSelector((state: IState) => state.browseState);
  const typeInfoValidationErrorMessage = "Please enter a valid desired type";
  const tempSamplingInterval = browseState.currentSamplingInterval;
  const truncatedSamplingInterval = tempSamplingInterval.replace(/^0+/g, "");
  const [isValid, setIsValid] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    flatenedNodes,
    dataBindingResponse,
    selectedNode,
    selectedNodeId,
    selectedNodeChildren,
    currentObjVariables,
    originalObjVariables,
    currentObjMethods,
    originalObjMethods,
  } = { ...browseState };
  const getDetectedAbilityType = () => {
    if (browseState.typeInfoData && browseState.typeInfoData.typeId) {
      let truncatedString =
        browseState.typeInfoData.typeId === "notfound"
          ? "Undefined"
          : browseState.typeInfoData.typeId;
      const splittedVesrion = browseState.typeInfoData.version
        ? browseState.typeInfoData.version.split(".")
        : [""];
      truncatedString = truncatedString.replace(
        /\.tenantId\..*/,
        `@${splittedVesrion[0]}`
      );
      return truncatedString;
    }
    if (
      dataBindingResponse &&
      dataBindingResponse.properties &&
      dataBindingResponse.properties.objectBinding &&
      dataBindingResponse.properties.objectBinding.typeId &&
      dataBindingResponse.properties.objectBinding.typeId.value
    ) {
      if (
        dataBindingResponse.properties.objectBinding.nodeId &&
        dataBindingResponse.properties.objectBinding.nodeId.value ===
        selectedNodeId
      ) {
        let truncatedString =
          dataBindingResponse.properties.objectBinding.typeId.value;
        truncatedString = truncatedString.replace(/\.tenantId\..*@/, "@");

        return truncatedString;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const getObjectExistingAs = () => {
    if (dataBindingResponse) {
      if (
        dataBindingResponse.properties &&
        dataBindingResponse.properties.objectBinding &&
        dataBindingResponse.properties.objectBinding.nodeId &&
        dataBindingResponse.properties.objectBinding.nodeId.value ===
        selectedNodeId
      ) {
        return dataBindingResponse.objectId;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const handleSamplingInterval = (value: string): void => {
    const samplingIntervalDuration: string = value;
    let count = 0;
    if (samplingIntervalDuration === "") {
      dispatch(
        browseActions.handleSamplingIntervalChange(samplingIntervalDuration)
      );
    } else {
      for (let i = 0; i < samplingIntervalDuration.length; i++) {
        const charUnicode = samplingIntervalDuration.charCodeAt(i);
        if (charUnicode < 48 || charUnicode > 57) {
          count = count + 1;
        }
      }
      if (count === 0) {
        dispatch(
          browseActions.handleSamplingIntervalChange(samplingIntervalDuration)
        );
      }
    }
  };
  const checkIsAllSelected = () => {
    let children = [...browseState.filteredVariableNodes];
    children = children.filter(
      (child) => child.nodeClass === availableTypes.uaVariable
    );
    const childrenUnSupported = children.filter(
      (child) => !child.isSupportedDataType
    );
    let isAllSelected = true;
    if (children.length > 0) {
      if (childrenUnSupported.length === children.length) {
        isAllSelected = false;
      } else {
        children.map((child) => {
          if (
            !currentObjVariables.some((e) => e.nodeId === child.nodeId) &&
            child.isSupportedDataType
          ) {
            isAllSelected = false;
          }
        });
      }
    } else {
      isAllSelected = false;
    }
    return isAllSelected;
  };
  const checkIsAllSelectedMethod = () => {
    let children = [...browseState.filteredMethodNodes];
    children = children.filter(
      (child) => child.nodeClass === availableTypes.uaMethod
    );
    const childrenUnSupported = children.filter(
      (child) =>
        !child.isSupportedDataType &&
        child.nodeClass === availableTypes.uaMethod
    );
    let isAllSelected = true;
    if (children.length > 0) {
      if (childrenUnSupported.length === children.length) {
        isAllSelected = false;
      } else {
        children.map((child) => {
          if (
            !currentObjMethods.some((e) => e.nodeId === child.nodeId) &&
            child.isSupportedDataType
          ) {
            isAllSelected = false;
          }
        });
      }
    } else {
      isAllSelected = false;
    }
    return isAllSelected;
  };
  const onActionClick = (
    actionType: "view" | "edit" | "delete",
    rowObj: browseTypes.IEachRowObj
  ) => {
    if (actionType === "view") {
      dispatch(browseActions.showInputOutputParameters({ ...rowObj }));
    }
  };
  const handleSelectAll = (newState: boolean) => {
    dispatch(browseActions.handleSelectAllVariables(newState));
  };
  const handleSelectAllMethod = (newState: boolean) => {
    dispatch(browseActions.handleSelectAllMethods(newState));
  };
  const handleSortVariable = (
    sortColumnId: string,
    sortDirection: string | undefined
  ) => {
    let newDirection: "asc" | "desc" = "desc";
    if (sortColumnId === browseState.variableSortColumnId) {
      newDirection = sortDirection !== "asc" ? "asc" : "desc";
    }
    dispatch(browseActions.sortVariabledData(newDirection, sortColumnId));
  };
  const handleSortMethod = (
    sortColumnId: string,
    sortDirection: string | undefined
  ) => {
    let newDirection: "asc" | "desc" = "desc";
    if (sortColumnId === browseState.methodSortColumnId) {
      newDirection = sortDirection !== "asc" ? "asc" : "desc";
    }
    dispatch(browseActions.sortMethoddData(newDirection, sortColumnId));
  };
  const getSortedVariableData = (unSortedData: browseTypes.IEachRowObj[]) => {
    let newDirection: "desc" | "asc" = browseState.variableSortDirection;
    let sortColId = browseState.variableSortColumnId;
    let colIdIndex = 0;
    childrenDataHeader.map((item, index) => {
      if (item.id === sortColId) {
        colIdIndex = index;
      }
    });
    const currData = [...unSortedData];
    currData.sort((a: any, b: any) => {
      let x = `${a.data[colIdIndex].rawVal ? a.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
      let y = `${b.data[colIdIndex].rawVal ? b.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
      if (sortColId === "selectColumn") {
        x = a.isSelected ? "a" : "b";
        y = b.isSelected ? "a" : "b";
      }
      if (newDirection === "desc") {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      } else if (newDirection === "asc") {
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
      }
      return 0;
    });
    return [...currData];
  };
  const getSortedMethodData = (unSortedData: browseTypes.IEachRowObj[]) => {
    let newDirection: "desc" | "asc" = browseState.methodSortDirection;
    let sortColId = browseState.methodSortColumnId;
    let colIdIndex = 0;
    childrenDataHeader.map((item, index) => {
      if (item.id === sortColId) {
        colIdIndex = index;
      }
    });
    const currData = [...unSortedData];
    currData.sort((a: any, b: any) => {
      let x = `${a.data[colIdIndex].rawVal ? a.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
      let y = `${b.data[colIdIndex].rawVal ? b.data[colIdIndex].rawVal : ""
        }`.toLowerCase();
      if (sortColId === "selectColumn") {
        x = a.isSelected ? "a" : "b";
        y = b.isSelected ? "a" : "b";
      }
      if (newDirection === "desc") {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      } else if (newDirection === "asc") {
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
      }
      return 0;
    });
    return [...currData];
  };
  const variablesTableCommon = () => {
    const formattedChildrenData = formatChildrenData(
      [...browseState.filteredVariableNodes],
      { ...selectedNode },
      [...currentObjVariables]
    );
    const sortedFormattedData = getSortedVariableData([
      ...formattedChildrenData,
    ]);
    const variablesTableView: JSX.Element[] = [];
    variablesTableView.push(
      <BrowseGrid
        data={sortedFormattedData}
        header={[...childrenDataHeader]}
        noDataText=""
        isCheckBoxEnabled={true}
        showSelectAllOption={true}
        isSelectedAll={checkIsAllSelected()}
        isSelectAllDisabled={false}
        isFilterEnabled={true}
        filterParams={{ ...browseState.variableFilters }}
        handleFilter={(id: string, filterText: string) =>
          dispatch(browseActions.handleFilter(id, filterText))
        }
        showFilter={browseState.showFilterVariableData}
        handleShowFilter={(showFilter) =>
          dispatch(browseActions.showFilterVariableData(showFilter))
        }
        handleSelectAll={(isSelectedAll: boolean) => {
          // setIsModified(matchObjectVariables());
          handleSelectAll(isSelectedAll)
        }}
        onCheckBoxClick={(item: browseTypes.IEachRowObj) => {
          // setIsModified(matchObjectVariables());
          dispatch(
            browseActions.handleVriableSelect(!item.isSelected, {
              ...item.rawData,
            })
          )
        }
        }
        isSortEnabled={true}
        onSort={(id: string, sortDirection: string | undefined) =>
          handleSortVariable(id, sortDirection)
        }
        isSortEnabledForSelectCollumn={true}
        sortedColumnId={browseState.variableSortColumnId}
        sortDirection={browseState.variableSortDirection}
        minWidth={document.documentElement.clientWidth - variableGridFixedWidth}
        className="variables-view"
        key={props.key ? props.key : ""}
      />
    );
    return variablesTableView;
  };

  const methodsTableCommon = () => {
    const formattedChildrenData = formatMethodChildrenData(
      [...browseState.filteredMethodNodes],
      { ...selectedNode },
      [...currentObjMethods]
    );
    const sortedFormattedData = getSortedMethodData([...formattedChildrenData]);
    const methodsTableView: JSX.Element[] = [];
    methodsTableView.push(
      <BrowseGrid
        data={sortedFormattedData}
        header={[...methodDataHeader]}
        noDataText=""
        isCheckBoxEnabled={true}
        showSelectAllOption={true}
        isSelectedAll={checkIsAllSelectedMethod()}
        isSelectAllDisabled={false}
        isFilterEnabled={true}
        filterParams={{ ...browseState.methodFilters }}
        isViewActionEnabled={true}
        onActionClick={onActionClick}
        handleFilter={(id: string, filterText: string) =>
          dispatch(browseActions.handleFilterMethod(id, filterText))
        }
        showFilter={browseState.showFilterMethodData}
        handleShowFilter={(showFilter) =>
          dispatch(browseActions.showFilterMethodData(showFilter))
        }
        handleSelectAll={(isSelectedAll: boolean) => {
          // setIsModified(matchObjectVariables());
          handleSelectAllMethod(isSelectedAll)
        }
        }
        onCheckBoxClick={(item: browseTypes.IEachRowObj) => {
          // setIsModified(matchObjectVariables());
          dispatch(
            browseActions.handleMethodsSelect(!item.isSelected, {
              ...item.rawData,
            })
          )
        }}
        isSortEnabled={true}
        onSort={(id: string, sortDirection: string | undefined) =>
          handleSortMethod(id, sortDirection)
        }
        isSortEnabledForSelectCollumn={true}
        sortedColumnId={browseState.methodSortColumnId}
        sortDirection={browseState.methodSortDirection}
        minWidth={document.documentElement.clientWidth - methodGridFixedWidth}
        className="methods-view"
        resizeId="methods-table-resize"
        key={props.key ? props.key : ""}
      />
    );
    return methodsTableView;
  };

  const handleDesiredTypeCopy = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.log("copying failed");
    }
  };

  const shouldEnableDesiredType = () => {
    let children = [...selectedNodeChildren];
    children = children.filter(
      (child) => child.nodeClass === availableTypes.uaVariable
    );
    if (browseState.typeInfoData.typeId === "notfound") {
      return true;
    }
    if (
      browseState.typeInfoData.typeId &&
      browseState.typeInfoData.typeId
        .toLocaleLowerCase()
        .indexOf(".ua.foldertype") >= 0
    ) {
      return true;
    }
    if (
      browseState.typeInfoData.typeId &&
      browseState.typeInfoData.typeId
        .toLocaleLowerCase()
        .indexOf(".ua.baseobjecttype") >= 0
    ) {
      return true;
    }
    // }
    return false;
  };
  const detectedAbilityType = getDetectedAbilityType();
  const enableDesiredType = shouldEnableDesiredType();
  const isDesiredTypeValid = () => {
    if (
      enableDesiredType &&
      browseState.desiredType.replace(/\s/g, "").length !== 0 &&
      !/^(?=.{1,104}$)[\w\-]([\w\-]|[\.][\w\-])*$/.test(browseState.desiredType)
    ) {
      return false;
    }
    return true;
  };
  const disableCollectButton = (): boolean => {
    return (
      browseState.isLoading ||
      browseState.isBrowseDataLoading ||
      browseState.isDataBindingLoading ||
      browseState.currentDisplayName.length < 1 ||
      (browseState.currentObjVariables.length === 0 &&
        browseState.currentObjMethods.length === 0)
    );
  };

  useEffect(() => {
    setIsValid(!matchObjectVariables());
  }, [browseState.currentObjVariables, browseState.currentObjMethods]);


  const matchObjectVariables = (): boolean => {
    if (
      browseState.currentObjVariables.length !== browseState.originalObjVariables.length ||
      browseState.currentObjMethods.length !== browseState.originalObjMethods.length
    ) {
      return false;
    } else {
      let match = true;
      let variableMatch = true;
      let methodMatch = true;
      browseState.originalObjVariables.map((originalItem) => {
        let count = 0;
        browseState.currentObjVariables.map((item) => {
          if (originalItem.nodeId === item.nodeId) {
            count = count + 1;
          }
        });
        if (count === 0) {
          variableMatch = false;
        }
      });
      browseState.originalObjMethods.map((originalItem) => {
        let count = 0;
        browseState.currentObjMethods.map((item) => {
          if (originalItem.nodeId === item.nodeId) {
            count = count + 1;
          }
        });
        if (count === 0) {
          methodMatch = false;
        }
      });
      match = variableMatch && methodMatch;
      if (
        browseState.currentSamplingInterval !==
        browseState.originalSamplingInterval &&
        browseState.currentSamplingInterval !== ""
      ) {
        return false;
      } else {
        if (
          browseState.currentDisplayName !== browseState.originalDisplayName
        ) {
          return false;
        } else {
          return match;
        }
      }
    }
  };

  const addDataBindingClick = (): void => {
    const updatedVariablesForRequest: browseTypes.IObjVariableForCollect[] = [];
    const updatedMethodsForRequest: browseTypes.IObjMethodForCollect[] = [];
    let children = [...selectedNodeChildren];
    const groupedChildren: { [key: string]: browseTypes.InodeObj[] } =
      _.groupBy(children, "nodeId");
    [...selectedNodeChildren].map((child) => {
      currentObjVariables.map((variable) => {
        if (child.nodeId === variable.nodeId) {
          updatedVariablesForRequest.push({
            nodeId: child.nodeId,
            browseName: child.browseName,
            nodeTypeId: child.nodeTypeId,
            displayName: child.displayName,
            isEnumOrOptionSet: child.isEnumOrOptionSet ? true : false,
            isArray: child.isArray ? true : false,
          });
        }
      });
      currentObjMethods.map((method) => {
        if (child.nodeId === method.nodeId) {
          const inputArgs = [
            ...(groupedChildren[method.nodeId] &&
              groupedChildren[method.nodeId][0] &&
              groupedChildren[method.nodeId][0].inputArguments
              ? groupedChildren[method.nodeId][0].inputArguments!
              : []),
          ];
          const outputArgs = [
            ...(groupedChildren[method.nodeId] &&
              groupedChildren[method.nodeId][0] &&
              groupedChildren[method.nodeId][0].outputArguments
              ? groupedChildren[method.nodeId][0].outputArguments!
              : []),
          ];
          const formattedInputArgs = inputArgs.map((item, index) => {
            return {
              nodeId: method.nodeId,
              browseName: item.browseName,
              displayName: item.displayName,
              nodeTypeId: item.nodeTypeId,
              isArray: item.isArray ? true : false,
              isEnumOrOptionSet: item.isEnumOrOptionSet ? true : false,
              index: item.index ? item.index : 0,
              valueRank: item.valueRank ? item.valueRank : 0,
            };
          });
          const formattedOutputArgs = outputArgs.map((item, index) => {
            return {
              nodeId: method.nodeId,
              browseName: item.browseName,
              displayName: item.displayName,
              nodeTypeId: item.nodeTypeId,
              isArray: item.isArray ? true : false,
              isEnumOrOptionSet: item.isEnumOrOptionSet ? true : false,
              index: item.index ? item.index : 0,
              valueRank: item.valueRank ? item.valueRank : 0,
            };
          });
          updatedMethodsForRequest.push({
            nodeId: child.nodeId,
            browseName: child.browseName,
            methodNodeId: child.nodeTypeId,
            displayName: child.displayName,
            valueRank: child.valueRank ? child.valueRank : 0,
            inputArguments: [...formattedInputArgs],
            outputArguments: [...formattedOutputArgs],
          });
        }
      });
    });
    const parentObj = getParentObj();
    if (originalObjVariables.length === 0 && originalObjMethods.length === 0) {
      dispatch(
        browseActions.collectForm({
          uaModuleId: browseState.uaModuleId,
          serverId: browseState.serverId,
          edgeId: browseState.edgeId,
          selectedNode: { ...selectedNode },
          nodeIds: [selectedNode.nodeId],
          variable: [...updatedVariablesForRequest],
          method: [...updatedMethodsForRequest],
          dataBindingName: browseState.currentDisplayName,
          samplingInterval: browseState.currentSamplingInterval,
          parentObj: { ...parentObj },
          desiredType: browseState.desiredType,
        })
      );
    } else {
      dispatch(
        browseActions.modifyForm({
          uaModuleId: browseState.uaModuleId,
          serverId: browseState.serverId,
          edgeId: browseState.edgeId,
          selectedNode: { ...selectedNode },
          nodeIds: [selectedNode.nodeId],
          variable: [...updatedVariablesForRequest],
          method: [...updatedMethodsForRequest],
          dataBindingName: browseState.currentDisplayName,
          samplingInterval: browseState.currentSamplingInterval,
          parentObj: { ...parentObj },
        })
      );
    }
  };
  const onClickCollect = () => {
    if (
      enableDesiredType &&
      browseState.desiredType.replace(/\s/g, "").length !== 0
    ) {
      const updatedVariablesForRequest: browseTypes.IObjVariableForCollect[] =
        [];
      const updatedMethodsForRequest: browseTypes.IObjMethodForCollect[] = [];
      const groupedChildren: { [key: string]: browseTypes.InodeObj[] } =
        _.groupBy([...selectedNodeChildren], "nodeId");
      [...selectedNodeChildren].map((child) => {
        currentObjVariables.map((variable) => {
          if (child.nodeId === variable.nodeId) {
            updatedVariablesForRequest.push({
              nodeId: child.nodeId,
              browseName: child.browseName,
              nodeTypeId: child.nodeTypeId,
              displayName: child.displayName,
              isEnumOrOptionSet: child.isEnumOrOptionSet ? true : false,
              isArray: child.isArray ? true : false,
            });
          }
        });
        currentObjMethods.map((method) => {
          if (child.nodeId === method.nodeId) {
            updatedMethodsForRequest.push({
              nodeId: child.nodeId,
              browseName: child.browseName,
              methodNodeId: child.nodeTypeId,
              displayName: child.displayName,
              valueRank: child.valueRank ? child.valueRank : 0,
              inputArguments:
                browseState.currentlyViewingMethod &&
                  browseState.currentlyViewingMethod.rawData &&
                  browseState.currentlyViewingMethod.rawData.inputArguments
                  ? [
                    ...browseState.currentlyViewingMethod.rawData
                      .inputArguments,
                  ]
                  : [],
              outputArguments:
                browseState.currentlyViewingMethod &&
                  browseState.currentlyViewingMethod.rawData &&
                  browseState.currentlyViewingMethod.rawData.outputArguments
                  ? [
                    ...browseState.currentlyViewingMethod.rawData
                      .outputArguments,
                  ]
                  : [],
            });
          }
        });
      });
      const parentObj = getParentObj();
      dispatch(
        browseActions.getDesiredType(
          [...updatedVariablesForRequest],
          browseState.desiredType,
          {
            uaModuleId: browseState.uaModuleId,
            serverId: browseState.serverId,
            edgeId: browseState.edgeId,
            selectedNode: { ...selectedNode },
            nodeIds: [selectedNode.nodeId],
            variable: [...updatedVariablesForRequest],
            method: [...updatedMethodsForRequest],
            dataBindingName: browseState.currentDisplayName,
            samplingInterval: browseState.currentSamplingInterval,
            parentObj: { ...parentObj },
            desiredType: browseState.desiredType,
          }
        )
      );
    } else {
      addDataBindingClick();
    }
  };

  const getParentObj = () => {
    const parentObjToBeSubmitted = {} as browseTypes.IParentObj;
    const selectedFormattedNode = browseState.formattedTreeNodes.filter(
      (item) => item.rawData.nodeId === selectedNode.nodeId
    );
    let selectedIdsWithParentString = selectedFormattedNode[0]
      ? selectedFormattedNode[0]!.id
      : "";
    selectedIdsWithParentString = selectedIdsWithParentString.substr(1);
    selectedIdsWithParentString = selectedIdsWithParentString.substr(
      0,
      selectedIdsWithParentString.length - 1
    );
    selectedIdsWithParentString = selectedIdsWithParentString.replace(
      /%%/g,
      "%"
    );
    const parentids = selectedIdsWithParentString.split("%");
    parentids.pop();
    const currentId = parentids[parentids.length - 1];
    const curentIndex = parentids.length - 1;
    if (flatenedNodes[currentId]) {
      parentObjToBeSubmitted.nodeId = flatenedNodes[currentId].nodeId;
      parentObjToBeSubmitted.name = flatenedNodes[currentId].displayName;
      parentObjToBeSubmitted.nodeTypeId = flatenedNodes[currentId].nodeTypeId;
      parentObjToBeSubmitted.nodeTypeName =
        flatenedNodes[currentId].nodeTypeName;
      if (curentIndex === 0) {
        parentObjToBeSubmitted.parent = null;
      } else {
        parentObjToBeSubmitted.parent = getParentObjEach(
          [...parentids],
          curentIndex - 1
        );
      }
    }
    return { ...parentObjToBeSubmitted };
  };
  const getParentObjEach = (parentids: string[], newIndex: number) => {
    const nextParentObj = {} as browseTypes.IParentObj;
    const currentId = parentids[newIndex];
    if (flatenedNodes[currentId]) {
      nextParentObj.nodeId = flatenedNodes[currentId].nodeId;
      nextParentObj.name = flatenedNodes[currentId].displayName;
      nextParentObj.nodeTypeId = flatenedNodes[currentId].nodeTypeId;
      nextParentObj.nodeTypeName = flatenedNodes[currentId].nodeTypeName;
      nextParentObj.parent =
        newIndex === 0 ? null : getParentObjEach([...parentids], newIndex - 1);
      return { ...nextParentObj };
    } else {
      return null;
    }
  };
  const desiredTypeToBeShown =
    !enableDesiredType &&
      detectedAbilityType &&
      detectedAbilityType !== "Undefined"
      ? detectedAbilityType
      : `${browseState.desiredType}`;
  const formattedInputParams = formatSelectedMethodData(
    browseState.currentlyViewingMethod.rawData &&
      browseState.currentlyViewingMethod.rawData.inputArguments
      ? [...browseState.currentlyViewingMethod.rawData.inputArguments]
      : [],
    "input"
  );
  const formattedOutputParams = formatSelectedMethodData(
    browseState.currentlyViewingMethod.rawData &&
      browseState.currentlyViewingMethod.rawData.outputArguments
      ? [...browseState.currentlyViewingMethod.rawData.outputArguments]
      : [],
    "output"
  );
  return (
    <div className="right-pannel-view">
      <div className="warning-dialog-wrap">
        <Dialog
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={false}
          dimBackground={browseState.showDesiredTypeModal ? true : false}
          isOpen={browseState.showDesiredTypeModal ? true : false}
          onClose={() => {
            dispatch(browseActions.hideDesiredTypeModal());
          }}
          title={`Create a new type ${browseState.desiredType} with the selected variables ?`}
        >
          <div>{browseState.desiredTypeModalMessage}</div>
          <div className="submit-cancel-warning-wrap">
            <Button
              sizeClass="medium"
              type="normal"
              className="btn-cancel-desired-type-modal"
              onClick={() => {
                dispatch(browseActions.hideDesiredTypeModal());
              }}
              text={"cancel"}
            />
            <Button
              sizeClass="medium"
              type="primary-blue"
              className="btn-confirm-desired-type-modal"
              onClick={() => {
                addDataBindingClick();
                dispatch(browseActions.hideDesiredTypeModal());
              }}
              text={"ok"}
            />
          </div>
        </Dialog>
      </div>
      {browseState.showNodeDetails ? (
        <Dialog
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={false}
          dimBackground={true}
          isOpen={true}
          onClose={() => dispatch(browseActions.handleViewNodeDetails(false))}
          title="Node Details"
          className="node-details-view"
        >
          <div className="each-row-node-details">
            <div className="each-column-node-details-modal-header">
              OPC UA Type:
            </div>
            <div className="each-column-node-details">
              {selectedNode.nodeClass}
            </div>
          </div>
          <div className="each-row-node-details">
            <div className="each-column-node-details-modal-header">NodeId:</div>
            <div className="each-column-node-details">
              {selectedNode.nodeId}
            </div>
          </div>
          <div className="each-row-node-details">
            <div className="each-column-node-details-modal-header">
              Detected Ability Type:
            </div>
            <div className="each-column-node-details">
              {getDetectedAbilityType()}
            </div>
          </div>
          <div className="each-row-node-details">
            <div className="each-column-node-details-header">
              Object Existing As:
            </div>
            <div className="each-column-node-details">
              {getObjectExistingAs()}
            </div>
          </div>
        </Dialog>
      ) : (
        <div />
      )}
      <div className="right-pannel-title">
        <div className="right-pannel-title-label">
          {selectedNode.displayName ? selectedNode.displayName : ""}
        </div>
        <Button
          icon="abb/information-circle-1"
          onClick={() => dispatch(browseActions.handleViewNodeDetails(true))}
          type="discreet-black"
        />
      </div>
      <hr />
      <div className="node_form_body">
        <div>
          <div className="attributes">
            <div className="form-inputs-wrap">
              <div className="each-row-node-details each-row-form">
                <div className="each-column-node-details-header">
                  Desired Type:
                </div>
                <div className="each-column-node-details">
                  <Input
                    dataType="text"
                    className="node-form-text"
                    onValueChange={(value: string) => {
                      dispatch(browseActions.handleDesiredTypeChange(value));
                    }}
                    disabled={!enableDesiredType}
                    value={desiredTypeToBeShown}
                  />
                </div>
                <div
                  className={
                    desiredTypeToBeShown !== ""
                      ? "desired-type-copy-icon-wrap"
                      : "desired-type-copy-icon-wrap disabled-view"
                  }
                  title="Copy to clipboard"
                  onClick={() => handleDesiredTypeCopy(desiredTypeToBeShown)}
                >
                  <Icon name="abb/copy" sizeClass="small" />
                </div>
              </div>

              <div className="each-row-node-details each-row-form">
                <div className="each-column-node-details-header">
                  DataBinding Name:
                </div>
                <div className="each-column-node-details">
                  <Input
                    dataType="text"
                    onValueChange={(value: string) => {
                      dispatch(browseActions.handleDisplayNameChange(value));
                    }}
                    disabled={selectedNode.nodeClass === "UAMethod"}
                    value={
                      browseState.currentDisplayName &&
                        browseState.currentDisplayName !== ""
                        ? browseState.currentDisplayName
                        : selectedNode.displayName
                    }
                  />
                </div>
              </div>
              <div className="each-row-node-details each-row-form">
                <div className="each-column-node-details-header">
                  Sampling Interval:
                </div>
                <div className="each-column-node-details">
                  <Input
                    dataType="text"
                    className="node-form-text"
                    onValueChange={(value: string) => {
                      setIsValid(true);
                      handleSamplingInterval(value)
                    }}
                    disabled={selectedNode.nodeClass === "UAMethod"}
                    value={`${browseState.currentSamplingInterval}`}
                    instantValidation={true}
                    showValidationBarWhenInvalid={true}
                    showValidationIconWhenInvalid={true}
                    validator={(value) => {
                      const valid = Number.isSafeInteger(Number(value)) && Number(value) <= ((2 ** 32) / 2) - 1;
                      setIsValid(valid);
                      return { valid, text: "Not a valid integer" }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              !isDesiredTypeValid()
                ? "type-invalid-message"
                : "type-invalid-message disabled-desiredType-hidden"
            }
          >
            {`${typeInfoValidationErrorMessage}`}
          </div>
          <div className="variables-view-wrap">{variablesTableCommon()}</div>
          <div className="methods-view-wrap">{methodsTableCommon()}</div>
          <div className="node-form-buttons">
            <div className="node-form-button-container">
              <Button
                text="Cancel"
                className="node-form-cancel"
                type="normal"
                sizeClass="small"
                onClick={() => dispatch(browseActions.cancelForm())}
              />
              <Button
                text={
                  originalObjVariables.length > 0 ||
                    originalObjMethods.length > 0
                    ? "Modify"
                    : "Activate"
                }
                type="primary-blue"
                className="node-form-submit"
                sizeClass="small"
                onClick={() => onClickCollect()}
                disabled={
                  !isValid ||
                  disableCollectButton() ||
                  !isDesiredTypeValid() ||
                  truncatedSamplingInterval === "" ||
                  currentObjVariables.length > 150 ||
                  selectedNode.nodeClass === "UAMethod"
                }
              />
            </div>
          </div>
          <div
            className={
              currentObjVariables.length > 150
                ? "collect-disable-message-view"
                : "collect-disable-message-view disabled-message-hidden"
            }
          >{`User can select a maximum of 150 variables. ( Currently selected : ${currentObjVariables.length} )`}</div>
        </div>
        {browseState.showInputOutputParametersDialog ? (
          <div>
            <Dialog
              showCloseButton={true}
              closeOnEscape={true}
              closeOnLostFocus={false}
              dimBackground={true}
              isOpen={true}
              onClose={() =>
                dispatch(browseActions.hideInputOutputParameters())
              }
              title="Input Output Parameters"
              className="input-output-params-modal"
            >
              <div className="input-output-params-view">
                <div className="unsupported-message">
                  {browseState.currentlyViewingMethod &&
                    browseState.currentlyViewingMethod.rawData &&
                    browseState.currentlyViewingMethod.rawData.errors ? (
                    <div className="method-error-unsupported">
                      <div className="method-info-warning-icon">
                        <Icon name="abb/warning-triangle" />
                      </div>
                      {browseState.currentlyViewingMethod.rawData.errors}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-params-table-wrap">
                  <BrowseGrid
                    data={[...formattedInputParams]}
                    header={[...inputParamsHeader]}
                    noDataText=""
                    minWidth={
                      document.documentElement.clientWidth -
                      variableGridFixedWidth
                    }
                    className="input-params-view"
                    resizeId="input-table-resize"
                    key={props.key ? props.key : ""}
                  />
                </div>
                <div className="output-params-table-wrap">
                  <BrowseGrid
                    data={[...formattedOutputParams]}
                    header={[...outputParamsHeader]}
                    noDataText=""
                    minWidth={
                      document.documentElement.clientWidth -
                      variableGridFixedWidth
                    }
                    className="output-params-view"
                    resizeId="output-table-resize"
                    key={props.key ? props.key : ""}
                  />
                </div>
              </div>
            </Dialog>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default withRouter(BrowseRightPannel);
