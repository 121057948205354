import React, { useEffect, useRef } from "react";
import "../Grid/Grid.css";
import { Button, Icon, LoadingIndicator } from "@abb/abb-common-ux-react";
import * as serversTypes from "../Type/TypeServers";
import * as commonTypes from "../../../Common/Type/TypeCommon";
import _ from "underscore";

const Id = "id";
const serverName = "name";
const edgeName = "edgeName";
const edgeId = "edgeId";
const status = "configureStatusMessage";
const serverDescription = "serverDescription";

interface IStatusColumnViewProps {
  type: "edge" | "server";
  statusArray: string[];
  statusCodeArray?: number[];
}
const StatusColumnView = (props: IStatusColumnViewProps) => {
  const [showStatusPopup, handleStatusPopup] = React.useState(false);
  const wrapperRef = useRef(null);
  let statusView: JSX.Element[] = [];
  if (props.type === "edge") {
    let readyCount = 0,
      totalCount = 0;
    props.statusArray.map((item) => {
      totalCount++;
      if (item === "Ready") {
        readyCount++;
      }
    });
    if (readyCount > 0) {
      statusView.push(
        <div
          className={
            readyCount > 0
              ? "server-status-label ready-view"
              : "server-status-label"
          }
        ></div>
      );
    }
  }
  if (props.type === "server") {
    const detailedStatus = props.statusArray[0];
    let groupedStatus = "";
    if (detailedStatus === "Ready") {
      groupedStatus = "Connected";
    } else if (
      props.statusCodeArray &&
      ([0, 8].indexOf(props.statusCodeArray[0]) >= 0 ||
        props.statusCodeArray[0] === null)
    ) {
      groupedStatus = "Not Connected";
    } else {
      groupedStatus = "Connecting";
    }
    statusView.push(
      <div
        className={
          groupedStatus === "Connected"
            ? "server-status-label ready-view"
            : "server-status-label"
        }
      >
        {groupedStatus === "Connected" ? (
          <div className="status-icon-wrap">
            <Icon name="abb/connect" sizeClass="medium" />
          </div>
        ) : groupedStatus === "Not Connected" ? (
          <div className="status-icon-wrap">
            <Icon name="abb/disconnect" sizeClass="medium" />
          </div>
        ) : (
          <div className="status-icon-wrap">
            <LoadingIndicator
              sizeClass="small"
              type="radial"
              color="grey"
              determinate={true}
            />
          </div>
        )}
        <span>{groupedStatus}</span>
        {groupedStatus !== "Connected" && props.type === "server" ? (
          <div className="action-icon-wrap">
            <div
              onMouseOver={(e) => {
                e.stopPropagation();
                handleStatusPopup(true);
              }}
              onMouseOut={(e) => {
                e.stopPropagation();
                handleStatusPopup(false);
              }}
            >
              <Button
                icon="abb/information-circle-1"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="server-status-info"
                type="discreet-black"
              />
            </div>
            {showStatusPopup ? (
              <React.Fragment>
                <div
                  className="pop-up-wrap-status"
                  ref={wrapperRef}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="pop-up-arrow-status" />
                  <div className="pop-up-view-status">
                    <div className="pop-up-view-status-header">
                      {groupedStatus === "Connecting"
                        ? "Connecting To Server"
                        : "Server Not Connected"}
                    </div>
                    <div className="pop-up-view-status-details">
                      {detailedStatus}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
  return <div className="status-view-wrap">{statusView}</div>;
};

export const viewByEdgesGridHeader: serversTypes.ITableHeaderObj[] = [
  {
    id: "serverlocation",
    displayName: "SERVER LOCATION",
    className: "users-list-header",
    isSortable: false,
  },
  {
    id: "serverstatus",
    displayName: "STATUS",
    className: "users-list-header",
    isSortable: false,
  },
  {
    id: "serverdescription",
    displayName: "DESCRIPTION",
    className: "users-list-header",
    isSortable: false,
  },
];

export const viewByserversGridHeaders: serversTypes.ITableHeaderObj[] = [
  {
    id: "servername",
    displayName: "SERVER NAME",
    className: "users-list-header",
    isSortable: true,
  },
  {
    id: "hostingedge",
    displayName: "HOSTING EDGE",
    className: "users-list-header",
    isSortable: true,
  },
  {
    id: "serverstatus",
    displayName: "STATUS",
    className: "users-list-header",
    isSortable: true,
  },
  {
    id: "serverdescription",
    displayName: "DESCRIPTION",
    className: "users-list-header",
    isSortable: true,
  },
];

export const formatViewByEdgesDataData = (
  rawData: serversTypes.IRawServersObj[]
) => {
  let formattedData: serversTypes.IServersGridRowObj[] = [];
  const keysToBeMapped = [
    "serverlocation",
    "serverstatus",
    "serverdescription",
  ];
  const groupedData = _.groupBy(rawData, edgeId);
  Object.keys(groupedData).map((key, keyIndex) => {
    const eachSetData: serversTypes.IServersGridRowObj[] = [];
    const eachEdgeRowObj: serversTypes.IServersGridRowObj = {
      id: `%${key}%`,
      displayName: `${groupedData[key][0][edgeName]}`,
      level: 1,
      isExpandable: true,
      isExpanded: false,
      isVisible: true,
      isClickable: false,
      rowType: "edge",
      isDeleteActionEnabled: false,
      isViewOptionsActionEnabled: true,
      data: [],
    };
    eachEdgeRowObj.data[0] = {
      id: "serverlocation",
      rawVal: `${
        groupedData[key][0][edgeName] ? groupedData[key][0][edgeName] : ""
      }`,
      formattedVal: `${
        groupedData[key][0][edgeName] ? groupedData[key][0][edgeName] : ""
      }`,
    };
    const edgeStatusArray: string[] = [];
    groupedData[key].map((eachServer) => {
      edgeStatusArray.push(eachServer[status] ? eachServer[status] : "");
      const eachServerRowObj: serversTypes.IServersGridRowObj = {
        id: `%${key}%%${eachServer[Id]}%`,
        displayName: `${eachServer[serverName]}`,
        level: 2,
        isExpandable: false,
        isExpanded: false,
        isVisible: false,
        isClickable: true,
        isBrowseDisabled: isBrowsingAllowed(
          eachServer.configureState,
          eachServer.lastConfiguredTimestamp
        )
          ? false
          : true,
        rowType: "server",
        isDeleteActionEnabled: true,
        isViewOptionsActionEnabled: true,
        additionalInfo: { edgeId: `${groupedData[key][0][edgeId]}` },
        data: [],
        rawData: { ...eachServer },
      };
      eachServerRowObj.data[0] = {
        id: "serverlocation",
        rawVal: `${eachServer[serverName] ? eachServer[serverName] : ""}`,
        formattedVal: `${eachServer[serverName] ? eachServer[serverName] : ""}`,
      };
      eachServerRowObj.data[1] = {
        id: "serverstatus",
        rawVal: [`${eachServer[status] ? eachServer[status] : ""}`],
        formattedVal: (
          <StatusColumnView
            type="server"
            statusArray={[`${eachServer[status] ? eachServer[status] : ""}`]}
            statusCodeArray={[eachServer["configureState"]]}
          />
        ),
      };
      eachServerRowObj.data[2] = {
        id: "serverdescription",
        rawVal: `${
          eachServer[serverDescription] ? eachServer[serverDescription] : ""
        }`,
        formattedVal: `${
          eachServer[serverDescription] ? eachServer[serverDescription] : ""
        }`,
      };
      eachSetData.push({ ...eachServerRowObj });
    });
    eachEdgeRowObj.data[1] = {
      id: "serverstatus",
      rawVal: [...edgeStatusArray],
      formattedVal: (
        <StatusColumnView
          type="edge"
          statusArray={[...edgeStatusArray]}
          statusCodeArray={[]}
        />
      ),
    };
    eachSetData.unshift({ ...eachEdgeRowObj });
    formattedData = [...formattedData, ...eachSetData];
  });
  return formattedData;
};

export const formatEdgeData = (
  rawData: serversTypes.IEachEdgeObj[]
): commonTypes.IEdgeDropDownData[] => {
  const formattedEdgeDropdownData: commonTypes.IEdgeDropDownData[] = [];
  rawData.map((item, index) => {
    let formattedObj = {} as commonTypes.IEdgeDropDownData;
    formattedObj.value = item.id;
    formattedObj.label = item.name;
    formattedEdgeDropdownData.push({ ...formattedObj });
  });
  return formattedEdgeDropdownData;
};

export const formatViewByServersData = (
  rawData: serversTypes.IRawServersObj[]
) => {
  let formattedData: serversTypes.IServersGridRowObj[] = [];
  const keysToBeMapped = [
    "servername",
    "hostingedge",
    "serverstatus",
    "serverdescription",
  ];
  const keyMapping: { [key: string]: string } = {
    servername: serverName,
    hostingedge: edgeName,
    serverstatus: status,
    serverdescription: serverDescription,
  };
  const rawDataCopy = [...rawData];
  rawDataCopy.map((item, index) => {
    const eachRowObj: serversTypes.IServersGridRowObj = {
      id: `%${item[Id]}%`,
      displayName: `${item[serverName]}`,
      level: 1,
      isExpandable: false,
      isExpanded: false,
      isVisible: true,
      isClickable: true,
      isBrowseDisabled: isBrowsingAllowed(
        item.configureState,
        item.lastConfiguredTimestamp
      )
        ? false
        : true,
      isDeleteActionEnabled: true,
      isViewOptionsActionEnabled: true,
      additionalInfo: { edgeId: `${item[edgeId]}` },
      data: [],
      rawData: { ...item },
    };
    keysToBeMapped.map((key) => {
      const dataObj: serversTypes.IEachCellObj = {
        id: "",
        rawVal: "",
        formattedVal: "",
      };
      dataObj.id = key;
      dataObj.rawVal = item[keyMapping[key]];
      if (key === "serverstatus") {
        dataObj.formattedVal = (
          <StatusColumnView
            type="server"
            statusArray={[
              item[keyMapping[key]] ? `${item[keyMapping[key]]}` : "",
            ]}
            statusCodeArray={[item["configureState"]]}
          />
        );
      } else {
        dataObj.formattedVal = item[keyMapping[key]];
      }
      eachRowObj.data.push({ ...dataObj });
    });
    formattedData.push({ ...eachRowObj });
  });

  return formattedData;
};
export const isBrowsingAllowed = (
  configureState: number | undefined,
  lastConfiguredTime: string | undefined | null
) => {
  if (
    configureState === 7 ||
    (configureState === 8 && lastConfiguredTime !== null)
  ) {
    return true;
  } else {
    return false;
  }
};
