import React, { useEffect } from "react";
import "./Browse.css";
import {
  AppHeader,
  AppMainContent,
  Button,
  Icon,
  ToggleButton,
  ToggleButtonGroup,
} from "@abb/abb-common-ux-react";
import BrowseRightPannel from "./BrowseRightPannel/BrowseRightPannel";
import Table from "./BrowseGrid/BrowseGrid";
import OPCtree from "../../Common/Components/Tree/Tree";
import CommonLoader from "../../Common/Components/CommonLoader/CommonLoader";
import {
  configuredDataHeaders,
  enabledMethodDataHeaders,
} from "./DataFormatter/BrowseDataFormatter";
import * as browseActions from "./Action/ActionBrowse";
import * as serverActions from "../Servers/Action/ActionServers";
import * as commonActions from "../../Common/Action/ActionCommon";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../Reducer";
import * as browseTypes from "./Type/TypeBrowse";
import {
  availableTypes,
  configuredDataGridFixedWidth,
} from "../../Utils/Constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "underscore";
import { envSettings } from "../../EnviornmentSettings/Settings";

let resizeCount = 0;
const BrowseView = (props: RouteComponentProps) => {
  const [resizeCountState, handleResieCount] = React.useState(0);
  const browseState = useSelector((state: IState) => state.browseState);
  const serversState = useSelector((state: IState) => state.serversState);
  const commonState = useSelector((state: IState) => state.commonState);
  const { dataBindingResponse } = { ...browseState };
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(commonActions.getPreference());
    const splittedRoute = props.history.location.search.split("&&");
    const uaModuleId = splittedRoute && splittedRoute[3];
    const serverId = splittedRoute && splittedRoute[5];
    const edgeId = splittedRoute && splittedRoute[1];
    dispatch(browseActions.getBrowseTreeData(uaModuleId, serverId, edgeId));
    dispatch(browseActions.getConfiguredData(uaModuleId, serverId, edgeId));
    dispatch(browseActions.getEnabledMethodData(uaModuleId, serverId, edgeId));
    dispatch(
      browseActions.getSelectedServerForBrowse(serverId, uaModuleId, edgeId)
    );
    dispatch(serverActions.getEdgeList());
  }, []);
  useEffect(() => {
    (window as any).visualViewport!.addEventListener("resize", viewportHandler);
    return () => {
      (window as any).visualViewport!.removeEventListener(
        "resize",
        viewportHandler
      );
    };
  }, []);
  function viewportHandler(event: any) {
    console.log(
      "check resize execution",
      window.screen.width,
      document.documentElement.clientWidth
    );
    resizeCount = resizeCount + 1;
    handleResieCount(resizeCount);
  }
  const handleExpandCollapseNode = (
    event: any,
    clickedObj: browseTypes.IOpcTreeDataObj
  ) => {
    event.stopPropagation();
    if (clickedObj.isExpandable) {
      handleExpandCollapse({ ...clickedObj });
    }
  };
  const handleExpandCollapse = (clickedNode: browseTypes.IOpcTreeDataObj) => {
    const updatedData = [...browseState.formattedTreeNodes];
    updatedData.map((item) => {
      if (item.id === clickedNode.id) {
        item.isExpanded = !item.isExpanded;
      }
    });
    if (clickedNode.isExpanded) {
      updatedData.map((item) => {
        if (
          item["id"].indexOf(clickedNode.id) >= 0 &&
          item.level > clickedNode.level
        ) {
          item.isExpanded = false;
          item.isVisible = false;
        }
      });
    } else {
      updatedData.map((item) => {
        if (
          item.id.indexOf(clickedNode.id) >= 0 &&
          item.level === clickedNode.level + 1
        ) {
          item.isExpanded = false;
          item.isVisible = true;
        }
        if (browseState.isNodeAutoClose) {
          if (
            clickedNode["id"].indexOf(item.id) < 0 &&
            item.id.indexOf(clickedNode.id) < 0 &&
            clickedNode.id !== item.id
          ) {
            item.isExpanded = false;
            if (item.level > clickedNode.level) {
              item.isVisible = false;
            }
          }
        }
      });
    }
    dispatch(browseActions.handleTreeExpandColapse([...updatedData]));
  };
  const clickedNode = (event: any, clickedObj: browseTypes.IOpcTreeDataObj) => {
    event.stopPropagation();
    if (clickedObj.rawData.nodeId !== browseState.selectedNodeId) {
      dispatch(browseActions.onNodeSelect({ ...clickedObj.rawData }));
      if (
        browseState.flatenedNodes[clickedObj.rawData.nodeId].childNodes &&
        browseState.flatenedNodes[clickedObj.rawData.nodeId].childNodes.some(
          (child: any) => child.nodeClass === availableTypes.uaVariable
        )
      ) {
        dispatch(
          browseActions.getDataBindingResponse(browseState.serverId, {
            ...clickedObj.rawData,
          })
        );
      }
    }
  };
  const selectedEdge = serversState.edgeList.filter(
    (item) => item.id === browseState.edgeId
  );
  const handleCheckBoxClick = (item: browseTypes.IEachRowObj) => {
    const selectedConfiguredIds = browseState.selectedConfiguredDataIds;
    if (!selectedConfiguredIds.includes(item.id)) {
      selectedConfiguredIds.push(item.id);
    } else {
      const currentIndex = selectedConfiguredIds.indexOf(item.id);
      selectedConfiguredIds.splice(currentIndex, 1);
    }
    dispatch(
      browseActions.selectEachConfiguredData([...selectedConfiguredIds])
    );
  };
  const handleCheckBoxSelectAll = (isSelectAll: boolean) => {
    let selectedConfiguredIds: string[] = [];
    if (isSelectAll) {
      selectedConfiguredIds = browseState.formattedConfiguredData.map(
        (item) => item.id
      );
    } else {
      selectedConfiguredIds = [];
    }
    dispatch(
      browseActions.selectEachConfiguredData([...selectedConfiguredIds])
    );
  };
  const handleSort = (
    sortColumnId: string,
    sortDirection: string | undefined
  ) => {
    let newDirection: "asc" | "desc" = "desc";
    if (sortColumnId === browseState.sortColumnId) {
      newDirection = sortDirection !== "asc" ? "asc" : "desc";
    }
    dispatch(browseActions.sortConfiguredData(newDirection, sortColumnId));
  };

  const handleCheckBoxClickEnabledMethod = (item: browseTypes.IEachRowObj) => {
    const selectedEnabledMethodIds = [
      ...browseState.selectedEnabledMethodDataIds,
    ];
    if (!selectedEnabledMethodIds.includes(item.id)) {
      selectedEnabledMethodIds.push(item.id);
    } else {
      const currentIndex = selectedEnabledMethodIds.indexOf(item.id);
      selectedEnabledMethodIds.splice(currentIndex, 1);
    }
    dispatch(
      browseActions.selectEachEnabledMethodData([...selectedEnabledMethodIds])
    );
  };
  const handleCheckBoxSelectAllEnabledMethod = (isSelectAll: boolean) => {
    let selectedEnabledMethodIds: string[] = [];
    if (isSelectAll) {
      selectedEnabledMethodIds = browseState.formattedEnabledMethodData.map(
        (item) => item.id
      );
    } else {
      selectedEnabledMethodIds = [];
    }
    dispatch(
      browseActions.selectEachEnabledMethodData([...selectedEnabledMethodIds])
    );
  };
  const handleSortEnabledMethod = (
    sortColumnId: string,
    sortDirection: string | undefined
  ) => {
    let newDirection: "asc" | "desc" = "desc";
    if (sortColumnId === browseState.enabledMethodsortColumnId) {
      newDirection = sortDirection !== "asc" ? "asc" : "desc";
    }
    dispatch(browseActions.sortEnabledMethodata(newDirection, sortColumnId));
  };
  const getSortedConfiguredData = (unSortedData: browseTypes.IEachRowObj[]) => {
    let newDirection: "desc" | "asc" = browseState.sortDirection;
    let sortColId = browseState.sortColumnId;
    let colIdIndex = 0;
    configuredDataHeaders.map((item, index) => {
      if (item.id === sortColId) {
        colIdIndex = index;
      }
    });
    const currData = [...browseState.formattedConfiguredData];
    currData.sort((a: any, b: any) => {
      let x = `${
        a.data[colIdIndex].rawVal ? a.data[colIdIndex].rawVal : ""
      }`.toLowerCase();
      let y = `${
        b.data[colIdIndex].rawVal ? b.data[colIdIndex].rawVal : ""
      }`.toLowerCase();
      if (newDirection === "desc") {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      } else if (newDirection === "asc") {
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
      }
      return 0;
    });
    return currData;
  };
  const getSortedEnabledMethodData = (
    unSortedData: browseTypes.IEachRowObj[]
  ) => {
    let newDirection: "desc" | "asc" = browseState.enabledMethodsortDirection;
    let sortColId = browseState.enabledMethodsortColumnId;
    let colIdIndex = 0;
    enabledMethodDataHeaders.map((item, index) => {
      if (item.id === sortColId) {
        colIdIndex = index;
      }
    });
    const currData = [...browseState.formattedEnabledMethodData];
    currData.sort((a: any, b: any) => {
      let x = `${
        a.data[colIdIndex].rawVal ? a.data[colIdIndex].rawVal : ""
      }`.toLowerCase();
      let y = `${
        b.data[colIdIndex].rawVal ? b.data[colIdIndex].rawVal : ""
      }`.toLowerCase();
      if (newDirection === "desc") {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      } else if (newDirection === "asc") {
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
      }
      return 0;
    });
    return currData;
  };
  const handlePublish = (isPublish: boolean) => {
    const configuredDataToBeUpdated: browseTypes.IConfiguredDataBinding[] = [];
    if (isPublish) {
      browseState.rawConfiguredData.map((item) => {
        let newObj = {} as browseTypes.IConfiguredDataBinding;
        if (
          browseState.selectedConfiguredDataIds.indexOf(item.id) >= 0 &&
          !item.isActive
        ) {
          newObj = {
            ...item,
            isActive: true,
          };
          configuredDataToBeUpdated.push({ ...newObj });
        }
      });
    } else {
      browseState.rawConfiguredData.map((item) => {
        if (
          browseState.selectedConfiguredDataIds.indexOf(item.id) >= 0 &&
          item.isActive
        )
          configuredDataToBeUpdated.push({ ...item, isActive: false });
      });
    }
    dispatch(
      browseActions.updateConfiguredData(
        [...configuredDataToBeUpdated],
        browseState.serverId,
        browseState.uaModuleId,
        browseState.edgeId,
        { ...browseState.selectedNode }
      )
    );
  };
  const handleDelete = () => {
    const configuredDattToBeDeleted: browseTypes.IConfiguredDataBinding[] = [];
    browseState.rawConfiguredData.map((item) => {
      if (browseState.selectedConfiguredDataIds.indexOf(item.id) >= 0)
        configuredDattToBeDeleted.push({ ...item });
    });
    dispatch(
      browseActions.deleteConfiguredData(
        [...configuredDattToBeDeleted],
        browseState.serverId,
        browseState.uaModuleId,
        browseState.edgeId,
        { ...browseState.selectedNode }
      )
    );
  };
  let isPublish = false;
  if (browseState.selectedConfiguredDataIds.length === 0) {
    isPublish = true;
  } else {
    const groupedConfiguredData = _.groupBy(
      [...browseState.rawConfiguredData],
      "id"
    );
    browseState.selectedConfiguredDataIds.map((item) => {
      if (
        groupedConfiguredData[item] &&
        groupedConfiguredData[item][0].isActive !== true
      ) {
        isPublish = true;
      }
    });
  }
  let isPause = false;
  if (browseState.selectedConfiguredDataIds.length !== 0) {
    const groupedConfiguredData = _.groupBy(
      [...browseState.rawConfiguredData],
      "id"
    );
    browseState.selectedConfiguredDataIds.map((item) => {
      if (
        groupedConfiguredData[item] &&
        groupedConfiguredData[item][0].isActive === true
      ) {
        isPause = true;
      }
    });
  }
  const handleDisableMethods = () => {
    const selectedEnabledMethodIds = [
      ...browseState.selectedEnabledMethodDataIds,
    ];
    const selectedMethodsToDisable: browseTypes.IFlattenedEnabledMethodObj[] =
      [];
    const requestPayload: browseTypes.IDisableMethodsPayload[] = [];
    browseState.flattenedEnabledMethodData.map((item) => {
      if (
        selectedEnabledMethodIds &&
        item.nodeId &&
        selectedEnabledMethodIds.indexOf(item.nodeId) >= 0
      ) {
        selectedMethodsToDisable.push({ ...item });
      }
    });
    const groupedMethodsToBeDisabled = _.groupBy(
      selectedMethodsToDisable,
      "objectId"
    );
    Object.keys(groupedMethodsToBeDisabled!).map((eachKey) => {
      const methodsToDeactivate: browseTypes.IMethodsToDeactivate[] = [];
      groupedMethodsToBeDisabled[eachKey] &&
        groupedMethodsToBeDisabled[eachKey].map((item) => {
          methodsToDeactivate.push({
            name: item.methodName,
            nodeId: item.nodeId,
          });
        });
      const requestPayloadEachObj = {
        id: eachKey,
        endpointId: browseState.serverId,
        methods: [...methodsToDeactivate],
      };
      requestPayload.push({ ...requestPayloadEachObj });
    });

    dispatch(
      browseActions.disableMethods(
        [...requestPayload],
        browseState.serverId,
        browseState.uaModuleId,
        browseState.edgeId,
        { ...browseState.selectedNode }
      )
    );
  };

  return (
    <React.Fragment>
      {commonState.isLoading ||
      browseState.isLoading ||
      serversState.isLoading ||
      browseState.isBrowseDataLoading ||
      browseState.isConfiguredDataLoading ||
      browseState.isDataBindingLoading ? (
        <CommonLoader />
      ) : (
        <div />
      )}
      {browseState.isLoading ||
      serversState.isLoading ||
      browseState.isBrowseDataLoading ||
      browseState.isConfiguredDataLoading ||
      browseState.isDataBindingLoading ? (
        <div className="loader-mask" />
      ) : (
        <div />
      )}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent className="browse-wrap">
        <div className="browse-top-pannel">
          {serversState.isLoading ? (
            <div className="browse-top-left"></div>
          ) : (
            <div className="browse-top-left">
              <div
                className="bread-crumb-cell bread-crumb-server"
                onClick={() => {
                  browseActions.resetBrowseState();
                  props.history.push("/");
                }}
              >{`OPC Servers`}</div>
              <div className="bread-crumb-devider">/</div>
              <div className="bread-crumb-cell">
                {selectedEdge.length > 0 ? selectedEdge[0].name : ""}
              </div>
              <div className="bread-crumb-devider">/</div>
              <div className="bread-crumb-cell">
                {browseState.selectedServer.name
                  ? browseState.selectedServer.name
                  : ""}
              </div>
            </div>
          )}
          <div className="browse-top-middle">
            <div className="browse-end-point-url-label">End Point Url</div>
            <div className="browse-end-point-url-value">
              {browseState.selectedServer.endpointUrl
                ? browseState.selectedServer.endpointUrl
                : ""}
            </div>
          </div>
          <div className="browse-top-end">
            <ToggleButtonGroup
              selected={browseState.showConfiguredDataPage ? [1] : [0]}
              onChange={(value) =>
                dispatch(
                  browseActions.handleShowConfiguredData(
                    value[0] === 0 ? false : true
                  )
                )
              }
              sizeClass="small"
              multiselect={false}
            >
              <ToggleButton text="Browse Server" />
              <ToggleButton text="Configured Data" />
            </ToggleButtonGroup>
          </div>
        </div>
        <div
          className={
            commonState.theme === "light"
              ? `browse-body light-theme`
              : `browse-body dark-theme`
          }
        >
          {!browseState.showConfiguredDataPage ? (
            <React.Fragment>
              <div className="browse-tree-wrap">
                {browseState.formattedTreeNodes.length > 0 ? (
                  <OPCtree
                    data={[...browseState.formattedTreeNodes]}
                    selectedNodeId={browseState.selectedNodeId}
                    handleExpandCollapse={handleExpandCollapseNode}
                    handleNodeSelection={clickedNode}
                    isDisabled={browseState.isLoading}
                  />
                ) : (
                  <div className="browse-tree-empty" />
                )}
              </div>

              <div className="browse-right-pannel">
                {browseState.typeVersion === 1 ? (
                  <div className="browse-right-pannel-mask">
                    <div className="right-pannel-mask-messege">
                      <div className="right-pannel-warning-icon">
                        <Icon
                          name="abb/information-circle-1"
                          // className="accordion-icon"
                        ></Icon>
                      </div>
                      <div>
                        {`OPC UA Configuration Utility updated with support to
                        visualize, activate and deactivate Methods from OPC UA
                        Server. It is Mandatory to remove configured object from
                        "Configured Data" page and perform "Upload Nodesets"
                        from "Server settings" page, to be able to perform data
                        collection and method activation on this server.`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                {browseState.selectedNodeId &&
                browseState.selectedNodeId !== "" ? (
                  <div
                    className={
                      browseState.typeVersion === 1
                        ? "browse-right-pannel-contet browse-right-pannel-contet-disabled"
                        : "browse-right-pannel-contet"
                    }
                  >
                    <BrowseRightPannel
                      key={`browse-grid-${resizeCountState}`}
                    />
                  </div>
                ) : (
                  <div>Select a node to view details</div>
                )}
              </div>
            </React.Fragment>
          ) : (
            <AppMainContent className="configured-data-view-wrap">
              <div
                className={
                  commonState.theme === "light"
                    ? `configured-data-container light-theme`
                    : `configured-data-container dark-theme`
                }
              >
                <div
                  className="configured-data-sub-view-header"
                  onClick={() =>
                    dispatch(
                      browseActions.handleShowConfiguredObjectsData(
                        browseState.showConfiguredObjects ? false : true
                      )
                    )
                  }
                >
                  <AppHeader className="configured-data-sub-view-header-container">
                    <div className="configured-data-sub-view-header-label">
                      Configured Objects
                    </div>
                    <div className="configured-data-sub-view-header-icon-wrap">
                      <Icon
                        name={
                          browseState.showConfiguredObjects
                            ? "abb/up"
                            : "abb/down"
                        }
                        className="accordion-icon"
                      />
                    </div>
                  </AppHeader>
                </div>
                {browseState.showConfiguredObjects ? (
                  <>
                    <div className="configured-data-buttons">
                      <Button
                        type="normal"
                        icon="abb/view"
                        text="Subscribe"
                        disabled={
                          !isPublish ||
                          browseState.selectedConfiguredDataIds.length === 0
                        }
                        onClick={() => handlePublish(isPublish)}
                      />
                      <Button
                        type="normal"
                        text="Pause Subscription"
                        icon="abb/pause"
                        disabled={
                          !isPause ||
                          browseState.selectedConfiguredDataIds.length === 0
                        }
                        onClick={() => handlePublish(false)}
                      />
                      <Button
                        type="normal"
                        icon="abb/hide"
                        text="Remove Subscription"
                        disabled={
                          browseState.selectedConfiguredDataIds.length === 0
                        }
                        onClick={() => handleDelete()}
                      />
                    </div>
                    <div className="configured-data-grid-wrap">
                      <Table
                        data={getSortedConfiguredData([
                          ...browseState.formattedConfiguredData,
                        ])}
                        header={[...configuredDataHeaders]}
                        noDataText=""
                        isCheckBoxEnabled={true}
                        showSelectAllOption={true}
                        isSelectedAll={
                          browseState.selectedConfiguredDataIds.length ===
                            browseState.formattedConfiguredData.length &&
                          browseState.formattedConfiguredData.length !== 0
                        }
                        isSelectAllDisabled={
                          browseState.formattedConfiguredData.length === 0
                        }
                        handleSelectAll={(isSelectedAll: boolean) =>
                          handleCheckBoxSelectAll(isSelectedAll)
                        }
                        onSort={(
                          id: string,
                          sortDirection: string | undefined
                        ) => handleSort(id, sortDirection)}
                        onCheckBoxClick={(item: browseTypes.IEachRowObj) =>
                          handleCheckBoxClick(item)
                        }
                        isSortEnabled={true}
                        sortedColumnId={browseState.sortColumnId}
                        sortDirection={browseState.sortDirection}
                        minWidth={
                          document.documentElement.clientWidth -
                          configuredDataGridFixedWidth
                        }
                        className="configured-data-grid-view"
                        key={`browse-grid-${resizeCountState}`}
                      />
                    </div>
                  </>
                ) : (
                  <div />
                )}
                <div
                  className="configured-data-sub-view-header"
                  onClick={() =>
                    dispatch(
                      browseActions.handleShowEnabledMethodsData(
                        browseState.showEnabledMethods ? false : true
                      )
                    )
                  }
                >
                  <AppHeader className="configured-data-sub-view-header-container">
                    <div className="configured-data-sub-view-header-label">
                      Enabled Methods
                    </div>
                    <div className="configured-data-sub-view-header-icon-wrap">
                      <Icon
                        name={
                          browseState.showEnabledMethods ? "abb/up" : "abb/down"
                        }
                        className="accordion-icon"
                      />
                    </div>
                  </AppHeader>
                </div>
                {browseState.showEnabledMethods ? (
                  <>
                    <div className="configured-data-buttons">
                      <Button
                        type="normal"
                        text="Disable"
                        disabled={
                          browseState.selectedEnabledMethodDataIds.length ===
                            0 || browseState.isLoading
                        }
                        onClick={() => handleDisableMethods()}
                      />
                    </div>
                    <div className="enabled-method-data-grid-wrap">
                      <Table
                        data={getSortedEnabledMethodData([
                          ...browseState.formattedEnabledMethodData,
                        ])}
                        header={[...enabledMethodDataHeaders]}
                        noDataText=""
                        isCheckBoxEnabled={true}
                        showSelectAllOption={true}
                        isSelectedAll={
                          browseState.selectedEnabledMethodDataIds.length ===
                            browseState.formattedEnabledMethodData.length &&
                          browseState.formattedEnabledMethodData.length !== 0
                        }
                        isSelectAllDisabled={
                          browseState.formattedEnabledMethodData.length === 0
                        }
                        handleSelectAll={(isSelectedAll: boolean) =>
                          handleCheckBoxSelectAllEnabledMethod(isSelectedAll)
                        }
                        onSort={(
                          id: string,
                          sortDirection: string | undefined
                        ) => handleSortEnabledMethod(id, sortDirection)}
                        onCheckBoxClick={(item: browseTypes.IEachRowObj) =>
                          handleCheckBoxClickEnabledMethod(item)
                        }
                        isSortEnabled={true}
                        sortedColumnId={browseState.enabledMethodsortColumnId}
                        sortDirection={browseState.enabledMethodsortDirection}
                        minWidth={
                          document.documentElement.clientWidth -
                          configuredDataGridFixedWidth
                        }
                        className="enabled-method-data-grid-view"
                        resizeId="enabled-method-grid"
                        key={`browse-grid-${resizeCountState}`}
                      />
                    </div>
                  </>
                ) : (
                  <div />
                )}
              </div>
            </AppMainContent>
          )}
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};
export default withRouter(BrowseView);
