import * as React from "react";
import "./Grid.css";
import {
  Button,
  Icon,
  LoadingIndicator,
  Table,
} from "@abb/abb-common-ux-react";
import * as serverTypes from "../Type/TypeServers";

let resizeNumber = 0;
const ServerGrid = (props: serverTypes.IServerGridProps) => {
  React.useEffect(() => {
    (window as any).visualViewport!.addEventListener("resize", viewportHandler);
    initialColumnSettings();
  }, [props.viewBy]);
  const initialColumnSettings = () => {
    const table = document.getElementById("resizeMe");
    const cols = table && table!.querySelectorAll("th");
    if (cols) {
      [].forEach.call(cols, function (col: any) {
        const resizer = document.createElement("div");
        resizer.classList.add("resizer");
        resizer.style.height = `${table!.offsetHeight}px`;
        col.style.width = `${document.documentElement.clientWidth / props.header.length + 500
          }px`;

        if (col.classList.contains("actions-header-column")) {
          col.style.width = "100px";
        }
        if (col.classList.contains("expand-header-column")) {
          col.style.width = "20px";
        }
        if (
          !col.classList.contains("actions-header-column") &&
          !col.classList.contains("expand-header-column")
        ) {
          col.appendChild(resizer);
        }
        createResizableColumn(table, col, resizer);
      });
    }
  };
  const createResizableColumn = function (table: any, col: any, resizer: any) {
    let x = 0;
    let w = 0;
    let tableW = 0;
    const mouseDownHandler = function (e: any) {
      x = e.clientX;
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);
      let tableWStyles = window.getComputedStyle(table);
      tableW = parseInt(tableWStyles.width, 10);
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };
    const mouseMoveHandler = function (e: any) {
      const dx = e.clientX - x;
      if (tableW + dx > props.minWidth!) {
        col.style.width = `${w + dx}px`;
        table.style.width = `${tableW + dx}px`;
      }
      e.stopPropagation();
    };
    const mouseUpHandler = function () {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };
    resizer.addEventListener("mousedown", mouseDownHandler);
  };
  const optionsWrapperRef = React.useRef(null);
  const useOutsideAlerter = (ref: any) => {
    React.useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.stopPropagation();
          props.onPopUpAction("closePopUp", { ...props.selectedRow });
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  React.useEffect(() => {
    return () => {
      (window as any).visualViewport!.addEventListener(
        "resize",
        viewportHandler
      );
    };
  });
  useOutsideAlerter(optionsWrapperRef);

  React.useEffect(() => {
    initialColumnSettings();
  }, [resizeNumber]);
  function viewportHandler(event: any) {
    resizeNumber = resizeNumber + 1;
  }
  const getHeader = () => {
    const headerRow: JSX.Element[] = [];
    props.header.map((eachHeader, index) => {
      let headerCellClassName = "header-cell each-header-cell";
      if (eachHeader.className) {
        headerCellClassName += ` ${eachHeader.className}`;
      }
      if (props.isSortEnabled && eachHeader.isSortable) {
        headerCellClassName += ` sortable-header`;
      }
      if (props.sortedColumnId === eachHeader.id) {
        headerCellClassName += ` sorted-header`;
      }
      if (eachHeader.id === props.sortedColumnId) {
        headerRow.push(
          <th
            key={`heder${index}-${props.viewBy}`}
            className={headerCellClassName}
            style={{ borderBottom: "4px solid #2E92FA" }}
          >
            <div
              className="header-content-wrap"
              onClick={
                props.isSortEnabled && eachHeader.isSortable && props.onSort
                  ? () => props.onSort!(eachHeader.id, props.sortDirection)
                  : () => null
              }
            >
              <span>{eachHeader.displayName}</span>
              <span>
                {props.sortDirection && props.sortDirection !== "unsorted" ? (
                  <Icon
                    name={props.sortDirection === "asc" ? "abb/down" : "abb/up"}
                  />
                ) : (
                  <span />
                )}
              </span>
            </div>
          </th>
        );
      } else {
        headerRow.push(
          <th
            key={`heder${index}-${props.viewBy}`}
            className={headerCellClassName}
          >
            <div
              className="header-content-wrap"
              onClick={
                props.isSortEnabled && eachHeader.isSortable && props.onSort
                  ? () => props.onSort!(eachHeader.id, props.sortDirection)
                  : () => null
              }
            >
              <span>{eachHeader.displayName}</span>
            </div>
          </th>
        );
      }
    });
    if (
      (props.isDeleteActionEnabled || props.isViewOptionsActionEnabled) &&
      props.data &&
      props.data.length > 0
    ) {
      headerRow.push(
        <th
          className="actions-header-column each-header-cell"
          key={`action-header-${props.viewBy}`}
        >
          <div className="header-content-wrap" />
        </th>
      );
    }
    if (props.isExpansionEnabled) {
      headerRow.unshift(
        <th
          className="expand-header-column each-header-cell"
          key={`expand-header-${props.viewBy}`}
        >
          <div className="header-content-wrap" />
        </th>
      );
    }
    return (
      <thead>
        <tr>{headerRow}</tr>
      </thead>
    );
  };
  const getBody = () => {
    let eachRow: JSX.Element[] = [];
    const body: JSX.Element[] = [];
    props.data.map((item: serverTypes.IServersGridRowObj, itemIndex) => {
      eachRow = [];
      const groupedRowData: any = {};
      item.data.map((eachCellObj) => {
        groupedRowData[eachCellObj.id] = eachCellObj;
      });
      props.header.map((dataObj: serverTypes.ITableHeaderObj, index) => {
        const cellVal =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["formattedVal"]
            : "";
        const customClassName =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["className"]
            : undefined;
        let cellClassName = "cell each-cell";
        if (customClassName) {
          cellClassName += ` ${customClassName}`;
        }
        eachRow.push(
          <td className={cellClassName}>
            <div
              className={
                item.rowType === "server" && index === 0 ? "server-padding" : ""
              }
            >
              {cellVal}
            </div>
          </td>
        );
      });
      if (props.isDeleteActionEnabled || props.isViewOptionsActionEnabled) {
        eachRow.push(
          <td className="action-cell each-cell">
            <div className="action-icons-container">
              {props.isDeleteActionEnabled && item.rowType !== "edge" ? (
                <div className="action-icon-wrap">
                  <Button
                    icon="abb/trash"
                    className={
                      item.isDeleteActionEnabled
                        ? "table-action-icon delete-icon"
                        : "table-action-icon delete-icon action-disabled"
                    }
                    disabled={!item.isDeleteActionEnabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      return props.onActionClick
                        ? props.onActionClick("delete", { ...item })
                        : null;
                    }}
                    type="discreet-black"
                  />
                </div>
              ) : null}
              {props.isViewOptionsActionEnabled && item.rowType !== "edge" ? (
                <div className="action-icon-wrap">
                  <Button
                    icon="abb/menu-narrow"
                    className={
                      item.isViewOptionsActionEnabled
                        ? "table-action-icon menu-icon"
                        : "table-action-icon menu-icon action-disabled"
                    }
                    disabled={!item.isViewOptionsActionEnabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      return props.onActionClick
                        ? props.onActionClick("viewOptions", { ...item })
                        : null;
                    }}
                    type="discreet-black"
                  />
                  {props.showPopUp && props.selectedRow.id === item.id ? (
                    <React.Fragment>
                      <div className="pop-up-wrap" ref={optionsWrapperRef} onClick={(e) => { e.stopPropagation() }}>
                        <div className="pop-up-arrow" />
                        <div className="pop-up-view">
                          <div
                            className="more-option-popup-row"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onPopUpAction("openSettings", { ...item });
                            }}
                          >
                            <Icon name="abb/settings" sizeClass="small" />
                            <div>Server Settings</div>
                          </div>
                          <div
                            className={
                              item.isBrowseDisabled
                                ? "more-option-popup-row browse-disabled"
                                : "more-option-popup-row"
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onPopUpAction("browse", { ...item });
                              props.onPopUpAction("closePopUp", { ...item });
                            }}
                          >
                            <Icon name="abb/folder-open" sizeClass="small" />
                            <div>Browse Server</div>
                          </div>
                          <div
                            className="more-option-popup-row"
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onPopUpAction("copyEdgeId", { ...item });
                            }}
                          >
                            <Icon name="abb/copy" sizeClass="small" />
                            <div>Copy Hosting Edge Id</div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              ) : null}
            </div>
          </td>
        );
      }
      if (props.isExpansionEnabled) {
        eachRow.unshift(
          <td
            className={
              item.rowType === "server"
                ? "expansion-cell server-expansion-cell each-cell"
                : "expansion-cell edge-expansion-cell each-cell"
            }
            style={{ paddingLeft: (item.level - 1) * 15 }}
            onClick={() =>
              props.onExpandCollapse && item.isExpandable
                ? props.onExpandCollapse({ ...item })
                : () => null
            }
          >
            {item.isExpandable ? (
              item.isExpanded ? (
                <Icon name="abb/down" />
              ) : (
                <Icon name="abb/right" />
              )
            ) : null}
          </td>
        );
      }
      let rowClass = item.isClickable ? "row-view click-class" : "row-view";
      if (!item.isVisible) {
        rowClass += ` hidden-row-view`;
      }
      if (item.rowType === "server") {
        if (!props.data[itemIndex + 1]) {
          rowClass += ` no-border-bottom`;
        } else if (props.data[itemIndex + 1].rowType === "server") {
          rowClass += ` faded-row-view`;
        }
      }
      if (item.isVisible) {
        body.push(
          <tr
            className={rowClass}
            onClick={(e) => {
              e.preventDefault();
              item.isClickable && props.onRowClick
                && props.onRowClick(item)

            }}
          >
            {eachRow}
          </tr>
        );
      }
    });
    return <tbody>{body}</tbody>;
  };
  return (
    <React.Fragment>
      <Table
        id="resizeMe"
        className="server-table"
        key={`${props.viewBy}-grid-key`}
      >
        {getHeader()}
        {props.isLoading ? (
          <div className="loading-container">
            <LoadingIndicator
              type="radial"
              sizeClass="medium"
              determinate={true}
            />
          </div>
        ) : props.data && props.data.length > 0 ? (
          getBody()
        ) : (
          <div />
        )}
      </Table>
      {props.data && props.data.length > 0 ? (
        <div />
      ) : (
        <Table>
          <tbody>
            <tr>
              <td className="no-data-container">{props.noDataText}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
};
export default ServerGrid;
