import { ITabData } from "../Common/Type/TypeCommon";
import { ITreeDataObj } from "../Common/Components/Tree/Tree";

export const tabData: ITabData[] = [
  {
    id: "servers",
    displayName: "Servers",
    path: "/",
    indexNumber: 0,
  },
];
export const OpcServerStatus: { [key: string]: number } = {
  Connected: 0,
  Connecting: 1,
  Disconnected: 2,
};

export const serversGridFixedWidth = 125;
export const configuredDataGridFixedWidth = 95;
export const variableGridFixedWidth = 430;
export const methodGridFixedWidth = 430;
export const availableTypes = {
  uaDataType: "UADataType",
  uaMethod: "UAMethod",
  uaObject: "UAObject",
  uaObjectType: "UAObjectType",
  uaReferenceType: "UAReferenceType",
  uaVariable: "UAVariable",
  uaVariableType: "UAVariableType",
  uaView: "UAView",
  root: "Root",
};
export const helpPageTreeData: ITreeDataObj[] = [
  {
    id: "%1.introduction%",
    displayName: "Introduction",
    rawData: { nodeId: "%1.introduction%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%1.introduction%%1.1rbac%",
    displayName: "1.1 RBAC",
    rawData: { nodeId: "%1.introduction%%1.1rbac%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconnect%",
    displayName: "OPC UA Connect",
    rawData: { nodeId: "%2.opcuaconnect%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%2.opcuaconnect%%2.1.installation%",
    displayName: "Installation",
    rawData: { nodeId: "%2.opcuaconnect%%2.1.installation%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconnect%%2.1.installation%%2.1.1.application%",
    displayName: "Application",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.1.installation%%2.1.1.application%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.1.installation%%2.1.2.certificates%",
    displayName: "Certificates",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.1.installation%%2.1.2.certificates%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.2.routingconfiguration%",
    displayName: "Routing Configuration",
    rawData: { nodeId: "%2.opcuaconnect%%2.2.routingconfiguration%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%",
    displayName: "Runtime",
    rawData: { nodeId: "%2.opcuaconnect%%2.3.runtime%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%overview%",
    displayName: "Overview",
    rawData: { nodeId: "%2.opcuaconnect%%2.3.runtime%%overview%" } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%settings%",
    displayName: "Settings",
    rawData: { nodeId: "%2.opcuaconnect%%2.3.runtime%%settings%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%settings%%loglevel%",
    displayName: "Log Level",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.3.runtime%%settings%%loglevel%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 4,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%modules%",
    displayName: "Modules",
    rawData: { nodeId: "%2.opcuaconnect%%2.3.runtime%%modules%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%modules%%information%",
    displayName: "Information",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.3.runtime%%modules%%information%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 4,
  },
  // {
  //   id: "%2.opcuaconnect%%2.3.runtime%%modules%%operation%",
  //   displayName: "Operation",
  //   rawData: {
  //     nodeId: "%2.opcuaconnect%%2.3.runtime%%modules%%operation%",
  //   } as any,
  //   isExpandable: false,
  //   isExpanded: false,
  //   isVisible: false,
  //   isTitle: false,
  //   level: 4,
  // },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%objects%",
    displayName: "Objects",
    rawData: { nodeId: "%2.opcuaconnect%%2.3.runtime%%objects%" } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%",
    displayName: "Endpoint",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 4,
  },
  {
    id: "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%%databinding%",
    displayName: "Databinding",
    rawData: {
      nodeId: "%2.opcuaconnect%%2.3.runtime%%objects%%endpoint%%databinding%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 5,
  },
  {
    id: "%3.opcuaconfigurationtool%",
    displayName: "OPC UA Configuration Utility",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.1.configuration%",
    displayName: "Configuration",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.1.configuration%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.1.configuration%3.1.1.connectopcuaserver",
    displayName: "Connect OPC UA Server",
    rawData: {
      nodeId:
        "%3.opcuaconfigurationtool%%3.1.configuration%3.1.1.connectopcuaserver",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.1.configuration%3.1.2.configureopcuaserver",
    displayName: "Configure OPC UA Server",
    rawData: {
      nodeId:
        "%3.opcuaconfigurationtool%%3.1.configuration%3.1.2.configureopcuaserver",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.2.browseopcuahierarchy%",
    displayName: "Browse OPC UA Hierarchy",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.2.browseopcuahierarchy%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.3.activatevariablesandmethods%",
    displayName: "Activate Variables and Methods",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.3.activatevariablesandmethods%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.4.configureopcuatypes%",
    displayName: "Configuring OPC UA Types",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.4.configureopcuatypes%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.5.editdeleteserver%",
    displayName: "Edit/Delete OPU UA Server Configuration",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.5.editdeleteserver%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%",
    displayName: "OPC UA Configuration Utility – Pages",
    rawData: {
      nodeId: "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },

  {
    id: "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.1.opcuaservers%",
    displayName: "OPC UA Servers",
    rawData: {
      nodeId:
        "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.1.opcuaservers%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.2.browseserver%",
    displayName: "Browse Server",
    rawData: {
      nodeId:
        "%3.opcuaconfigurationtool%%3.6.opcuaconfigutility%%3.6.2.browseserver%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 3,
  },
  {
    id: "%4.opcuaconnectondemand%",
    displayName: "OPC UA Configuration Utility – Connect-On-Demand mode ",
    rawData: {
      nodeId: "%4.opcuaconnectondemand%",
    } as any,
    isExpandable: true,
    isExpanded: false,
    isVisible: true,
    isTitle: false,
    level: 1,
  },
  {
    id: "%4.opcuaconnectondemand%%4.1.installation%",
    displayName: "Installation",
    rawData: {
      nodeId: "%4.opcuaconnectondemand%%4.1.installation%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.opcuaconnectondemand%%4.2.routingconfiguration%",
    displayName: "Routing Configuration",
    rawData: {
      nodeId: "%4.opcuaconnectondemand%%4.2.routingconfiguration%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
  {
    id: "%4.opcuaconnectondemand%%4.3.synchronization%",
    displayName: "Synchronization",
    rawData: {
      nodeId: "%4.opcuaconnectondemand%%4.3.synchronization%",
    } as any,
    isExpandable: false,
    isExpanded: false,
    isVisible: false,
    isTitle: false,
    level: 2,
  },
];
