import { all } from "redux-saga/effects";
import actionWatcherServers, {
  watchHandleServerStatus,
  watchHandleCurrentServerBackgroundCall,
  watchSessionStateCall,
  watchServerAfterUpload,
} from "../Views/Servers/Saga/SagaServers";
import actionWatcherCommons from "../Common/Saga/SagaCommon";
import actionWatcherBrowse from "../Views/Browse/Saga/SagaBrowse";

export default function* () {
  yield all([
    actionWatcherServers(),
    watchHandleServerStatus(),
    watchHandleCurrentServerBackgroundCall(),
    watchSessionStateCall(),
    watchServerAfterUpload(),
    actionWatcherBrowse(),
    actionWatcherCommons(),
  ]);
}
