import * as React from "react";
import "./BrowseGrid.css";
import {
  Icon,
  LoadingIndicator,
  Table,
  Checkbox,
  Input,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from "@abb/abb-common-ux-react";
import * as tableInterfaces from "../Type/TypeBrowse";

let resizeNumber = 0;
const TableView = (props: tableInterfaces.ITableProps) => {
  React.useEffect(() => {
    (window as any).visualViewport!.addEventListener("resize", viewportHandler);
    initialColumnSettings();
  }, []);
  const initialColumnSettings = () => {
    const table = document.getElementById(
      props.resizeId ? props.resizeId : "browseResizeMe"
    );
    const cols = table && table!.querySelectorAll("th");
    if (cols) {
      [].forEach.call(cols, function (col: any) {
        const resizer = document.createElement("div");
        resizer.classList.add("resizer");
        resizer.style.height = `${table!.offsetHeight}px`;
        if (
          col.classList.contains("variable-table-header") ||
          col.classList.contains("output-param-table-header") ||
          col.classList.contains("input-param-table-header") ||
          col.classList.contains("method-table-header")
        ) {
          col.style.width = `${
            (document.documentElement.clientWidth - 300) / props.header.length +
            500
          }px`;
        } else {
          col.style.width = `${
            document.documentElement.clientWidth / (props.header.length - 1) +
            500
          }px`;
        }
        if (
          col.classList.contains("actions-header-column") ||
          col.classList.contains("actions-filter-column")
        ) {
          col.style.width = "100px";
        }
        if (col.classList.contains("isactive-header")) {
          col.style.width = "40px";
        }
        if (col.classList.contains("expand-header-column")) {
          col.style.width = "20px";
        }
        if (
          !col.classList.contains("actions-header-column") &&
          !col.classList.contains("expand-header-column")
        ) {
          col.appendChild(resizer);
        }
        createResizableColumn(table, col, resizer);
      });
    }
  };
  React.useEffect(() => {
    return () => {
      (window as any).visualViewport!.addEventListener(
        "resize",
        viewportHandler
      );
    };
  });
  React.useEffect(() => {
    initialColumnSettings();
  }, [resizeNumber]);
  function viewportHandler(event: any) {
    resizeNumber = resizeNumber + 1;
  }
  const createResizableColumn = function (table: any, col: any, resizer: any) {
    let x = 0;
    let w = 0;
    let tableW = 0;

    const mouseDownHandler = function (e: any) {
      x = e.clientX;
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);
      let tableWStyles = window.getComputedStyle(table);
      tableW = parseInt(tableWStyles.width, 10);
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };

    const mouseMoveHandler = function (e: any) {
      const dx = e.clientX - x;
      if (tableW + dx > props.minWidth!) {
        col.style.width = `${w + dx}px`;
        table.style.width = `${tableW + dx}px`;
        e.stopPropagation();
      }
    };
    const mouseUpHandler = function () {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      resizer.classList.add("resizing");
    };
    resizer.addEventListener("mousedown", mouseDownHandler);
  };

  const getHeader = () => {
    const headerRow: JSX.Element[] = props.header.map((eachHeader, index) => {
      let headerCellClassName = "header-cell each-header-cell";
      if (eachHeader.className) {
        headerCellClassName += ` ${eachHeader.className}`;
      }
      if (props.isSortEnabled && eachHeader.isSortable) {
        headerCellClassName += ` sortable-header`;
      }
      if (props.sortedColumnId === eachHeader.id) {
        headerCellClassName += ` sorted-header`;
      }
      if (eachHeader.id === props.sortedColumnId) {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            style={{ borderBottom: "4px solid #2E92FA" }}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
              <span>
                {props.sortDirection && props.sortDirection !== "unsorted" ? (
                  <Icon
                    name={props.sortDirection === "asc" ? "abb/down" : "abb/up"}
                  />
                ) : (
                  <span />
                )}
              </span>
            </div>
          </th>
        );
      } else {
        return (
          <th
            key={`heder${index}`}
            className={headerCellClassName}
            onClick={() =>
              props.isSortEnabled && eachHeader.isSortable && props.onSort
                ? props.onSort(eachHeader.id, props.sortDirection)
                : () => null
            }
          >
            <div className="header-content-wrap">
              <span>{eachHeader.displayName}</span>
            </div>
          </th>
        );
      }
    });
    if (
      (props.isDeleteActionEnabled ||
        props.isEditActionEnabled ||
        props.isViewActionEnabled) &&
      props.data &&
      props.data.length > 0
    ) {
      headerRow.push(<th className="actions-header-column each-header-cell" />);
    }
    if (props.isCheckBoxEnabled) {
      if (props.showSelectAllOption) {
        let checkBoxSelectAllClassName = "check-box-cell";
        if (props.isSortEnabledForSelectCollumn) {
          checkBoxSelectAllClassName +=
            " sortable-header sortable-check-box-cell";
        }
        if (props.sortedColumnId === "selectColumn") {
          checkBoxSelectAllClassName += " sorted-header";

          headerRow.unshift(
            <th
              className={checkBoxSelectAllClassName}
              onClick={() =>
                props.onSort
                  ? props.onSort("selectColumn", props.sortDirection)
                  : () => null
              }
            >
              <div className="check-box-sort-icon-box-wrap">
                <Checkbox
                  sizeClass="small"
                  value={props.isSelectedAll}
                  disabled={props.isSelectAllDisabled}
                  onChange={() =>
                    props.handleSelectAll
                      ? props.handleSelectAll(
                          props.isSelectedAll ? false : true
                        )
                      : null
                  }
                />
                <span className="check-box-sort-icon-wrap">
                  {props.sortDirection && props.sortDirection !== "unsorted" ? (
                    <Icon
                      name={
                        props.sortDirection === "asc" ? "abb/down" : "abb/up"
                      }
                    />
                  ) : (
                    <span />
                  )}
                </span>
              </div>
            </th>
          );
        } else {
          headerRow.unshift(
            <th
              className={checkBoxSelectAllClassName}
              onClick={() =>
                props.isSortEnabledForSelectCollumn && props.onSort
                  ? props.onSort("selectColumn", props.sortDirection)
                  : () => null
              }
            >
              <Checkbox
                sizeClass="small"
                value={props.isSelectedAll}
                disabled={props.isSelectAllDisabled}
                onChange={() =>
                  props.handleSelectAll
                    ? props.handleSelectAll(props.isSelectedAll ? false : true)
                    : null
                }
              />
            </th>
          );
        }
      } else {
        let checkBoxClassName = "checkBox-header-column each-header-cell";
        if (props.isSortEnabledForSelectCollumn) {
          checkBoxClassName += " sortable-header sortable-check-box-cell";
        }
        if (props.sortedColumnId === "selectColumn") {
          checkBoxClassName += " sorted-header";
          headerRow.unshift(
            <th
              className={checkBoxClassName}
              onClick={() =>
                props.isSortEnabledForSelectCollumn && props.onSort
                  ? props.onSort("selectColumn", props.sortDirection)
                  : () => null
              }
            >
              <span>
                {props.sortDirection && props.sortDirection !== "unsorted" ? (
                  <Icon
                    name={props.sortDirection === "asc" ? "abb/down" : "abb/up"}
                  />
                ) : (
                  <span />
                )}
              </span>
            </th>
          );
        } else {
          headerRow.unshift(
            <th
              className={checkBoxClassName}
              onClick={() =>
                props.isSortEnabledForSelectCollumn && props.onSort
                  ? props.onSort("selectColumn", props.sortDirection)
                  : () => null
              }
            />
          );
        }
      }
    }
    let filterRow: JSX.Element[] = [];
    if (props.isFilterEnabled) {
      filterRow = props.header.map((eachHeader, index) => {
        let filterCellClassName = "filter-cell each-filter-cell";
        if (eachHeader.className) {
          filterCellClassName += ` ${eachHeader.className}`;
        }
        return (
          <th key={`heder${index}`} className={filterCellClassName}>
            <div className="filter-content-wrap">
              {eachHeader.isSearchable ? (
                <Input
                  dataType="text"
                  value={
                    props.filterParams && props.filterParams[eachHeader.id]
                      ? props.filterParams[eachHeader.id]
                      : ""
                  }
                  onValueChange={(value: string) =>
                    props.handleFilter
                      ? props.handleFilter(eachHeader.id, value)
                      : null
                  }
                />
              ) : null}
            </div>
          </th>
        );
      });
      if (
        (props.isDeleteActionEnabled ||
          props.isEditActionEnabled ||
          props.isViewActionEnabled) &&
        props.data &&
        props.data.length > 0
      ) {
        filterRow.push(
          <th className="actions-filter-column each-filter-cell" />
        );
      }
      if (props.isCheckBoxEnabled) {
        filterRow.unshift(
          <th
            className={
              props.isSortEnabledForSelectCollumn
                ? "checkBox-filter-column each-filter-cell sortable-check-box-cell"
                : "checkBox-filter-column each-filter-cell"
            }
          />
        );
      }
    }
    return (
      <thead>
        <tr>{headerRow}</tr>
        {props.isFilterEnabled ? (
          <tr
            className={
              props.showFilter
                ? "filter-row-wrap"
                : "filter-row-wrap hidden-filter"
            }
          >
            {filterRow}
          </tr>
        ) : null}
      </thead>
    );
  };
  const getBody = () => {
    let eachRow: JSX.Element[] = [];
    const body: JSX.Element[] = [];
    props.data.map((item: tableInterfaces.IEachRowObj) => {
      eachRow = [];
      let rowClassCustom = "row-view";
      if (item.className) {
        rowClassCustom += ` ${item.className}`;
      }
      const groupedRowData: any = {};
      item.data.map((eachCellObj) => {
        groupedRowData[eachCellObj.id] = eachCellObj;
      });
      props.header.map((dataObj: tableInterfaces.ITableHeaderObj) => {
        const cellVal =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["formattedVal"]
            : "";
        const customClassName =
          groupedRowData && groupedRowData[dataObj["id"]]
            ? groupedRowData[dataObj["id"]]["className"]
            : undefined;
        let cellClassName = "cell";
        if (customClassName) {
          cellClassName += ` ${customClassName}`;
        }
        eachRow.push(
          <td
            className={cellClassName}
            title={cellVal && typeof cellVal !== "object" ? cellVal : ""}
          >
            {cellVal}
          </td>
        );
      });
      if (
        props.isDeleteActionEnabled ||
        props.isEditActionEnabled ||
        props.isViewActionEnabled
      ) {
        eachRow.push(
          <td className="action-cell">
            <div className="action-icons-container">
              {props.isViewActionEnabled ? (
                <div className="action-icon-wrap">
                  {/* <Icon
                    name="abb/view"
                    className={
                      item.isViewActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={() =>
                      props.onActionClick
                        ? props.onActionClick("view", { ...item })
                        : null
                    }
                  /> */}
                  <Button
                    icon="abb/information-circle-1"
                    className={
                      item.isViewActionEnabled
                        ? "table-action-icon view-icon"
                        : "table-action-icon view-icon action-disabled"
                    }
                    disabled={!item.isViewActionEnabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      return props.onActionClick
                        ? props.onActionClick("view", { ...item })
                        : null;
                    }}
                    type="discreet-black"
                  />
                </div>
              ) : null}
              {props.isEditActionEnabled ? (
                <div className="action-icon-wrap">
                  {/* <Icon
                    name="abb/edit"
                    className={
                      item.isEditActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={() =>
                      props.onActionClick
                        ? props.onActionClick("edit", { ...item })
                        : null
                    }
                  /> */}
                  <Button
                    icon="abb/edit"
                    className={
                      item.isEditActionEnabled
                        ? "table-action-icon edit-icon"
                        : "table-action-icon edit-icon action-disabled"
                    }
                    disabled={!item.isEditActionEnabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      return props.onActionClick
                        ? props.onActionClick("edit", { ...item })
                        : null;
                    }}
                    type="discreet-black"
                  />
                </div>
              ) : null}
              {props.isDeleteActionEnabled ? (
                <div className="action-icon-wrap">
                  {/* <Icon
                    name="abb/trash"
                    className={
                      item.isDeleteActionEnabled
                        ? "table-action-icon"
                        : "table-action-icon action-disabled"
                    }
                    onClick={() =>
                      props.onActionClick
                        ? props.onActionClick("delete", { ...item })
                        : null
                    }
                  /> */}
                  <Button
                    icon="abb/trash"
                    className={
                      item.isDeleteActionEnabled
                        ? "table-action-icon delete-icon"
                        : "table-action-icon delete-icon action-disabled"
                    }
                    disabled={!item.isDeleteActionEnabled}
                    onClick={(e) => {
                      e.stopPropagation();
                      return props.onActionClick
                        ? props.onActionClick("delete", { ...item })
                        : null;
                    }}
                    type="discreet-black"
                  />
                </div>
              ) : null}
            </div>
          </td>
        );
      }
      if (props.isCheckBoxEnabled) {
        eachRow.unshift(
          <td
            className={
              props.isSortEnabledForSelectCollumn
                ? "check-box-cell sortable-check-box-cell"
                : "check-box-cell"
            }
          >
            <Checkbox
              sizeClass="small"
              value={item.isSelected}
              disabled={!item.isSelectable}
              onChange={() =>
                props.onCheckBoxClick
                  ? props.onCheckBoxClick({ ...item })
                  : null
              }
            />
          </td>
        );
      }
      body.push(
        <tr
          className={
            item.isClickable
              ? `${rowClassCustom} click-class`
              : `${rowClassCustom}`
          }
          onClick={() =>
            item.isClickable && props.onRowClick
              ? props.onRowClick(item)
              : () => null
          }
        >
          {eachRow}
        </tr>
      );
    });
    return <tbody>{body}</tbody>;
  };
  return (
    <React.Fragment>
      {props.isFilterEnabled ? (
        <ToggleButtonGroup
          sizeClass="medium"
          selected={props.showFilter ? [0] : undefined}
          className="filter-toggle"
        >
          <ToggleButton
            icon="abb/filter"
            onClick={() =>
              props.handleShowFilter
                ? props.handleShowFilter(!props.showFilter)
                : null
            }
          />
        </ToggleButtonGroup>
      ) : null}
      <div className={`${props.className ? props.className : ""}`}>
        <React.Fragment>
          <Table
            className="browse-view-grid browse-table"
            id={props.resizeId ? props.resizeId : "browseResizeMe"}
            key={`browse-table-grid-key`}
          >
            {getHeader()}
            {props.isLoading ? (
              <div className="loading-container">
                <LoadingIndicator
                  type="radial"
                  sizeClass="medium"
                  determinate={true}
                />
              </div>
            ) : props.data && props.data.length > 0 ? (
              getBody()
            ) : (
              <div />
            )}
          </Table>
          {props.data && props.data.length > 0 ? (
            <div />
          ) : (
            <Table>
              <tbody>
                <tr>
                  <td className="no-data-container">{props.noDataText}</td>
                </tr>
              </tbody>
            </Table>
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};
export default TableView;
