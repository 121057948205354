import React from "react";
import "./License.css";
import { AppMainContent, AppContainer, Icon } from "@abb/abb-common-ux-react";
import Interweave from "interweave";
import CommonLoader from "../CommonLoader/CommonLoader";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";

interface IlicenseProps {
  showLicense: boolean;
  onCloseLicense: () => void;
}
const LicenseView = (props: IlicenseProps) => {
  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      props.onCloseLicense();
    }
  };
  const commonState = useSelector((state: IState) => state.commonState);
  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);
  return (
    <div className="license-wrap">
      <div className="notes-heading">
        <span>Licensing </span>
        <div>
          <Icon
            name="abb/close"
            className="notes-close-icon"
            onClick={() => props.onCloseLicense()}
          />
        </div>
      </div>
      <AppContainer
        className={`views-container ${
          commonState.theme === "light"
            ? "light-view-container"
            : "dark-view-container"
        }`}
        theme={commonState.theme}
      >
        <AppMainContent>
          <div className="license-container">
            <div className="license-content">
              <div className="license-information">
                <span> LICENSING INFORMATION </span>
              </div>
              <hr />

              <DisplayDoc docURL="./Black Duck.html" />
              {/* </div> */}
            </div>
          </div>
        </AppMainContent>
      </AppContainer>
    </div>
    // {/* </Dialog> */}
  );
};

const DisplayDoc = (props: { docURL: string }) => {
  const [content, setConent] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    //setLoadState(loaderState.loading);
    setLoading(true);
    fetch(props.docURL)
      .then((response) => {
        return response.text();
      })
      .then((returnString) => {
        if (returnString.indexOf("<body") === -1) {
          setConent(returnString);
          // setLoadState(loaderState.loaded);
          setLoading(false);
        }
        returnString = returnString.slice(
          returnString.indexOf("<body"),
          returnString.length
        );
        returnString = returnString.slice(0, returnString.indexOf("</body>"));
        const dom = document.createElement("div");
        dom.innerHTML = returnString;
        const scripts = dom.getElementsByTagName("script");
        let scriptLength = scripts.length;
        while (scriptLength--) {
          const script = scripts[scriptLength].parentNode;
          if (script) {
            script.removeChild(scripts[scriptLength]);
          }
        }
        setConent(dom.innerHTML);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Failed to fetch page: ", err);
        setLoading(false);
      });
  }, [props.docURL]);

  return (
    <React.Fragment>
      {isLoading ? <CommonLoader /> : <div />}
      <div className="content">
        <Interweave content={content} />
      </div>
    </React.Fragment>
  );
};

export default LicenseView;
