import * as commonTypes from "../Type/TypeCommon";

export interface ICommonStateType {
  theme: "dark" | "light";
  showNotification: boolean;
  notificationType: "success" | "info" | "warn" | "alarm";
  notificationCloseDuration: number;
  notificationMessage: string;
  showUnAuthorized: boolean;
  isLoading: boolean;
  isPreferenceLoaded: boolean;
  unAuthorizedErrorType: string;
}
const defaulNotificationCloseDuration = 5000;
const initialState: ICommonStateType = {
  theme: "light",
  showNotification: false,
  notificationType: "success",
  notificationCloseDuration: defaulNotificationCloseDuration,
  notificationMessage: "",
  showUnAuthorized: false,
  isLoading: false,
  isPreferenceLoaded: false,
  unAuthorizedErrorType: "",
};

export default function (
  state: ICommonStateType = initialState,
  action: commonTypes.ICommonActions
): ICommonStateType {
  switch (action.type) {
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        showNotification: true,
        notificationType: action.notificationType,
        notificationCloseDuration: action.closeDuration
          ? action.closeDuration
          : state.notificationCloseDuration,
        notificationMessage: action.message,
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        notificationMessage: "",
        notificationCloseDuration: defaulNotificationCloseDuration,
        showNotification: false,
      };
    case "SHOW_UNAUTHORIZED_PAGE":
      return {
        ...state,
        showUnAuthorized: true,
        unAuthorizedErrorType: action.errorType,
      };
    case "GET_PREFERENCE":
      return {
        ...state,
        isLoading: true,
      };
    case "RECEIVED_PREFERENCE":
      return {
        ...state,
        isLoading: false,
        theme:
          action.preferenceData &&
          action.preferenceData.theme &&
          action.preferenceData.theme === "dark"
            ? "dark"
            : "light",
        isPreferenceLoaded: true,
      };
    case "CHANGE_PREFERENCE":
      return {
        ...state,
        theme: action.selectedTheme,
      };
    default:
      return state;
  }
}
