import React from "react";
import "./CommonLoader.css";
import { LoadingIndicator } from "@abb/abb-common-ux-react";

const CommonLoader = (props: any) => {
  return (
    <div className="common-loader-wrap">
      <LoadingIndicator
        sizeClass="large"
        type="radial"
        color="blue"
        determinate={true}
      />
    </div>
  );
};

export default CommonLoader;
